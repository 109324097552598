import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Loading from '../../../Components/Loading'
import Details from './Details'

import {ContainerService} from '../../../../services/AdminServices/ContainerService'
import {CompanyService} from '../../../../services/AdminServices/CompanyService'

import {priceConversion} from '../../../../utils/common'
import {PageTitle} from '../../../../_metronic/layout/core'

const ManageProposals = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)

  const [manageProposalList, setManageProposalList] = useState<any>([])
  const [companyDetails, setCompanyDetails] = useState<any>({})
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [showCompanyDetails, setShowCompanyDetails] = useState<boolean>(false)

  const containerServ = new ContainerService()
  const companyServ = new CompanyService()

  const getAllProposalByUser = (page: number) => {
    containerServ
      .getManageProposalByAdmin(page)
      .then((res: any) => {
        setLoading(false)
        setManageProposalList(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleManageProposalPagination = (page: number) => {
    setPageMIndex(page)
    getAllProposalByUser(page)
  }

  const getCompanyDetails = (companyId: number) => {
    companyServ
      .getCompanyDetails(companyId)
      .then((resp: any) => {
        setLoading(false)
        setCompanyDetails(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getAllProposalByUser(pageMIndex)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.systemOperator.manageProposal'})}
      </PageTitle>
      {!manageProposalList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
            accessor: 'company',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.proposalList.description'}),
            accessor: 'description',
          },
          {
            Header: intl.formatMessage({id: 'Admin.Containers.from'}),
            accessor: 'fromLocation',
          },

          {
            Header: intl.formatMessage({id: 'Admin.Containers.to'}),
            accessor: 'toLocation',
          },
          {
            Header: intl.formatMessage({id: 'Carrier.freightPosting.proposalList.freightStatus'}),
            accessor: 'freightStatus',
          },
          {
            Header: () => (
              <div className='d-flex justify-content-end'>
                {intl.formatMessage({id: 'freightPosting.proposalList.price'})}
              </div>
            ),
            accessor: 'price',
            cellStyle: {
              textAlign: 'end',
            },
          },
          {
            Header: intl.formatMessage({id: 'freightPosting.proposalList.currency'}),
            accessor: 'currency',
          },
          {
            Header: intl.formatMessage({id: 'freightPosting.proposalList.proposalScope'}),
            accessor: 'proposalScope',
          },
        ]}
        data={
          manageProposalList &&
          manageProposalList.map((item: any) => {
            return {
              id: item?.containerProposalId,
              company: item?.company?.name ?? '-',
              description: item?.container?.freightContentType?.description,
              freightStatus: item?.container?.containerStatus?.description,
              price: item?.price && priceConversion(item?.price, item?.currency?.currencyCode),
              currency: item?.currency?.currencyCode,
              fromLocation: item?.container?.fromAddress?.city?.name
                ? item?.container?.fromAddress?.city?.name +
                  ' / ' +
                  item?.container?.fromAddress?.country?.name
                : '',
              toLocation: item?.container?.toAddress?.city?.name
                ? item?.container?.toAddress?.city?.name +
                  ' / ' +
                  item?.container?.toAddress?.country?.name
                : '',
              proposalScope:
                item.proposalScope.code === 'OTHER'
                  ? item?.explanation
                  : item?.proposalScope?.description,
              data: item,
            }
          })
        }
        editableRows={['']}
        deleteText={intl.formatMessage({id: 'global.table.deleteText'})}
        showDetailBtn={(row: any) => {
          setLoading(true)
          setShowCompanyDetails(true)
          getCompanyDetails(row?.original?.data?.company?.companyId)
        }}
        setPageMIndex={handleManageProposalPagination}
        pageMIndex={pageMIndex}
      />

      {showCompanyDetails && (
        <Details allData={companyDetails} onClose={() => setShowCompanyDetails(false)} />
      )}
    </>
  )
}

export default ManageProposals
