import React, {Suspense, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {format} from 'date-fns'
import {useFormik} from 'formik'

import DatePicker from '../../../../Components/DatePicker'
import MyTimePicker from '../../../../Components/TimePicker'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Dropbox from '../../../../Components/Dropbox'
import {CreateMapAddress} from '../../../FreightOperator/CreateQuickFreight/Popup/CreateMapViewPopup'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'

import {AddressService} from '../../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'
import {ClientService} from '../../../../../services/CarrierServices/ClientService'

import useScrollLock from '../../../../../utils/useScrollLock'
import {getTime, exceptThisSymbols} from '../../../../../utils/common'

const NewCheckGroup = React.lazy(() => import('../../../../Components/NewCheckGroup'))

const EditPreferences = ({
  trailerTypes,
  trailerSpecifications,
  floorTypes,
  currencyList,
  transportTypes,
  freightTypes,
  freightPackageTypes,
  freightLoadTypes,
  gtipList,
  currentData,
  editedData,
  selectedTrailerType,
  onClose,
  setLoading,
}: any) => {
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState(0)
  const [activeClientTab, setActiveClientTab] = useState(0)
  const [specUpdatedSelected, setSpecUpdatedSelected] = useState<any>([])
  const [trailerUpdatedSelected, setTrailerUpdatedSelected] = useState<number>(-1)
  const [arrivalDetail, setArrivalDetail] = useState<any>({})
  const [departureDetail, setDepartureDetail] = useState<any>({})

  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])

  const [requiredLocationContent, setRequiredLocationContent] = useState<any>(false)

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])

  const [isMapFromView, setIsMapFromView] = useState<any>(false)
  const [isMapToView, setIsMapToView] = useState<any>(false)
  const [showFromAddress, setShowFromAddress] = useState<any>(false)
  const [showToAddress, setShowToAddress] = useState<any>(false)

  const [fromAddressCustomSearch, setFromAddressCustomSearch] = useState<string>('')
  const [fromAddressPageIndex, setFromAddressPageIndex] = useState<number>(0)
  const [fromAddressList, setFromAddressList] = useState<any>([])
  const [selectedFromAddressName, setSelectedFromAddressName] = useState<any>(null)

  const [toAddressCustomSearch, setToAddressCustomSearch] = useState<string>('')
  const [toAddressPageIndex, setToAddressPageIndex] = useState<number>(0)
  const [toAddressList, setToAddressList] = useState<any>([])
  const [selectedToAddressName, setSelectedToAddressName] = useState<any>(null)

  const [consignorClientCustomSearch, setConsignorClientCustomSearch] = useState<string>('')
  const [consignorClientPageIndex, setConsignorClientPageIndex] = useState<number>(0)
  const [consignorCompanyList, setConsignorCompanyList] = useState<any>([])
  const [selectedConsignorName, setSelectedConsignorName] = useState<any>(null)

  const [consigneeClientCustomSearch, setConsigneeClientCustomSearch] = useState<string>('')
  const [consigneeClientPageIndex, setConsigneeClientPageIndex] = useState<number>(0)
  const [consigneeCompanyList, setConsigneeCompanyList] = useState<any>([])
  const [selectedConsigneeName, setSelectedConsigneeName] = useState<any>(null)

  const [clearFromAddress, setClearFromAddress] = useState<boolean>(false)
  const [clearToAddress, setClearToAddress] = useState<boolean>(false)

  const [consignorClientScrollAPICalled, setConsignorClientScrollAPICalled] =
    useState<boolean>(false)
  const [consigneeClientScrollAPICalled, setConsigneeClientScrollAPICalled] =
    useState<boolean>(false)
  const [fromAddressScrollAPICalled, setFromAddressScrollAPICalled] = useState<boolean>(false)
  const [toAddressScrollAPICalled, setToAddressScrollAPICalled] = useState<boolean>(false)

  const addressServ = new AddressService()
  const commonServ = new CommonService()
  const clientServ = new ClientService()

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
    getConsigneeClientsList()
    getConsignorClientsList()
    getCountryList()
    getNewAddressList()
    getNewToAddressList()
  }, [])

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    freightId: -1,
    title: '',
    trailerType: '',
    specificationList: [],
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    loadAddress: '',
    destinationAddress: '',
    transportType: '',
    freightType: '',
    freightPackageType: '',
    freightLoadType: '',

    weight: '',
    value: '',
    currency: '',
    flammable: false,
    stackable: false,

    width: '',
    height: '',
    freightLength: '',
    desi: '',
    ldm: '',
    harmonizedSystemCode: '',

    fromCountry: '',
    fromCity: '',
    fromDistrict: '',
    fromNeighborhood: '',
    fromPostCode: '',
    fromStreet: '',
    fromStreetNumber: '',
    fromLatitude: '',
    fromLongitude: '',

    toCountry: '',
    toCity: '',
    toDistrict: '',
    toNeighborhood: '',
    toPostCode: '',
    toStreet: '',
    toStreetNumber: '',
    toLatitude: '',
    toLongitude: '',
    targetCost: '',
    targetCostCurrency: '',

    consignorName: '',
    consignorSurName: '',
    consignorCountryCode: '+90',
    consignorPhone: '',
    consignorEmail: '',
    consignorTaxNumber: '',
    consignorTaxOffice: '',
    consignorReferenceClientId: '',

    consigneeName: '',
    consigneeSurName: '',
    consigneeCountryCode: '+90',
    consigneePhone: '',
    consigneeEmail: '',
    consigneeTaxNumber: '',
    consigneeTaxOffice: '',
    consigneeReferenceClientId: '',
  }
  const loginSchema = Yup.object().shape(
    {
      freightId: Yup.number().required(''),
      width: Yup.string().notRequired(),
      height: Yup.string().notRequired(),
      freightLength: Yup.string().notRequired(),
      desi: Yup.string().notRequired(),
      /*.required(intl.formatMessage({id: 'global.thisFieldRequired'}))*/ ldm: Yup.string()
        .notRequired()
        .nullable(),
      /*.required(intl.formatMessage({id: 'global.thisFieldRequired'}))*/
      title: Yup.string().notRequired(),
      trailerType: Yup.string().notRequired(),
      floorType: Yup.string().notRequired(),
      specificationList: Yup.array().notRequired(),

      loadDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      loadTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      destDate: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      destTime: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),

      loadAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      destinationAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      transportType: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      freightType: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
      freightPackageType: Yup.string().notRequired(),
      freightLoadType: Yup.string().notRequired(),
      weight: Yup.string().notRequired(),
      value: Yup.string().notRequired(),
      currency: Yup.string().notRequired(),
      harmonizedSystemCode: Yup.string().notRequired(),
      flammable: Yup.boolean(),
      stackable: Yup.boolean(),

      fromCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      fromCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      fromDistrict: Yup.string().notRequired().nullable(),
      fromNeighborhood: Yup.string().notRequired().nullable(),
      fromPostCode: Yup.string().notRequired().nullable(),
      fromStreet: Yup.string().notRequired().nullable(),
      fromStreetNumber: Yup.string().notRequired().nullable(),
      fromLatitude: Yup.string().notRequired().nullable(),
      fromLongitude: Yup.string().notRequired().nullable(),

      toCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      toCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string().notRequired().nullable(), // unnecessary
      }),
      toDistrict: Yup.string().notRequired().nullable(),
      toNeighborhood: Yup.string().notRequired().nullable(),
      toPostCode: Yup.string().notRequired().nullable(),
      toStreet: Yup.string().notRequired().nullable(),
      toStreetNumber: Yup.string().notRequired().nullable(),
      toLatitude: Yup.string().notRequired().nullable(),
      toLongitude: Yup.string().notRequired().nullable(),

      consignorName: Yup.string().notRequired().nullable(),
      consignorSurName: Yup.string().notRequired().nullable(),
      consignorCountryCode: Yup.string().notRequired().nullable(),
      consignorPhone: Yup.string().notRequired().nullable(),
      consignorEmail: Yup.string().notRequired().nullable(),
      consignorTaxNumber: Yup.string().notRequired().nullable(),
      consignorTaxOffice: Yup.string().notRequired().nullable(),
      consignorReferenceClientId: Yup.string().notRequired().nullable(),

      consigneeName: Yup.string().notRequired().nullable(),
      consigneeSurName: Yup.string().notRequired().nullable(),
      consigneeCountryCode: Yup.string().notRequired().nullable(),
      consigneePhone: Yup.string().notRequired().nullable(),
      consigneeEmail: Yup.string().notRequired().nullable(),
      consigneeTaxNumber: Yup.string().notRequired().nullable(),
      consigneeTaxOffice: Yup.string().notRequired().nullable(),
      consigneeReferenceClientId: Yup.string().notRequired().nullable(),
    },
    [
      ['fromCountry', 'toCountry'],
      ['loadAddress', 'fromCountry'],
      ['loadAddress', 'toCountry'],
      ['loadAddress', 'fromCity'],
      ['loadAddress', 'toCity'],
      ['destinationAddress', 'fromCountry'],
      ['destinationAddress', 'toCountry'],
    ]
  )

  const getConsignorClientData = (clientId: any) => {
    clientServ
      .getClientDetailById(clientId)
      .then((res: any) => {
        setSelectedConsignorName(res?.data?.name)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getConsigneeClientData = (clientId: any) => {
    clientServ
      .getClientDetailById(clientId)
      .then((res: any) => {
        setSelectedConsigneeName(res?.data?.name)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      editedData &&
        editedData({
          ...values,
          freightContentTypeId: currentData?.freightContentType.freightContentTypeId,
        })
    },
  })

  /*disabled days for arrive date input */
  let disDate = new Date(formik.values.loadNonFormatDate)

  const fillUpdateScreenInputs = (dat: any) => {
    setTrailerUpdatedSelected(dat?.trailerType?.trailerTypeId)
    formik.setFieldValue('freightId', dat.freightId)
    formik.setFieldValue('title', dat?.description ?? '')
    formik.setFieldValue('value', dat?.value ?? '')
    formik.setFieldValue('width', dat?.width ?? '')
    formik.setFieldValue('height', dat?.height ?? '')
    formik.setFieldValue('weight', dat?.weight ?? '')
    formik.setFieldValue('freightLength', dat?.length ?? '')
    formik.setFieldValue('desi', dat?.desi ?? '')
    formik.setFieldValue('ldm', dat?.ldm ?? '')
    formik.setFieldValue('trailerType', dat?.trailerType?.trailerTypeId ?? '')
    formik.setFieldValue('flammable', dat?.flammable === 'Y')
    formik.setFieldValue('stackable', dat?.stackable === 'Y')
    formik.setFieldValue('currency', dat?.valueCurrency?.currencyId ?? '')
    dat?.plannedDepartureDate &&
      formik.setFieldValue('loadDate', format(new Date(dat?.plannedDepartureDate), 'yyyy-MM-dd'))
    formik.setFieldValue('loadNonFormatDate', new Date(dat?.plannedDepartureDate))
    dat?.plannedArrivalDate &&
      formik.setFieldValue('destDate', format(new Date(dat?.plannedArrivalDate), 'yyyy-MM-dd'))
    formik.setFieldValue('destNonFormatDate', new Date(dat?.plannedArrivalDate))
    formik.setFieldValue('loadTime', getTime(dat?.plannedDepartureDate))
    formik.setFieldValue('destTime', getTime(dat?.plannedArrivalDate))
    formik.setFieldValue('loadAddress', dat?.fromAddress?.addressId ?? '')
    formik.setFieldValue('destinationAddress', dat?.toAddress?.addressId ?? '')
    formik.setFieldValue('floorType', dat?.floorType?.floorTypeId ?? '')
    formik.setFieldValue('transportType', dat?.transportType?.transportTypeId)
    formik.setFieldValue('freightType', dat?.freightType?.freightTypeId)
    formik.setFieldValue('freightLoadType', dat?.freightLoadingType?.freightLoadingTypeId ?? '')
    formik.setFieldValue('freightPackageType', dat?.freightPackageType?.freightPackageTypeId ?? '')
    formik.setFieldValue('harmonizedSystemCode', dat?.harmonizedSystemCode?.code)

    if (dat?.fromAddress?.addressId) {
      formik.setFieldValue('fromCountry', '')
      formik.setFieldValue('fromCity', '')
      formik.setFieldValue('fromDistrict', '')
      formik.setFieldValue('fromNeighborhood', '')
      formik.setFieldValue('fromPostCode', '')
      formik.setFieldValue('fromStreet', '')
      formik.setFieldValue('fromStreetNumber', '')
      formik.setFieldValue('fromLatitude', '')
      formik.setFieldValue('fromLongitude', '')
      setSelectedFromAddressName(dat?.fromAddress?.description)
    } else {
      formik.setFieldValue('fromCountry', dat?.fromAddress?.country?.code)
      setTimeout(() => {
        formik.setFieldValue('fromCity', dat?.fromAddress?.city?.cityId)
      }, 100)
      formik.setFieldValue('fromDistrict', dat?.fromAddress?.district)
      formik.setFieldValue('fromNeighborhood', dat?.fromAddress?.neighborhood)
      formik.setFieldValue('fromPostCode', dat?.fromAddress?.postCode)
      formik.setFieldValue('fromStreet', dat?.fromAddress?.street)
      formik.setFieldValue('fromStreetNumber', dat?.fromAddress?.streetNumber)
      formik.setFieldValue('fromLatitude', dat?.fromAddress?.latitude)
      formik.setFieldValue('fromLongitude', dat?.fromAddress?.longitude)
      setSelectedFromAddressName('')
    }

    if (dat?.toAddress?.addressId) {
      formik.setFieldValue('toCountry', '')
      formik.setFieldValue('toCity', '')
      formik.setFieldValue('toDistrict', '')
      formik.setFieldValue('toNeighborhood', '')
      formik.setFieldValue('toPostCode', '')
      formik.setFieldValue('toStreet', '')
      formik.setFieldValue('toStreetNumber', '')
      formik.setFieldValue('toLatitude', '')
      formik.setFieldValue('toLongitude', '')
      setSelectedToAddressName(dat?.toAddress?.description)
    } else {
      formik.setFieldValue('toCountry', dat?.toAddress?.country?.code)
      setTimeout(() => {
        formik.setFieldValue('toCity', dat?.toAddress?.city?.cityId)
      }, 100)
      formik.setFieldValue('toDistrict', dat?.toAddress?.district)
      formik.setFieldValue('toNeighborhood', dat?.toAddress?.neighborhood)
      formik.setFieldValue('toPostCode', dat?.toAddress?.postCode)
      formik.setFieldValue('toStreet', dat?.toAddress?.street)
      formik.setFieldValue('toStreetNumber', dat?.toAddress?.streetNumber)
      formik.setFieldValue('toLatitude', dat?.toAddress?.latitude)
      formik.setFieldValue('toLongitude', dat?.toAddress?.longitude)
      setSelectedToAddressName('')
    }

    formik.setFieldValue('consignorName', dat?.consignor?.name ?? '')
    formik.setFieldValue('consignorSurName', dat?.consignor?.surname ?? '')
    formik.setFieldValue('consignorCountryCode', dat?.consignor?.countryCode ?? '')
    formik.setFieldValue('consignorPhone', dat?.consignor?.phone ?? '')
    formik.setFieldValue('consignorEmail', dat?.consignor?.email ?? '')
    formik.setFieldValue('consignorTaxNumber', dat?.consignor?.taxNumber ?? '')
    formik.setFieldValue('consignorTaxOffice', dat?.consignor?.taxOffice ?? '')
    formik.setFieldValue('consignorReferenceClientId', dat?.consignor?.referenceClientId ?? '')
    formik.setFieldValue('consigneeName', dat?.consignee?.name ?? '')
    formik.setFieldValue('consigneeSurName', dat?.consignee?.surname ?? '')
    formik.setFieldValue('consigneeCountryCode', dat?.consignee?.countryCode ?? '')
    formik.setFieldValue('consigneePhone', dat?.consignee?.phone ?? '')
    formik.setFieldValue('consigneeEmail', dat?.consignee?.email ?? '')
    formik.setFieldValue('consigneeTaxNumber', dat?.consignee?.taxNumber ?? '')
    formik.setFieldValue('consigneeTaxOffice', dat?.consignee?.taxOffice ?? '')
    formik.setFieldValue('consigneeReferenceClientId', dat?.consignee?.referenceClientId ?? '')

    if (dat?.consignor?.referenceClientId) {
      getConsignorClientData(dat?.consignor?.referenceClientId)
    }

    if (dat?.consignee?.referenceClientId) {
      getConsigneeClientData(dat?.consignee?.referenceClientId)
    }

    setActiveTab(dat?.fromAddress?.addressId ? 0 : 1)
  }

  useMemo(() => {
    if (currentData) {
      fillUpdateScreenInputs(currentData)
      setSpecUpdatedSelected(
        currentData?.specificationList?.map((item: any) => parseInt(item?.specificationId))
      )
    }
  }, [currentData])

  useEffect(() => {
    formik.values.fromCountry &&
      setTimeout(() => getCityList(formik.values.fromCountry, 'fromCity'), 200)
    if (requiredLocationContent) {
      setRequiredLocationContent(false)
    }
  }, [formik.values.fromCountry])

  useEffect(() => {
    formik.values.toCountry && setTimeout(() => getCityList(formik.values.toCountry, 'toCity'), 200)
    if (requiredLocationContent) {
      setRequiredLocationContent(false)
    }
  }, [formik.values.toCountry])

  const getConsignorClientsList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getClientsList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setConsignorClientScrollAPICalled(false)
        if (pageIndex === 0)
          setConsignorCompanyList(resp?.data?.filter((item: any) => item?.clientId))
        else setConsignorCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setConsignorClientScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getConsigneeClientsList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getClientsList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setConsigneeClientScrollAPICalled(false)
        if (pageIndex === 0)
          setConsigneeCompanyList(resp?.data?.filter((item: any) => item?.clientId))
        else setConsigneeCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setConsigneeClientScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const setTab = (tabId: number) => {
    setActiveTab(tabId)
  }

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCityList = (countryCode: string, key?: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp) => {
        if (key === 'fromCity') {
          setFromCityList(resp.data)
        } else if (key === 'toCity') {
          setToCityList(resp.data)
        } else {
          setCities(resp.data)
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Country and City List api call End **/

  const getArrivalDetail = (addressId: number) => {
    addressServ
      .getAdress(addressId)
      .then((resp: any) => {
        setArrivalDetail(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
      })
  }

  const getDepartureDetail = (addressId: number) => {
    addressServ
      .getAdress(addressId)
      .then((resp: any) => {
        setDepartureDetail(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
      })
  }

  const getNewAddressList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getAdressList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setFromAddressScrollAPICalled(false)
        if (pageIndex === 0) setFromAddressList(resp?.data?.filter((item: any) => item?.addressId))
        else setFromAddressList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromAddressScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getNewToAddressList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getAdressList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setToAddressScrollAPICalled(false)
        if (pageIndex === 0) setToAddressList(resp?.data?.filter((item: any) => item?.addressId))
        else setToAddressList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToAddressScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    if (formik?.values?.loadAddress) {
      getDepartureDetail(parseInt(formik?.values?.loadAddress))
    }
  }, [formik.values.loadAddress])

  useEffect(() => {
    if (formik?.values?.destinationAddress) {
      getArrivalDetail(parseInt(formik?.values?.destinationAddress))
    }
  }, [formik.values.destinationAddress])

  useMemo(
    () =>
      specUpdatedSelected &&
      specUpdatedSelected?.length &&
      formik.setFieldValue('specificationList', specUpdatedSelected),
    [specUpdatedSelected]
  )

  useEffect(() => {
    trailerUpdatedSelected && selectedTrailerType(trailerUpdatedSelected)
  }, [trailerUpdatedSelected])

  const clearNewAddressInputs = () => {
    formik.setFieldValue('fromCountry', '')
    formik.setFieldValue('fromCity', '')
    formik.setFieldValue('fromDistrict', '')
    formik.setFieldValue('fromNeighborhood', '')
    formik.setFieldValue('fromPostCode', '')
    formik.setFieldValue('fromStreet', '')
    formik.setFieldValue('fromStreetNumber', '')
    formik.setFieldValue('fromLatitude', '')
    formik.setFieldValue('fromLongitude', '')

    formik.setFieldValue('toCountry', '')
    formik.setFieldValue('toCity', '')
    formik.setFieldValue('toDistrict', '')
    formik.setFieldValue('toNeighborhood', '')
    formik.setFieldValue('toPostCode', '')
    formik.setFieldValue('toStreet', '')
    formik.setFieldValue('toStreetNumber', '')
    formik.setFieldValue('toLatitude', '')
    formik.setFieldValue('toLongitude', '')
  }

  const clearExistAddressInputs = () => {
    formik.setFieldValue('loadAddress', '')
    formik.setFieldValue('destinationAddress', '')
    setSelectedFromAddressName('')
    setSelectedToAddressName('')
    setDepartureDetail({})
    setArrivalDetail({})
  }

  const consignContent = () => (
    <>
      {/* <SidebarGeneral /> */}
      <ul
        className='sidebar-nav nav nav-tabs pt-5 px-5 justify-content-left'
        id='kt_sidebar_tabs'
        role='tablist'
      >
        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setActiveClientTab(0)
            }}
            className={`nav-main-wrap ${
              activeClientTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'
            }`}
            id='kt_sidebar_tab_1'
          >
            {intl.formatMessage({id: 'Carrier.freight.tab.existing'})}
          </a>
        </li>

        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setActiveClientTab(1)
            }}
            className={`nav-main-wrap ${
              activeClientTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'
            }`}
            id='kt_sidebar_tab_2'
          >
            {intl.formatMessage({id: 'Carrier.freight.tab.new'})}
          </a>
        </li>
      </ul>

      {/* begin::Sidebar Content */}
      <div id='kt_sidebar_content' className='border-wrap py-10 px-5 px-lg-5'>
        <div
          className='me-lg-n2 pe-lg-2'
          data-kt-scroll='true'
          data-kt-scroll-offset='10px'
          data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
          data-kt-scroll-wrappers='#kt_sidebar_content'
        >
          <div className='tab-content'>
            <div
              className={clsx('tab-pane', {active: activeClientTab === 0})}
              id='kt_sidebar_tab_pane_1'
              role='tabpanel'
            >
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div className={'mb-7'}>
                    <label htmlFor='consignorReferenceClientId' className={'text-muted fs-4 mb-2'}>
                      {intl.formatMessage({id: 'Carrier.freight.create.consignor.client'})}
                    </label>
                    <Dropbox
                      hideArrow
                      id={'consignorReferenceClientId'}
                      customClass={'form-select'}
                      placeholder={intl.formatMessage({
                        id: 'Carrier.freight.create.consignor.client.select',
                      })}
                      customSearchValue={consignorClientCustomSearch}
                      customOnSearch={(e: any) => {
                        setConsignorClientPageIndex(0)
                        setConsignorClientCustomSearch(e.target.value)
                        getConsignorClientsList(0, e.target.value)
                      }}
                      onScroll={(val: any) => {
                        setConsignorClientPageIndex(val)
                        getConsignorClientsList(val, consignorClientCustomSearch)
                      }}
                      setScrollCalled={() => {
                        setConsignorClientScrollAPICalled(true)
                      }}
                      scrollAPIResponse={consignorClientScrollAPICalled}
                      currentPageIndex={consignorClientPageIndex}
                      menuList={
                        consignorCompanyList &&
                        consignorCompanyList
                          .filter(function ({clientId}: any) {
                            // @ts-ignore
                            return !this[clientId] && (this[clientId] = clientId)
                          }, {})
                          ?.map((item: any) => {
                            return {id: item?.clientId, title: item?.name}
                          })
                      }
                      defaultSelected={{
                        id: formik.values.consignorReferenceClientId,
                        title: selectedConsignorName,
                      }}
                      defaultClearItem={{
                        id: '',
                        title: '',
                      }}
                      selectedItemData={(item: any) => {
                        if (item?.id) {
                          formik.setFieldValue('consignorReferenceClientId', item?.id)
                          setSelectedConsignorName(item?.title)
                        }

                        if (item?.id === '' && item?.title === '') {
                          formik.setFieldValue('consignorReferenceClientId', '')
                          setSelectedConsignorName('')
                        }
                      }}
                    />
                    {formik.touched.consignorReferenceClientId &&
                      formik.errors.consignorReferenceClientId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.consignorReferenceClientId}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className={'col-md-6'}>
                  <div className={'mb-7'}>
                    <label htmlFor='consigneeReferenceClientId' className={'text-muted fs-4 mb-2'}>
                      {intl.formatMessage({id: 'Carrier.freight.create.consignee.client'})}
                    </label>
                    <Dropbox
                      hideArrow
                      id={'consigneeReferenceClientId'}
                      customClass={'form-select'}
                      placeholder={intl.formatMessage({
                        id: 'Carrier.freight.create.consignee.client.select',
                      })}
                      customSearchValue={consigneeClientCustomSearch}
                      customOnSearch={(e: any) => {
                        setConsigneeClientPageIndex(0)
                        setConsigneeClientCustomSearch(e.target.value)
                        getConsigneeClientsList(0, e.target.value)
                      }}
                      onScroll={(val: any) => {
                        setConsigneeClientPageIndex(val)
                        getConsigneeClientsList(val, consignorClientCustomSearch)
                      }}
                      setScrollCalled={() => {
                        setConsigneeClientScrollAPICalled(true)
                      }}
                      scrollAPIResponse={consigneeClientScrollAPICalled}
                      currentPageIndex={consigneeClientPageIndex}
                      menuList={
                        consigneeCompanyList &&
                        consigneeCompanyList
                          .filter(function ({clientId}: any) {
                            // @ts-ignore
                            return !this[clientId] && (this[clientId] = clientId)
                          }, {})
                          ?.map((item: any) => {
                            return {id: item?.clientId, title: item?.name}
                          })
                      }
                      defaultSelected={{
                        id: formik.values.consigneeReferenceClientId,
                        title: selectedConsigneeName,
                      }}
                      defaultClearItem={{
                        id: '',
                        title: '',
                      }}
                      selectedItemData={(item: any) => {
                        if (item?.id) {
                          formik.setFieldValue('consigneeReferenceClientId', item?.id)
                          setSelectedConsigneeName(item?.title)
                        }

                        if (item?.id === '' && item?.title === '') {
                          formik.setFieldValue('consigneeReferenceClientId', '')
                          setSelectedConsigneeName('')
                        }
                      }}
                    />
                    {formik.touched.consigneeReferenceClientId &&
                      formik.errors.consigneeReferenceClientId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.consigneeReferenceClientId}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={clsx('tab-pane', {active: activeClientTab === 1})}
              id='kt_sidebar_tab_pane_2'
              role='tabpanel'
            >
              <div>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />
                <div className={'row'}>
                  <div className='col-md-6'>
                    <div className='row consignor-wrap'>
                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorName', event.target.value)
                          }
                          value={formik.values.consignorName}
                          type='text'
                          name='consignorName'
                          autoComplete='off'
                        />
                        {formik.touched.consignorName && formik.errors.consignorName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.surName'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorSurName', event.target.value)
                          }
                          value={formik.values.consignorSurName}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consignorSurName && formik.errors.consignorSurName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorSurName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={'col-md-4'}>
                        <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                        </label>
                        <PhoneInp
                          id={'consignorCountryCode'}
                          onSelect={(event: any) => {
                            formik.setFieldValue(
                              'consignorCountryCode',
                              event.length > 0 ? '+' + event : event
                            )
                          }}
                          className='form-control form-control-lg form-control-solid w-100 bg-white'
                        />
                        {formik.touched.consignorCountryCode &&
                          formik.errors.consignorCountryCode && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' style={{color: '#DC3444'}}>
                                  {formik.errors.consignorCountryCode}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='fv-row col-md-8 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'Carrier.drivers.create.phone.placeHolder',
                          })}
                          maxLength={13}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) => {
                            if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                              formik.setFieldValue('consignorPhone', event.target.value)
                            }
                          }}
                          value={formik.values.consignorPhone}
                          type='text'
                          name='consignorPhone'
                          autoComplete='off'
                          required
                        />
                        {formik.touched.consignorPhone && formik.errors.consignorPhone && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorPhone}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='mb-10 col-md-12 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'CreateClient.create.email'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorEmail', event.target.value)
                          }
                          value={formik.values.consignorEmail}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consignorEmail && formik.errors.consignorEmail && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorEmail}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorTaxNumber', event.target.value)
                          }
                          value={formik.values.consignorTaxNumber}
                          type='text'
                          name='consignorTaxNumber'
                          autoComplete='off'
                        />
                        {formik.touched.consignorTaxNumber && formik.errors.consignorTaxNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorTaxNumber}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorTaxOffice', event.target.value)
                          }
                          value={formik.values.consignorTaxOffice}
                          type='text'
                          name='consignorTaxOffice'
                          autoComplete='off'
                        />
                        {formik.touched.consignorTaxOffice && formik.errors.consignorTaxOffice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorTaxOffice}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row consignee-wrap'>
                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeName', event.target.value)
                          }
                          value={formik.values.consigneeName}
                          type='text'
                          name='consigneeName'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeName && formik.errors.consigneeName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.surName'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeSurName', event.target.value)
                          }
                          value={formik.values.consigneeSurName}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeSurName && formik.errors.consigneeSurName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeSurName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={'col-md-4'}>
                        <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                        </label>
                        <PhoneInp
                          id={'consigneeCountryCode'}
                          onSelect={(event: any) => {
                            formik.setFieldValue(
                              'consigneeCountryCode',
                              event.length > 0 ? '+' + event : event
                            )
                          }}
                          className='form-control form-control-lg form-control-solid w-100 bg-white'
                        />
                        {formik.touched.consigneeCountryCode &&
                          formik.errors.consigneeCountryCode && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' style={{color: '#DC3444'}}>
                                  {formik.errors.consigneeCountryCode}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='fv-row col-md-8 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'Carrier.drivers.create.phone.placeHolder',
                          })}
                          maxLength={13}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) => {
                            if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                              formik.setFieldValue('consigneePhone', event.target.value)
                            }
                          }}
                          value={formik.values.consigneePhone}
                          type='text'
                          name='consigneePhone'
                          autoComplete='off'
                          required
                        />
                        {formik.touched.consigneePhone && formik.errors.consigneePhone && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneePhone}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='mb-10 col-md-12 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'CreateClient.create.email'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeEmail', event.target.value)
                          }
                          value={formik.values.consigneeEmail}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeEmail && formik.errors.consigneeEmail && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeEmail}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeTaxNumber', event.target.value)
                          }
                          value={formik.values.consigneeTaxNumber}
                          type='text'
                          name='consigneeTaxNumber'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeTaxNumber && formik.errors.consigneeTaxNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeTaxNumber}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeTaxOffice', event.target.value)
                          }
                          value={formik.values.consigneeTaxOffice}
                          type='text'
                          name='consigneeTaxOffice'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeTaxOffice && formik.errors.consigneeTaxOffice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeTaxOffice}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Admin.Adverts.editPopupTitle'})}
        className='right-layout-show-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        {/*ARAÇ SEÇENEKLERİ*/}
        <div className={'mt-10'}>
          <h3 className={'mb-10'}>
            {intl.formatMessage({id: 'Freight.myAdvert.update.vehicleOption'})}
          </h3>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='title' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.descriptions'})}
              </label>
              <input
                type='text'
                id={'title'}
                placeholder={intl.formatMessage({
                  id: 'Freight.myAdvert.update.description.placeholder',
                })}
                value={formik.values.title}
                onChange={(e) => formik.setFieldValue('title', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='trailerType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.trailerType'})}
              </label>
              <select
                id={'trailerType'}
                className='form-select'
                onChange={(e: any) => {
                  // @ts-ignore
                  setTrailerUpdatedSelected(parseInt(e.target.value))
                  setSpecUpdatedSelected([])
                }}
                value={trailerUpdatedSelected}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectTrailerType'})}
                </option>
                {trailerTypes &&
                  trailerTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.trailerTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {!trailerUpdatedSelected && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.trailerType}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='floorType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.floorType'})}
              </label>
              <select
                id={'floorType'}
                className='form-select'
                {...formik.getFieldProps('floorType')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectFloorType'})}
                </option>
                {floorTypes &&
                  floorTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.floorTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.floorType && formik.errors.floorType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.floorType}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-15'}>
              <label htmlFor='trailerSpecification' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.specificationList'})}
              </label>
              <Suspense fallback={<div>Loading...</div>}>
                <NewCheckGroup
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setSpecUpdatedSelected((item: any) => [...item, parseInt(e?.target?.value)])
                    } else {
                      setSpecUpdatedSelected((item: any) =>
                        item.filter((itm: any) => itm !== parseInt(e.target.value))
                      )
                    }
                  }}
                  values={specUpdatedSelected}
                  data={
                    trailerSpecifications &&
                    trailerSpecifications?.map((item: any) => {
                      return {
                        id: item?.specificationId,
                        label: item?.description,
                      }
                    })
                  }
                  placeholder={intl.formatMessage({
                    id: 'Freight.myAdvert.update.specificationListWarning',
                  })}
                />
              </Suspense>
              {(!specUpdatedSelected ||
                parseInt(formik.values.trailerType) !== 1 ||
                !specUpdatedSelected.length) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.specificationList}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='transportType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.transportType'})}
              </label>
              <select
                id={'transportType'}
                className='form-select'
                {...formik.getFieldProps('transportType')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectTransportType'})}
                </option>
                {transportTypes &&
                  transportTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.transportTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.transportType && formik.errors.transportType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.transportType}</span>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
        <hr />

        <h3 className={'mt-10'}>{intl.formatMessage({id: 'Carrier.freight.create.title'})}</h3>

        {consignContent()}

        <hr />
        {/*TARİH VE ZAMAN*/}
        <div className={'mt-10'}>
          <h3 className={'mb-10'}>
            {intl.formatMessage({id: 'Freight.myAdvert.update.dateAndTime'})}
          </h3>
          <div className={'row mb-10'}>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='loadDate' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.plannedDepartureDate'})}
              </label>
              <DatePicker
                value={formik.values.loadNonFormatDate}
                onSelect={(data: any) => {
                  formik.setFieldValue('loadDate', format(data, 'yyyy-MM-dd'))
                  formik.setFieldValue('loadNonFormatDate', data)
                }}
                selected={!!formik.values.loadDate}
                customDate={formik.values.loadNonFormatDate}
                disabledDays={new Date()}
              />
              {formik.touched.loadDate && formik.errors.loadDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.loadDate}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='destDate' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.plannedArrivalDate'})}
              </label>
              <DatePicker
                disabled={!formik.values.loadDate}
                value={formik.values.destNonFormatDate}
                selected={!!formik.values.destDate}
                onSelect={(data: any) => {
                  formik.setFieldValue('destDate', format(data, 'yyyy-MM-dd'))
                  formik.setFieldValue('destNonFormatDate', data)
                }}
                customDate={formik.values.destNonFormatDate}
                disabledDays={disDate.setDate(disDate.getDate() + 1)}
              />
              {formik.touched.destDate && formik.errors.destDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.destDate}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='loadTime' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.plannedDepartureTime'})}
              </label>
              <MyTimePicker
                onChange={(val: string) => {
                  formik.setFieldValue('loadTime', val)
                }}
                value={formik.values?.loadTime}
              />
              {formik.touched.loadTime && formik.errors.loadTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.loadTime}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='loadTime' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.plannedArrivalTime'})}
              </label>
              <MyTimePicker
                onChange={(val: string) => {
                  formik.setFieldValue('destTime', val)
                }}
                value={formik.values?.destTime}
              />
              {formik.touched.destTime && formik.errors.destTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.destTime}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        {/*NEREDEN NEREYE*/}
        <div className={'mt-10'}>
          <h3 className={'mb-10'}>{intl.formatMessage({id: 'Admin.Adverts.Edit.Area-3-title'})}</h3>
          <ul
            className='sidebar-nav nav nav-tabs pt-5 px-5 justify-content-left'
            id='kt_sidebar_tabs'
            role='tablist'
          >
            <li className='nav-item tab-wrapper'>
              <a
                onClick={() => {
                  setTab(0)
                }}
                className={`nav-main-wrap ${
                  activeTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'
                }`}
                id='kt_sidebar_tab_1'
              >
                {intl.formatMessage({id: 'Carrier.freight.create.existingAddress'})}
              </a>
            </li>

            <li className='nav-item tab-wrapper'>
              <a
                onClick={() => {
                  setTab(1)
                }}
                className={`nav-main-wrap ${
                  activeTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'
                }`}
                id='kt_sidebar_tab_2'
              >
                {intl.formatMessage({id: 'Carrier.freight.create.newAddress'})}
              </a>
            </li>
          </ul>

          <div id='kt_sidebar_content' className='py-10 px-5 px-lg-5'>
            <div
              className='me-lg-n2 pe-lg-2'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-offset='10px'
              data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
              data-kt-scroll-wrappers='#kt_sidebar_content'
            >
              <div className='tab-content'>
                <div
                  className={clsx('tab-pane', {active: activeTab === 0})}
                  id='kt_sidebar_tab_pane_1'
                  role='tabpanel'
                >
                  <div className={'mt-10'}>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div className='col-md-12'>
                          <div className={'mb-7'}>
                            <label htmlFor='loadAddress' className={'text-muted fs-4 mb-2'}>
                              {intl.formatMessage({id: 'Admin.Adverts.fromLocation'})}
                            </label>
                            <Dropbox
                              hideArrow
                              id={'loadAddress'}
                              customClass={'form-select'}
                              placeholder={intl.formatMessage({
                                id: 'Freight.createAdvert.selectDownloadLoc',
                              })}
                              customSearchValue={fromAddressCustomSearch}
                              customOnSearch={(e: any) => {
                                setFromAddressPageIndex(0)
                                setFromAddressCustomSearch(e.target.value)
                                getNewAddressList(0, e.target.value)
                              }}
                              onScroll={(val: any) => {
                                setFromAddressPageIndex(val)
                                getNewAddressList(val, fromAddressCustomSearch)
                              }}
                              setScrollCalled={() => {
                                setFromAddressScrollAPICalled(true)
                              }}
                              scrollAPIResponse={fromAddressScrollAPICalled}
                              currentPageIndex={fromAddressPageIndex}
                              menuList={
                                fromAddressList &&
                                fromAddressList
                                  .filter(function ({addressId}: any) {
                                    // @ts-ignore
                                    return !this[addressId] && (this[addressId] = addressId)
                                  }, {})
                                  ?.map((item: any) => {
                                    return {id: item?.addressId, title: item?.description}
                                  })
                              }
                              defaultSelected={{
                                id: formik.values.loadAddress,
                                title: selectedFromAddressName,
                              }}
                              dependentValue={clearFromAddress}
                              defaultClearItem={{
                                id: '',
                                title: '',
                              }}
                              selectedItemData={(item: any) => {
                                if (item?.id) {
                                  formik.setFieldValue('loadAddress', item?.id)
                                  setSelectedFromAddressName(item?.title)
                                  clearNewAddressInputs()
                                  if (clearFromAddress) {
                                    setClearFromAddress(false)
                                  }
                                }

                                if (item?.id === '' && item?.title === '') {
                                  formik.setFieldValue('loadAddress', '')
                                  setSelectedFromAddressName('')
                                  clearNewAddressInputs()
                                  setDepartureDetail({})
                                  if (clearFromAddress) {
                                    setClearFromAddress(false)
                                  }
                                }
                              }}
                            />
                            {formik.touched.loadAddress && formik.errors.loadAddress && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' style={{color: '#DC3444'}}>
                                    {formik.errors.loadAddress}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {departureDetail && Object.keys(departureDetail).length > 0 && (
                            <div className={'col-md-12 editFromWrap'}>
                              <div
                                className={'row border border-2 rounded py-4'}
                                style={{margin: 0}}
                              >
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.explanation',
                                    })}
                                  </p>
                                  <b>{departureDetail?.description}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.country',
                                    })}
                                  </p>
                                  <b>{departureDetail?.country?.name}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.city',
                                    })}
                                  </p>
                                  <b>{departureDetail?.city?.name}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.town',
                                    })}
                                  </p>
                                  <b>{departureDetail?.town}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.district',
                                    })}
                                  </p>
                                  <b>{departureDetail?.district}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.postCode',
                                    })}
                                  </p>
                                  <b>{departureDetail?.postCode}</b>
                                </div>
                                <hr />
                                <div className={'mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.departure.fullAddress',
                                    })}
                                  </p>
                                  <b>{departureDetail?.addressText}</b>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={'col-md-6'}>
                        <div className='col-md-12'>
                          <div className={'mb-7'}>
                            <label htmlFor='destinationAddress' className={'text-muted fs-4 mb-2'}>
                              {intl.formatMessage({id: 'Admin.Adverts.toLocation'})}
                            </label>
                            <Dropbox
                              hideArrow
                              disabled={!formik.values.loadAddress}
                              id={'destinationAddress'}
                              customClass={'form-select'}
                              placeholder={intl.formatMessage({
                                id: 'Freight.createAdvert.selectDownloadLoc',
                              })}
                              customSearchValue={toAddressCustomSearch}
                              customOnSearch={(e: any) => {
                                setToAddressPageIndex(0)
                                setToAddressCustomSearch(e.target.value)
                                getNewToAddressList(0, e.target.value)
                              }}
                              onScroll={(val: any) => {
                                setToAddressPageIndex(val)
                                getNewToAddressList(val, toAddressCustomSearch)
                              }}
                              setScrollCalled={() => {
                                setToAddressScrollAPICalled(true)
                              }}
                              scrollAPIResponse={toAddressScrollAPICalled}
                              currentPageIndex={toAddressPageIndex}
                              menuList={
                                toAddressList &&
                                toAddressList
                                  .filter(function ({addressId}: any) {
                                    // @ts-ignore
                                    return !this[addressId] && (this[addressId] = addressId)
                                  }, {})
                                  ?.map((item: any) => {
                                    return {id: item?.addressId, title: item?.description}
                                  })
                              }
                              defaultSelected={{
                                id: formik.values.destinationAddress,
                                title: selectedToAddressName,
                              }}
                              defaultClearItem={{
                                id: '',
                                title: '',
                              }}
                              dependentValue={clearToAddress}
                              selectedItemData={(item: any) => {
                                if (item?.id) {
                                  formik.setFieldValue('destinationAddress', item?.id)
                                  setSelectedToAddressName(item?.title)
                                  clearNewAddressInputs()
                                  if (clearToAddress) {
                                    setClearToAddress(false)
                                  }
                                }

                                if (item?.id === '' && item?.title === '') {
                                  formik.setFieldValue('destinationAddress', '')
                                  setSelectedToAddressName('')
                                  clearNewAddressInputs()
                                  setArrivalDetail({})
                                  if (clearToAddress) {
                                    setClearToAddress(false)
                                  }
                                }
                              }}
                            />
                            {formik.touched.destinationAddress &&
                              formik.errors.destinationAddress && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' style={{color: '#DC3444'}}>
                                      {formik.errors.destinationAddress}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {arrivalDetail && Object.keys(arrivalDetail).length > 0 && (
                            <div className={'col-md-12 editToWrap'}>
                              <div
                                className={'row border border-2 rounded py-4'}
                                style={{margin: 0}}
                              >
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.arrival.explanation',
                                    })}
                                  </p>
                                  <b>{arrivalDetail?.description}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.arrival.country',
                                    })}
                                  </p>
                                  <b>{arrivalDetail?.country?.name}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                                  </p>
                                  <b>{arrivalDetail?.city?.name}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.arrival.district',
                                    })}
                                  </p>
                                  <b>{arrivalDetail?.town}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                                  </p>
                                  <b>{arrivalDetail?.district}</b>
                                </div>
                                <div className={'col-md-4 mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.arrival.postCode',
                                    })}
                                  </p>
                                  <b>{arrivalDetail?.postCode}</b>
                                </div>
                                <hr />
                                <div className={'mb-5 '}>
                                  <p className={'mb-0 text-muted'}>
                                    {intl.formatMessage({
                                      id: 'Freight.createAdvert.arrival.fullAddress',
                                    })}
                                  </p>
                                  <b>{arrivalDetail?.addressText}</b>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={clsx('tab-pane', {active: activeTab === 1})}
                  id='kt_sidebar_tab_pane_2'
                  role='tabpanel'
                >
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row edit-wrap-address'>
                        {/* from address */}
                        <div className='col-md-6 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                          </label>
                          <select
                            placeholder={intl.formatMessage({
                              id: 'addNewAddressPopup.area2.country.placeholder',
                            })}
                            {...formik.getFieldProps('fromCountry')}
                            className={'form-control form-control-lg'}
                            onChange={(e: any) => {
                              formik.setFieldValue('fromCountry', e.target.value)
                              formik.setFieldValue('fromCity', '')
                              clearExistAddressInputs()
                              setClearFromAddress(true)
                            }}
                          >
                            <option value='' selected disabled>
                              {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                            </option>
                            {countries &&
                              countries?.map((item: any, key: number) => (
                                <option
                                  key={key}
                                  /*selected={country === item?.code}*/ value={item?.code}
                                >
                                  {item?.name}
                                </option>
                              ))}
                          </select>
                          {formik.touched?.fromCountry && formik.errors?.fromCountry && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span className={'text-danger'} role='alert'>
                                  {formik.errors?.fromCountry}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-md-5 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                          </label>
                          <select
                            disabled={!formik.values.fromCountry}
                            placeholder={intl.formatMessage({
                              id: 'addNewAddressPopup.area2.city.placeholder',
                            })}
                            {...formik.getFieldProps('fromCity')}
                            className={'form-control form-control-lg'}
                          >
                            <option value='' selected={!formik.values.fromCity} disabled>
                              {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                            </option>
                            {formik.values.fromCountry &&
                              fromCityList?.map((item: any) => (
                                <option key={item.cityId} value={item?.cityId}>
                                  {item?.originalName}
                                </option>
                              ))}
                          </select>
                          {formik.touched.fromCity && formik.errors.fromCity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span className={'text-danger'} role='alert'>
                                  {formik.errors.fromCity}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='d-flex col-md-1 align-items-center map-content-warp'>
                          <img
                            src='/media/google_maps.svg'
                            className='map-wrap'
                            onClick={() => setIsMapFromView(true)}
                          />
                        </div>
                        <div className={'form-check mb-6 col-md-8 show-address-wrap'}>
                          <input
                            type='checkbox'
                            id={'adr1'}
                            onChange={() => setShowFromAddress(!showFromAddress)}
                            checked={showFromAddress}
                            className={'form-check-input'}
                          />
                          <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
                            {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                          </label>
                        </div>
                      </div>

                      <div className='row'>
                        {showFromAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.district1.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('fromDistrict')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                              )}
                            />
                          </div>
                        )}

                        {showFromAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('fromNeighborhood')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        {showFromAddress && (
                          <div className='col-md-12 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('fromPostCode')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        {showFromAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.street.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('fromStreet')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                              )}
                            />
                          </div>
                        )}

                        {showFromAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('fromStreetNumber')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='row edit-wrap-address-left'>
                        {/* to Address */}
                        <div className='col-md-6 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                          </label>
                          <select
                            placeholder={intl.formatMessage({
                              id: 'addNewAddressPopup.area2.country.placeholder',
                            })}
                            {...formik.getFieldProps('toCountry')}
                            className={'form-control form-control-lg'}
                            onChange={(e: any) => {
                              formik.setFieldValue('toCountry', e.target.value)
                              formik.setFieldValue('toCity', '')
                              clearExistAddressInputs()
                              setClearToAddress(true)
                            }}
                          >
                            <option value='' selected disabled>
                              {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                            </option>
                            {countries?.map((item: any, key: number) => (
                              <option
                                key={key}
                                /*selected={country === item?.code}*/ value={item?.code}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched?.toCountry && formik.errors?.toCountry && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span className={'text-danger'} role='alert'>
                                  {formik.errors?.toCountry}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-md-5 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                          </label>
                          <select
                            disabled={!formik.values.toCountry}
                            placeholder={intl.formatMessage({
                              id: 'addNewAddressPopup.area2.city.placeholder',
                            })}
                            {...formik.getFieldProps('toCity')}
                            className={'form-control form-control-lg'}
                          >
                            <option value='' selected={!formik.values.toCity} disabled>
                              {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                            </option>
                            {formik.values.toCountry &&
                              toCityList?.map((item: any) => (
                                <option key={item.cityId} value={item?.cityId}>
                                  {item?.originalName}
                                </option>
                              ))}
                          </select>
                          {formik.touched.toCity && formik.errors.toCity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span className={'text-danger'} role='alert'>
                                  {formik.errors.toCity}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='d-flex col-md-1 align-items-center map-content-warp'>
                          <img
                            src='/media/google_maps.svg'
                            className='map-wrap'
                            onClick={() => setIsMapToView(true)}
                          />
                        </div>
                        <div className={'form-check mb-4 col-md-8 show-address-wrap'}>
                          <input
                            type='checkbox'
                            id={'adr2'}
                            onChange={() => setShowToAddress(!showToAddress)}
                            checked={showToAddress}
                            className={'form-check-input '}
                          />
                          <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                            {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                          </label>
                        </div>
                      </div>

                      <div className='row'>
                        {showToAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.district1.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('toDistrict')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                                // {
                                //     'is-invalid': formik.touched.district && formik.errors.district,
                                // },
                                // {
                                //     'is-valid': formik.touched.district && !formik.errors.district,
                                // },
                              )}
                            />
                          </div>
                        )}

                        {showToAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('toNeighborhood')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                                // {
                                //     'is-invalid': formik.touched.neighborhood && formik.errors.neighborhood,
                                // },
                                // {
                                //     'is-valid': formik.touched.neighborhood && !formik.errors.neighborhood,
                                // },
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        {showToAddress && (
                          <div className='col-md-12 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('toPostCode')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                                // {
                                //     'is-invalid': formik.touched.postCode && formik.errors.postCode,
                                // },
                                // {
                                //     'is-valid': formik.touched.postCode && !formik.errors.postCode,
                                // },
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        {showToAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.street.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('toStreet')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                                // {
                                //     'is-invalid': formik.touched.street && formik.errors.street,
                                // },
                                // {
                                //     'is-valid': formik.touched.street && !formik.errors.street,
                                // },
                              )}
                            />
                          </div>
                        )}

                        {showToAddress && (
                          <div className='col-md-6 mb-5'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                            </label>
                            <input
                              placeholder={intl.formatMessage({
                                id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                              })}
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('toStreetNumber')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid bg-white'
                                // {
                                //     'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber,
                                // },
                                // {
                                //     'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                                // },
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/*MAL BİLGİLERİ*/}
        <div className={'mt-10'}>
          <h3 className={'mb-10'}>{intl.formatMessage({id: 'Admin.Adverts.Edit.Area-4-title'})}</h3>
          <div className={'row'}>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='freightLoadType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.valueCurrency'})}
              </label>
              <div className={'row'}>
                <div className={'col-md-7 mb-5'}>
                  <input
                    type='number'
                    id={'value'}
                    step='any'
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    placeholder={intl.formatMessage({id: 'Freight.myAdvert.update.enterValue'})}
                    value={formik.values.value}
                    onChange={(e) => formik.setFieldValue('value', e.target.value)}
                    min={0}
                    className={clsx('form-control', {
                      'price-input-wrap': formik.values.value,
                    })}
                  />
                </div>
                <div className={'col-md-5'}>
                  <select
                    id={'currency'}
                    className='form-select'
                    {...formik.getFieldProps('currency')}
                  >
                    <option value='' selected disabled>
                      {intl.formatMessage({id: 'Carrier.newContainer.create.currency'})}
                    </option>
                    {currencyList &&
                      currencyList.map((item: any, key: number) => (
                        <option key={key} value={item?.currencyId}>
                          {item?.description}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {formik.touched.value && formik.errors.value && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.value}</span>
                  </div>
                </div>
              )}
              {formik.touched.currency && formik.errors.currency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.currency}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='freightType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.freightType'})}
              </label>
              <select
                id={'freightType'}
                className='form-select'
                {...formik.getFieldProps('freightType')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectLoadType'})}
                </option>
                {freightTypes &&
                  freightTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.freightTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.freightType && formik.errors.freightType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.freightType}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='freightPackageType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.packageType'})}
              </label>
              <select
                id={'freightPackageType'}
                className='form-select'
                {...formik.getFieldProps('freightPackageType')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectPackagingType'})}
                </option>
                {freightPackageTypes &&
                  freightPackageTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.freightPackageTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.freightPackageType && formik.errors.freightPackageType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.freightPackageType}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='freightLoadType' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.loadingType'})}
              </label>
              <select
                id={'freightLoadType'}
                className='form-select'
                {...formik.getFieldProps('freightLoadType')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Freight.createAdvert.selectUploadType'})}
                </option>
                {freightLoadTypes &&
                  freightLoadTypes?.map((item: any, key: number) => (
                    <option key={key} value={item?.freightLoadingTypeId}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.freightLoadType && formik.errors.freightLoadType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.freightLoadType}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='harmonizedSystemCode' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.gtip'})}
              </label>
              <select
                id={'harmonizedSystemCode'}
                className='form-select'
                {...formik.getFieldProps('harmonizedSystemCode')}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({id: 'Carrier.freight.create.gtip'})}
                </option>
                {gtipList &&
                  gtipList?.map((item: any, key: number) => (
                    <option key={key} value={item?.code}>
                      {item?.description}
                    </option>
                  ))}
              </select>
              {formik.touched.harmonizedSystemCode && formik.errors.harmonizedSystemCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.harmonizedSystemCode}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='freightLength' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.lengthValue'})}
              </label>
              <input
                type='text'
                id={'freightLength'}
                placeholder={intl.formatMessage({
                  id: 'Freight.myAdvert.update.lengthValue.placeHolder',
                })}
                value={formik.values.freightLength}
                onChange={(e) => formik.setFieldValue('freightLength', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.freightLength && formik.errors.freightLength && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.freightLength}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='width' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.widthValue'})}
              </label>
              <input
                type='text'
                id={'width'}
                placeholder={intl.formatMessage({
                  id: 'Freight.myAdvert.update.widthValue.placeHolder',
                })}
                value={formik.values.width}
                onChange={(e) => formik.setFieldValue('width', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.width && formik.errors.width && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.width}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='height' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.heightValue'})}
              </label>
              <input
                type='text'
                id={'height'}
                placeholder={intl.formatMessage({
                  id: 'Freight.myAdvert.update.heightValue.placeHolder',
                })}
                value={formik.values.height}
                onChange={(e) => formik.setFieldValue('height', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.height && formik.errors.height && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.height}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='weight' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.grossKg'})}
              </label>
              <input
                type='text'
                id={'weight'}
                placeholder={intl.formatMessage({
                  id: 'Freight.myAdvert.update.grossKg.placeHolder',
                })}
                value={formik.values.weight}
                onChange={(e) => formik.setFieldValue('weight', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.weight && formik.errors.weight && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.weight}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='desi' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.desi'})}
              </label>
              <input
                type='text'
                id={'desi'}
                placeholder={intl.formatMessage({id: 'Freight.myAdvert.update.desi.placeHolder'})}
                value={formik.values.desi}
                onChange={(e) => formik.setFieldValue('desi', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.desi && formik.errors.desi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.desi}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <label htmlFor='ldm' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Freight.myAdvert.update.ldm'})}
              </label>
              <input
                type='text'
                id={'ldm'}
                placeholder={intl.formatMessage({id: 'Freight.myAdvert.update.ldm.placeHolder'})}
                value={formik.values.ldm}
                onChange={(e) => formik.setFieldValue('ldm', e.target.value)}
                className={'form-control'}
              />
              {formik.touched.ldm && formik.errors.ldm && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.ldm}</span>
                  </div>
                </div>
              )}
            </div>
            <div className={'col-md-6 mb-10'}>
              <div className={'form-check mb-4'}>
                <input
                  type='checkbox'
                  id={'flammable'}
                  onChange={(e) => {
                    formik.setFieldValue('flammable', !formik.values.flammable)
                  }}
                  checked={formik.values.flammable}
                  className={'form-check-input '}
                />
                <label htmlFor='flammable' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.update.productContaining'})}
                </label>
              </div>
              <div className={'form-check'}>
                <input
                  type='checkbox'
                  id={'istif'}
                  onChange={(e) => formik.setFieldValue('stackable', !formik.values.stackable)}
                  checked={formik.values.stackable}
                  className={'form-check-input '}
                />
                <label htmlFor='istif' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Freight.myAdvert.update.stackable'})}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />

        {(isMapFromView || isMapToView) && (
          <CreateMapAddress
            handleClose={() => {
              if (isMapFromView) {
                setIsMapFromView(false)
              }

              if (isMapToView) {
                setIsMapToView(false)
              }
            }}
            setFillupAddress={(data: any) => {
              if (isMapFromView) {
                formik.setFieldValue('fromCountry', data.countryCode)
                formik.setFieldValue('fromCity', data.cityId)
                formik.setFieldValue('fromDistrict', data.district)
                formik.setFieldValue('fromNeighborhood', data.neighborhood)
                formik.setFieldValue('fromPostCode', data.postCode)
                formik.setFieldValue('fromStreet', data.street)
                formik.setFieldValue('fromStreetNumber', data.streetNumber)
                formik.setFieldValue('fromLatitude', data.latitude)
                formik.setFieldValue('fromLongitude', data.longitude)
                setClearFromAddress(true)
              }

              if (isMapToView) {
                formik.setFieldValue('toCountry', data.countryCode)
                formik.setFieldValue('toCity', data.cityId)
                formik.setFieldValue('toDistrict', data.district)
                formik.setFieldValue('toNeighborhood', data.neighborhood)
                formik.setFieldValue('toPostCode', data.postCode)
                formik.setFieldValue('toStreet', data.street)
                formik.setFieldValue('toStreetNumber', data.streetNumber)
                formik.setFieldValue('toLatitude', data.latitude)
                formik.setFieldValue('toLongitude', data.longitude)
                setClearToAddress(true)
              }
              clearExistAddressInputs()
            }}
          />
        )}

        <div className='text-center mt-5 row view-details-freight-popup'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
            </button>
          </div>
          <div className={'col-md-6'}>
            <button
              className={'btn btn-success w-100'}
              onClick={() => formik.handleSubmit()}
              type={'submit'}
            >
              {intl.formatMessage({id: 'Freight.myAdvert.update.updateInfo'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </>
  )
}
export default EditPreferences
