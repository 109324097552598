/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import Map from '../../../Components/GoogleMap/Map'
import {loadMapApi} from '../../../Components/GoogleMap/utils/GoogleMapsUtils'

import {TransporterService} from '../../../../services/FreightOperatorServices/TransporterService'

import getDateTime from '../../../../utils/common'
import {FilterComponent} from '../../Carrier/TrackOnMap/FilterComponent'

const TrackOnMap = () => {
  const intl = useIntl()
  const transporterServ = new TransporterService()
  const [loading, setLoading] = useState<boolean>(false)
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)
  const [transporterList, setTransporterList] = useState<any>([])

  const renderDistanceSentence = () => (
    <div className='distance-info'>
      {intl.formatMessage({id: 'Admin.trackOnMap.renderDistance'}, {value: distanceInKm})}
    </div>
  )

  const getTransporters = (data: any) => {
    transporterServ
      .getCurrentLocations(data)
      .then((resp: any) => {
        setLoading(false)
        setTransporterList(resp?.data)
        getMap()
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getMap = () => {
    scriptLoaded && window.location.reload()
    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', () => setScriptLoaded(true))
  }

  useEffect(() => {
    setLoading(true)
    getTransporters({})
    getMap()
  }, [])

  const handleFilterData = (data: any) => {
    getTransporters(data)
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.freightOperator.whereTools'})}
      </PageTitle>
      {/**Map**/}
      <FilterComponent handleFilterData={handleFilterData} hideCheckBoxFilter />
      <div>
        <Map
          isUnClickable
          fullscreenControl
          mapType={google.maps.MapTypeId.ROADMAP}
          mapTypeControl={true}
          setDistanceInKm={setDistanceInKm}
          location={(item: any) => {}}
          customMarkerList={
            transporterList &&
            transporterList?.map(
              ({
                latitude,
                longitude,
                lastSubmissionDate,
                driver,
                vehicle,
                transporterStatus,
                container,
                trailer,
                transporterCompany,
              }: any) => {
                return {
                  ...(latitude &&
                    longitude && {position: new google.maps.LatLng(latitude, longitude)}),
                  type: 'truck',
                  data: {
                    title: transporterStatus?.description,
                    status: transporterStatus?.code,
                    date: lastSubmissionDate && getDateTime(lastSubmissionDate, intl.locale),
                    driver: {
                      name: driver?.name,
                      companyName: transporterCompany?.name,
                      surname: driver?.surname,
                      mobile: driver?.countryCode + driver?.mobile,
                    },
                    vehicle: {
                      plate: vehicle?.plate,
                      make: vehicle?.make,
                      vehicleTypeId: vehicle?.vehicleType?.vehicleTypeId,
                    },
                    trailer: {
                      plate: trailer?.plate,
                      type: trailer?.trailerType?.description,
                      specifications: trailer?.specificationList,
                    },
                  },
                }
              }
            )
          }
        />
        {distanceInKm > -1 && renderDistanceSentence()}
      </div>
    </>
  )
}

export default TrackOnMap
