import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import _ from 'lodash'

import {KTSVG} from '../../../../_metronic/helpers'

import Dropbox from '../../../Components/Dropbox'
import NumberSlider from '../../../Components/NumberSlider/NumberSlider'

import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {TrailersService} from '../../../../services/CarrierServices/TrailersService'

const FilterComponent = ({
  handleFilterData,
  coordinate,
  setShowFilterPanel,
  showFilterPanel,
  handleActualFilterData,
}: any) => {
  const intl = useIntl()

  const commonServ = new CommonService()
  const trailerServ = new TrailersService()

  const [numberSliderValue, setNumberSliderValue] = useState<number>(1000)
  const [actualNumberSliderValue, setActualNumberSliderValue] = useState<number>(1000)

  const [trailerTypes, setTrailerTypes] = useState<any[]>([])
  const [floorTypes, setFloorTypes] = useState<any[]>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')
  const [selectedTrailerTypeName, setSelectedTrailerTypeName] = useState<any>('')
  const [selectedFloorTypeName, setSelectedFloorTypeName] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [trailerTypeSearch, setTrailerTypeSearch] = useState<string>('')
  const [trailerTypePageIndex, setTrailerTypePageIndex] = useState<number>(0)
  const [selectedTrailerTypeId, setSelectedTrailerTypeId] = useState<any>('')

  const [floorTypeSearch, setFloorTypeSearch] = useState<string>('')
  const [floorTypePageIndex, setFloorTypePageIndex] = useState<number>(0)
  const [selectedFloorTypeId, setSelectedFloorTypeId] = useState<any>('')

  const [name, setName] = useState<any>('')
  const [actualName, setActualName] = useState<any>('')
  const [surName, setSurName] = useState<any>('')
  const [actualSurName, setActualSurName] = useState<any>('')

  const [floorTypeScrollAPICalled, setFloorTypeScrollAPICalled] = useState<boolean>(false)
  const [trailerTypeScrollAPICalled, setTrailerTypeScrollAPICalled] = useState<boolean>(false)
  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)

  const [isFirst, setIsFirst] = useState<boolean>(false)

  useEffect(() => {
    getFromCountryList()
    getToCountryList()
    getTrailerTypes()
    getFloorTypes()
  }, [])

  const getTrailerTypes = (pageIndex: number = 0, searchText?: string) => {
    trailerServ
      .getSearchTrailerTypes(pageIndex, searchText)
      .then((resp: any) => {
        setTrailerTypeScrollAPICalled(false)
        setTrailerTypes(resp?.data)
      })
      .catch((e) => {
        setTrailerTypeScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFloorTypes = (pageIndex: number = 0, searchText?: string) => {
    trailerServ
      .getSearchFloorTypes(pageIndex, searchText)
      .then((resp: any) => {
        setFloorTypeScrollAPICalled(false)
        setFloorTypes(resp?.data)
      })
      .catch((e) => {
        setFloorTypeScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0) setFromCountryList(resp?.data?.filter((item: any) => item?.name))
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCityList(resp?.data?.filter((item: any) => item?.name))
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0) setToCountryList(resp?.data?.filter((item: any) => item?.name))
        else setToCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCityList(resp?.data?.filter((item: any) => item?.name))
        else setToCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const filterData = () => {
    handleFilterData({
      name: actualName,
      surName: actualSurName,
      fromCountryCode: fromCountryId,
      fromCityId: fromCityId,
      toCountryCode: toCountryId,
      toCityId: toCityId,
      distance: actualNumberSliderValue,
      trailerTypeId: selectedTrailerTypeId,
      floorTypeId: selectedFloorTypeId,
      latitude: coordinate?.latitude,
      longitude: coordinate?.longitude,
    })
  }

  const actualFilterData = (isFirstCalled?: any) => {
    handleActualFilterData({
      name: actualName,
      surName: actualSurName,
      fromCountryCode: fromCountryId,
      fromCityId: fromCityId,
      toCountryCode: toCountryId,
      toCityId: toCityId,
      distance: actualNumberSliderValue,
      trailerTypeId: selectedTrailerTypeId,
      floorTypeId: selectedFloorTypeId,
      latitude: coordinate?.latitude,
      longitude: coordinate?.longitude,
      firstCalled: isFirstCalled,
    })
  }

  useEffect(() => {
    filterData()
  }, [coordinate])

  useEffect(() => {
    if (
      actualName ||
      actualSurName ||
      fromCountryId ||
      fromCityId ||
      toCountryId ||
      toCityId ||
      actualNumberSliderValue ||
      selectedTrailerTypeId ||
      selectedFloorTypeId ||
      isFirst
    ) {
      actualFilterData(isFirst)
      setIsFirst(true)
    }
  }, [
    actualName,
    actualSurName,
    fromCountryId,
    fromCityId,
    toCountryId,
    toCityId,
    actualNumberSliderValue,
    selectedTrailerTypeId,
    selectedFloorTypeId,
  ])

  const handleDistanceSlider = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualNumberSliderValue(value)
      }, 1000),
    []
  )

  const handleNameFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualName(value)
      }, 1000),
    []
  )

  const handleSurNameFilterSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualSurName(value)
      }, 1000),
    []
  )

  return (
    <>
      <span className={`menu-icon d-flex justify-content-end`}>
        <div
          className={`btn btn-icon btn-active-light-primary btn-custom ${
            showFilterPanel ? 'menu-button-active' : ''
          }`}
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={() => setShowFilterPanel(!showFilterPanel)}
        >
          <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
        </div>
      </span>
      {showFilterPanel && (
        <>
          <div className={'row'}>
            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.name'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.name',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setName(event.target.value)
                    handleNameFilterSearch(event.target.value)
                  }}
                  value={name}
                  type='text'
                />
              </div>
            </div>
            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.container.transporter.filter.surName'})}
              </h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({
                    id: 'Carrier.container.transporter.filter.surName',
                  })}
                  className={'form-control form-control-lg'}
                  onChange={(event: any) => {
                    setSurName(event.target.value)
                    handleSurNameFilterSearch(event.target.value)
                  }}
                  value={surName}
                  type='text'
                />
              </div>
            </div>
            <div className={'col-md-6 mb-6'}>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.trailer.create.trailerType'})}
              </h6>
              <Dropbox
                hideArrow
                id={'trailer'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.trailerType.placeHolder',
                })}
                customSearchValue={trailerTypeSearch}
                customOnSearch={(e: any) => {
                  setTrailerTypePageIndex(0)
                  setTrailerTypeSearch(e.target.value)
                  getTrailerTypes(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setTrailerTypePageIndex(val)
                  getTrailerTypes(val, trailerTypeSearch)
                }}
                setScrollCalled={() => {
                  setTrailerTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={trailerTypeScrollAPICalled}
                currentPageIndex={trailerTypePageIndex}
                defaultSelected={{id: selectedTrailerTypeId, title: selectedTrailerTypeName}}
                menuList={
                  trailerTypes &&
                  trailerTypes.map((item: any) => {
                    return {
                      id: item?.trailerTypeId,
                      title: item?.description,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setSelectedTrailerTypeId(parseInt(dataItem?.id))
                    setSelectedTrailerTypeName(dataItem?.title)
                  }

                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setSelectedTrailerTypeId('')
                    setSelectedTrailerTypeName('')
                  }
                }}
              />
            </div>
            <div className={'col-md-6 mb-6'}>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'Carrier.trailer.create.floorType'})}
              </h6>
              <Dropbox
                hideArrow
                id={'floorType'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.trailer.create.floorType.placeHolder',
                })}
                customSearchValue={floorTypeSearch}
                customOnSearch={(e: any) => {
                  setFloorTypePageIndex(0)
                  setFloorTypeSearch(e.target.value)
                  getFloorTypes(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setFloorTypePageIndex(val)
                  getFloorTypes(val, floorTypeSearch)
                }}
                setScrollCalled={() => {
                  setFloorTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={floorTypeScrollAPICalled}
                currentPageIndex={floorTypePageIndex}
                defaultSelected={{id: selectedFloorTypeId, title: selectedFloorTypeName}}
                menuList={
                  floorTypes &&
                  floorTypes.map((item: any) => {
                    return {
                      id: item?.floorTypeId,
                      title: item?.description,
                    }
                  })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                selectedItemData={(dataItem: any) => {
                  if (dataItem?.id) {
                    setSelectedFloorTypeId(parseInt(dataItem?.id))
                    setSelectedFloorTypeName(dataItem?.title)
                  }

                  if (dataItem?.id === '' && dataItem?.title === '') {
                    setSelectedFloorTypeId('')
                    setSelectedFloorTypeName('')
                  }
                }}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 mb-5'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'freight.truckExchange.distance'})}
              </h6>
              <div className={'position-relative pt-6'}>
                <span className={'position-absolute top-0 start-0'}>5</span>
                <span className={'position-absolute top-0 start-50 fw-bold'}>
                  {numberSliderValue}
                </span>
                <span className={'position-absolute top-0 end-0'}>1000</span>
                <NumberSlider
                  min={5}
                  max={1000}
                  defaultValue={1000}
                  value={(val: number) => {
                    setNumberSliderValue(val)
                    handleDistanceSlider(val)
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 mb-5'>
                <h4 className={'text-muted mb-0'}>
                  {intl.formatMessage({id: 'Admin.Containers.preferredRoute'})}
                </h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
              </h6>
              <div className={''}>
                <Dropbox
                  key={selectedFromFilterCountry}
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.country.placeholder',
                  })}
                  customSearchValue={fromCountrySearch}
                  customOnSearch={(e: any) => {
                    setFromCountryPageIndex(0)
                    setFromCountrySearch(e.target.value)
                    getFromCountryList(0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    setFromCountryPageIndex(val)
                    getFromCountryList(val, fromCountrySearch)
                  }}
                  setScrollCalled={() => {
                    setFromCountryScrollAPICalled(true)
                  }}
                  scrollAPIResponse={fromCountryScrollAPICalled}
                  currentPageIndex={fromCountryPageIndex}
                  menuList={
                    fromCountryList &&
                    fromCountryList
                      ?.filter(function ({code}: any) {
                        // @ts-ignore
                        return !this[code] && (this[code] = code)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.code, title: item?.name}
                      })
                  }
                  defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setFromCountryId(item?.id)
                      setSelectedFromFilterCountry(item?.title)
                      setDependentFromCity(true)
                      setFromCityId('')
                    }

                    if (item?.id === '' && item?.title === '') {
                      setFromCountryId('')
                      setSelectedFromFilterCountry('')
                      setDependentFromCity(true)
                      setFromCityId('')
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  disabled={!fromCountryId}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={fromCitySearch}
                  customOnSearch={(e: any) => {
                    setFromCityPageIndex(0)
                    setFromCitySearch(e.target.value)
                    getFromSearchCityList(fromCountryId, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (fromCountryId) {
                      setFromCityPageIndex(val)
                      getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setFromCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={fromCityScrollAPICalled}
                  currentPageIndex={fromCityPageIndex}
                  menuList={
                    fromCityList &&
                    fromCityList
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  dependentValue={dependentFromCity}
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  defaultSelected={{id: fromCityId}}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setFromCityId(item?.id)
                      setDependentFromCity(false)
                    }

                    if (item?.id === '' && item?.title === '') {
                      setFromCityId('')
                      setDependentFromCity(false)
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
              </h6>
              <div className={''}>
                <Dropbox
                  key={selectedToFilterCountry}
                  hideArrow
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.country.placeholder',
                  })}
                  customSearchValue={toCountrySearch}
                  customOnSearch={(e: any) => {
                    setToCountryPageIndex(0)
                    setToCountrySearch(e.target.value)
                    getToCountryList(0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    setToCountryPageIndex(val)
                    getToCountryList(val, toCountrySearch)
                  }}
                  setScrollCalled={() => {
                    setToCountryScrollAPICalled(true)
                  }}
                  scrollAPIResponse={toCountryScrollAPICalled}
                  defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                  currentPageIndex={toCountryPageIndex}
                  menuList={
                    toCountryList &&
                    toCountryList
                      ?.filter(function ({code}: any) {
                        // @ts-ignore
                        return !this[code] && (this[code] = code)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.code, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setToCountryId(item?.id)
                      setSelectedToFilterCountry(item?.title)
                      setDependentToCity(true)
                      setToCityId('')
                    }

                    if (item?.id === '' && item?.title === '') {
                      setToCountryId('')
                      setSelectedToFilterCountry('')
                      setDependentToCity(true)
                      setToCityId('')
                    }
                  }}
                />
              </div>
            </div>

            <div className='mb-6 col-md-6'>
              <h6 className={'mb-3'}>
                {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
              </h6>
              <div className={''}>
                <Dropbox
                  hideArrow
                  disabled={!toCountryId}
                  customClass={'form-select'}
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.city.placeholder',
                  })}
                  customSearchValue={toCitySearch}
                  customOnSearch={(e: any) => {
                    setToCityPageIndex(0)
                    setToCitySearch(e.target.value)
                    getToSearchCityList(toCountryId, 0, e.target.value)
                  }}
                  onScroll={(val: any) => {
                    if (toCountryId) {
                      setToCityPageIndex(val)
                      getToSearchCityList(toCountryId, val, fromCountrySearch)
                    }
                  }}
                  setScrollCalled={() => {
                    setToCityScrollAPICalled(true)
                  }}
                  scrollAPIResponse={toCityScrollAPICalled}
                  currentPageIndex={toCityPageIndex}
                  dependentValue={dependentToCity}
                  menuList={
                    toCityList &&
                    toCityList
                      ?.filter(function ({cityId}: any) {
                        // @ts-ignore
                        return !this[cityId] && (this[cityId] = cityId)
                      }, {})
                      ?.map((item: any) => {
                        return {id: item?.cityId, title: item?.name}
                      })
                  }
                  defaultClearItem={{
                    id: '',
                    title: '',
                  }}
                  defaultSelected={{id: toCityId}}
                  selectedItemData={(item: any) => {
                    if (item?.id) {
                      setToCityId(item?.id)
                      setDependentToCity(false)
                    }

                    if (item?.id === '' && item?.title === '') {
                      setToCityId('')
                      setDependentToCity(false)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {FilterComponent}
