import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {KTSVG} from '../../../../_metronic/helpers'
import {scrollUnlock} from '../../../../utils/useScrollLock'
import {PageTitle} from '../../../../_metronic/layout/core'

import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import CreateNewTransporterPopup from '../../../Components/CreateNewTransporterPopup'
import Modal from '../../../Components/Modal'
import Loading from '../../../Components/Loading'
import ShowTransporter from './Popups/ShowTransporter'
import ShowDocument from './Popups/ShowDocument'

import {TransporterService} from '../../../../services/CarrierServices/TransporterService'

import './Transporters.scss'

const Transporters = () => {
  const intl = useIntl()
  /**
   ** UTILS
   **/
  const transportServ = new TransporterService()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [editTransporter, setEditTransporter] = useState<boolean>(false)

  /*List States*/
  const [transporterList, setTransporterList] = useState<any[]>([])
  const [driverList, setDriverList] = useState<any[]>([])
  const [vehicleList, setVehicleList] = useState<any[]>([])
  const [trailerList, setTrailerList] = useState<any[]>([])
  const [transporterTypeList, setTransporterTypeList] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedTransporter, setSelectedTransporter] = useState<any>(null)
  const [selectedDriverType, setSelectedDriverType] = useState<any>(null)
  const [selectedVehicleType, setSelectedVehicleType] = useState<any>(null)
  const [selectedTrailerType, setSelectedTrailerType] = useState<any>(null)
  const [selectedTransporterType, setSelectedTransporterType] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [transporterDetail, setTransporterDetail] = useState<any>(null)
  const [showApproveSubContractorPopup, setShowApproveSubContractorPopup] = useState<boolean>(false)
  const [showRejectSubContractorPopup, setShowRejectSubContractorPopup] = useState<boolean>(false)
  const [showDocumentPopup, setShowDocumentPopup] = useState<boolean>(false)
  const [transporterFileList, setTransporterFileList] = useState<any>([])

  const [rejectText, setRejectText] = useState<string>('')

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [companyCustomSearch, setCompanyCustomSearch] = useState<string>('')

  const [currentVehiclePageIndex, setCurrentVehiclePageIndex] = useState<number>(0)
  const [vehicleCustomSearch, setVehicleCustomSearch] = useState<string>('')

  const [currentTrailerPageIndex, setCurrentTrailerPageIndex] = useState<number>(0)
  const [trailerCustomSearch, setTrailerCustomSearch] = useState<string>('')

  const [currentTransporterPageIndex, setCurrentTransporterPageIndex] = useState<number>(0)
  const [transporterCustomSearch, setTransporterCustomSearch] = useState<string>('')

  const [transporterTypeScrollAPICalled, setTransporterTypeScrollAPICalled] =
    useState<boolean>(false)
  const [driverTypeScrollAPICalled, setDriverTypeScrollAPICalled] = useState<boolean>(false)
  const [vehicleTypeScrollAPICalled, setVehicleTypeScrollAPICalled] = useState<boolean>(false)
  const [trailerTypeScrollAPICalled, setTrailerTypeScrollAPICalled] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const getDriversList = (pageIndex: number = 0, searchText?: string) => {
    transportServ
      .getDriverListSearch(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setDriverTypeScrollAPICalled(false)
        if (pageIndex === 0) setDriverList(resp?.data?.filter((item: any) => item?.name))
        else setDriverList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setDriverTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransporterTypeList = (pageIndex: number = 0, searchText?: string) => {
    transportServ
      .getTransporterTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransporterTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setTransporterTypeList(resp?.data?.filter((item: any) => item?.transportTypeId))
        else setTransporterTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTransporterTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCompanyVehiclesList = (pageIndex: number = 0, searchText?: string) => {
    transportServ
      .getCompanyVehicleList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setVehicleTypeScrollAPICalled(false)
        if (pageIndex === 0) setVehicleList(resp?.data?.filter((item: any) => item?.vehicleId))
        else setVehicleList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setVehicleTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTrailersList = (pageIndex: number = 0, searchText?: string) => {
    transportServ
      .getTrailerList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTrailerTypeScrollAPICalled(false)
        if (pageIndex === 0) setTrailerList(resp?.data?.filter((item: any) => item?.trailerId))
        else setTrailerList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTrailerTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransporterById = (id?: number) => {
    transportServ
      .getTransporterById(id)
      .then((resp: any) => {
        setTransporterDetail(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  /**
   *! API CALL START
   **/
  const getTransporterList = (pageIndex?: number) => {
    transportServ
      .getTransporterList(pageIndex)
      .then((resp: any) => {
        setTransporterList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setIsNextPage(false)
      })
  }

  const createTransporter = (
    vehicleId?: number,
    trailerId?: number,
    driverId?: number,
    transporterId?: number
  ) => {
    transportServ
      .createTransporter(vehicleId, trailerId, driverId, transporterId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.transporterCreateSuccess'}))
        setShowCreatePopup(false)
        getTransporterList(pageMIndex)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
    scrollUnlock()
  }

  const updateTransporter = (
    transporterId?: number,
    vehicleId?: number,
    trailerId?: number,
    driverId?: number,
    transporterTypeId?: number
  ) => {
    transportServ
      .updateTransporter(transporterId, vehicleId, trailerId, driverId, transporterTypeId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.transporterUpdateSuccess'}))
        setShowCreatePopup(false)
        getTransporterList(pageMIndex)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
    scrollUnlock()
  }

  const deleteTransporter = (transporterId?: number) => {
    transportServ
      .deleteTransporter(transporterId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.transporterDeletedSuccess'}))
        setShowDeletePopup(false)
        getTransporterList(pageMIndex)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setShowDeletePopup(false)
        setLoading(false)
      })
    scrollUnlock()
  }

  const getTransporterFile = (transporterId?: number) => {
    transportServ
      .getTransporterFileList(transporterId)
      .then((resp: any) => {
        let fileList: any = []
        if (resp?.data?.driverFileList && resp?.data?.driverFileList.length > 0) {
          fileList.push(...resp?.data?.driverFileList)
        }

        if (resp?.data?.trailerFileList && resp?.data?.trailerFileList.length > 0) {
          fileList.push(...resp?.data?.trailerFileList)
        }

        if (resp?.data?.vehicleFileList && resp?.data?.vehicleFileList.length > 0) {
          fileList.push(...resp?.data?.vehicleFileList)
        }

        setTransporterFileList(fileList)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const approveSubContractor = (transporterId?: number) => {
    transportServ
      .approveSubContractor(transporterId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.transporter.subContractortApproveSuccess'}))
        getTransporterList(pageMIndex)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const rejectSubContractor = (transporterId?: number, rejectText?: string) => {
    transportServ
      .rejectSubContractor(transporterId, rejectText)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.transporter.subContractortRejectSuccess'}))
        getTransporterList(pageMIndex)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  /**
   *! API CALL END
   **/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getTransporterList(page)
      setPageMIndex(page)
    }

    setIsNextPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getTransporterList(pageMIndex)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Carrier.transporters.title'})}
      </PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => {
            setLoading(true)
            getDriversList()
            getCompanyVehiclesList()
            getTrailersList()
            getTransporterTypeList()
            setShowCreatePopup(true)
            if (editTransporter) {
              setEditTransporter(false)
            }
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'Carrier.transporters.create.create'})}
        </button>
      </div>
      <div>
        {!transporterList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.transporters.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.driverName'}),
              accessor: 'driverName',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.driverSurName'}),
              accessor: 'driverSurName',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-1'}),
              accessor: 'companyName',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.vehiclePlate'}),
              accessor: 'vehiclePlate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.trailerPlate'}),
              accessor: 'trailerPlate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.create.transportType'}),
              accessor: 'transporterType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.create.transporterStatus'}),
              accessor: 'transporterStatus',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.create.subContractorStatus'}),
              accessor: 'subContractorStatus',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporters.trailerPlate'}),
              accessor: 'data',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
          ]}
          data={
            transporterList &&
            transporterList?.map((item: any) => {
              return {
                id: item?.transporterId,
                driverName: item?.driver?.name,
                driverSurName: item?.driver?.surname,
                vehiclePlate: item?.vehicle?.plate,
                transporterType: item?.transportType?.description,
                companyName: item?.company?.name,
                transporterStatus: item?.transporterStatus?.description,
                subContractorStatus: item?.subContractStatus?.description,
                trailerPlate: item?.trailer?.plate,
                isFreightPostings: true,
                hiddenButtons: {
                  showFile: item?.subContractStatus?.code !== 'WAITING_FOR_REVIEW',
                  advertApprove: item?.subContractStatus?.code !== 'WAITING_FOR_REVIEW',
                  advertReject: item?.subContractStatus?.code !== 'WAITING_FOR_REVIEW',
                },
                data: item,
              }
            })
          }
          showDetailBtn={(row: any) => {
            setShowDetailPopup(true)
            setLoading(true)
            getTransporterById(row.values.id)
            setSelectedTransporter(row.values)
          }}
          showFile={(row: any) => {
            setLoading(true)
            setSelectedTransporter(row.values)
            setShowDocumentPopup(true)
            getTransporterFile(Number(row?.values?.id))
          }}
          advertApprove={(row: any) => {
            setSelectedTransporter(row.values)
            setShowApproveSubContractorPopup(true)
          }}
          advertReject={(row: any) => {
            setSelectedTransporter(row.values)
            setShowRejectSubContractorPopup(true)
          }}
          editBtn={(row: any) => {
            setLoading(true)
            getDriversList()
            getCompanyVehiclesList()
            getTrailersList()
            getTransporterTypeList()
            setShowCreatePopup(true)
            getTransporterById(row?.values?.id)
            setSelectedTransporter(row.values)
            setEditTransporter(true)
          }}
          deleteRow={(row: any) => {
            setSelectedTransporter(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Carrier.tranporter.tabel.deleteText'})}
          editableRows={['']}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />

        {showApproveSubContractorPopup && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowApproveSubContractorPopup(false),
            }}
            customAll={
              <div className={''}>
                <h3 className={'mt-5 mb-15'}>
                  {intl.formatMessage({id: 'Carrier.transporter.subContractortIsApprove'})}
                </h3>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-danger w-100'}
                      onClick={() => {
                        setShowApproveSubContractorPopup(false)
                      }}
                    >
                      {intl.formatMessage({id: 'Admin.Adverts.cancel'})}
                    </button>
                  </div>
                  <div
                    className={'col-md-6'}
                    onClick={() => {
                      setLoading(true)
                      approveSubContractor(Number(selectedTransporter?.id))
                      setShowApproveSubContractorPopup(false)
                    }}
                  >
                    <button className={'btn btn-success w-100'}>
                      {intl.formatMessage({id: 'Admin.Adverts.approve'})}
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}

        {showRejectSubContractorPopup && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowRejectSubContractorPopup(false),
            }}
            customAll={
              <div className={''}>
                <h3 className={'mt-5 mb-5'}>
                  {intl.formatMessage({id: 'Carrier.transporter.subContractortIsReject'})}
                </h3>
                <div className={'mb-10'}>
                  <label htmlFor='rejText' className={'text-muted'}>
                    {intl.formatMessage({id: 'Admin.Adverts.rejectReason'})}
                  </label>
                  <textarea
                    onChange={(e: any) => setRejectText(e.target.value)}
                    value={rejectText}
                    id={'rejText'}
                    className={'form-control'}
                    placeholder={intl.formatMessage({id: 'Admin.Adverts.rejectReason'})}
                    rows={7}
                    style={{resize: 'none'}}
                  ></textarea>
                </div>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-danger w-100'}
                      onClick={() => {
                        setShowRejectSubContractorPopup(false)
                      }}
                    >
                      {intl.formatMessage({id: 'Admin.Adverts.cancel'})}
                    </button>
                  </div>
                  <div
                    className={'col-md-6'}
                    onClick={() => {
                      setLoading(true)
                      rejectSubContractor(Number(selectedTransporter?.id), rejectText)
                      setShowRejectSubContractorPopup(false)
                    }}
                  >
                    <button disabled={!rejectText} className={'btn btn-success w-100'}>
                      {intl.formatMessage({id: 'Admin.Adverts.approve'})}
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}

        {showDocumentPopup && (
          <ShowDocument
            transporterFileList={transporterFileList}
            onClose={() => setShowDocumentPopup(false)}
          />
        )}

        {/*CREATE POPUP*/}
        {(showCreatePopup || editTransporter) && (
          <CreateNewTransporterPopup
            companyCustomSearch={companyCustomSearch}
            getDriversList={getDriversList}
            setCompanyCustomSearch={setCompanyCustomSearch}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            driverList={driverList}
            getTransporterTypeList={getTransporterTypeList}
            transporterTypeList={transporterTypeList}
            setTransporterCustomSearch={setTransporterCustomSearch}
            transporterCustomSearch={transporterCustomSearch}
            setCurrentTransporterPageIndex={setCurrentTransporterPageIndex}
            currentTransporterPageIndex={currentTransporterPageIndex}
            selectedTransporterType={selectedTransporterType}
            setSelectedTransporterType={setSelectedTransporterType}
            setSelectedDriverType={setSelectedDriverType}
            vehicleCustomSearch={vehicleCustomSearch}
            setSelectedVehicleType={setSelectedVehicleType}
            vehicleList={vehicleList}
            getCompanyVehiclesList={getCompanyVehiclesList}
            setVehicleCustomSearch={setVehicleCustomSearch}
            setCurrentVehiclePageIndex={setCurrentVehiclePageIndex}
            currentVehiclePageIndex={currentVehiclePageIndex}
            setSelectedTrailerType={setSelectedTrailerType}
            trailerList={trailerList}
            currentTrailerPageIndex={currentTrailerPageIndex}
            getTrailersList={getTrailersList}
            setTrailerCustomSearch={setTrailerCustomSearch}
            setCurrentTrailerPageIndex={setCurrentTrailerPageIndex}
            trailerCustomSearch={trailerCustomSearch}
            selectedTrailerType={selectedTrailerType}
            selectedVehicleType={selectedVehicleType}
            selectedDriverType={selectedDriverType}
            setTransporterTypeScrollAPICalled={setTransporterTypeScrollAPICalled}
            setDriverTypeScrollAPICalled={setDriverTypeScrollAPICalled}
            setVehicleTypeScrollAPICalled={setVehicleTypeScrollAPICalled}
            setTrailerTypeScrollAPICalled={setTrailerTypeScrollAPICalled}
            transporterTypeScrollAPICalled={transporterTypeScrollAPICalled}
            driverTypeScrollAPICalled={driverTypeScrollAPICalled}
            vehicleTypeScrollAPICalled={vehicleTypeScrollAPICalled}
            trailerTypeScrollAPICalled={trailerTypeScrollAPICalled}
            onSubmit={({
              selectedVehicleType,
              selectedTrailerType,
              selectedDriverType,
              selectedTransporterType,
            }: any) => {
              setLoading(true)
              if (editTransporter) {
                updateTransporter(
                  selectedTransporter?.id,
                  selectedVehicleType,
                  selectedTrailerType,
                  selectedDriverType,
                  selectedTransporterType
                )
              } else {
                createTransporter(
                  selectedVehicleType,
                  selectedTrailerType,
                  selectedDriverType,
                  selectedTransporterType
                )
              }
              setShowCreatePopup(false)
              if (editTransporter) {
                setEditTransporter(false)
              }
              setSelectedTransporter(null)
            }}
            onClose={() => {
              if (editTransporter) {
                setEditTransporter(false)
              }
              setShowCreatePopup(false)
              setSelectedTransporter(null)
            }}
            editTransporter={editTransporter}
            selectedTransporter={transporterDetail}
          />
        )}

        {showDetailPopup && (
          <ShowTransporter
            selectedTransporter={transporterDetail}
            onClose={() => setShowDetailPopup(false)}
          />
        )}
        {/*DELETE POPUP*/}
        {showDeletePopup && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowDeletePopup(false),
            }}
            customAll={
              <div className={''}>
                <h3 className={'mt-5 mb-15'}>
                  {intl.formatMessage({id: 'Carrier.transporters.delete.title'})}
                </h3>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-danger w-100'}
                      onClick={() => {
                        setShowDeletePopup(false)
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.transporters.delete.cancel'})}
                    </button>
                  </div>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-success w-100'}
                      onClick={() => {
                        setLoading(true)
                        deleteTransporter(selectedTransporter?.id)
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.transporters.delete.approve'})}
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export {Transporters}
