import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'
import PhoneInp from '../../../Components/PhoneInp/PhoneInp'

import {UserManagementService} from '../../../../services/FreightOperatorServices/UserManagementService'

import useScrollLock from '../../../../utils/useScrollLock'

import './CreateNewUser.scss'

const CreateNewUser = () => {
  const intl = useIntl()
  const history = useHistory()
  const userServ = new UserManagementService()
  const [loading, setLoading] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    name: '',
    surname: '',
    username: '',
    email: '',
    mobile: '',
    countryCode: '+90',
  }
  const loginSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    surname: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    username: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    email: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    countryCode: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
    mobile: Yup.string().required(intl.formatMessage({id: 'global.thisFieldRequired'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      createUser({
        name: formik.values.name,
        surname: formik.values.surname,
        username: formik.values.username,
        email: formik.values.email,
        countryCode: formik.values.countryCode,
        mobile: formik.values.mobile,
      })
    },
  })
  /** Formik Preferences End**/

  const createUser = (data: any) => {
    userServ
      .createUser(
        data.name,
        data.surname,
        data.username,
        data.email,
        data.countryCode,
        data.mobile,
        data.initialPassword
      )
      .then(() => {
        setShowSuccess(true)
      })
      .catch((error: any) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  /** Api Calls End**/

  const {scrollUnlock} = useScrollLock()

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {/*{intl.formatMessage({id: "YENİ KULLANICI OLUŞTUR"})}*/}
        {intl.formatMessage({id: 'Freight.users.create.title'})}
      </PageTitle>
      <div>
        {/* end::Form group */}
        <div className={'row mb-10'}>
          {/* begin::Form group Street */}
          <div className='col-md-4 mb-10'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.name'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.users.create.enterName'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Town */}
          <div className='col-md-4 mb-10'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.surName'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.users.create.enterSurName'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('surname')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.surname && formik.errors.surname,
                },
                {
                  'is-valid': formik.touched.surname && !formik.errors.surname,
                }
              )}
            />
            {formik.touched.surname && formik.errors.surname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.surname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group PostCode */}
          <div className='col-md-4 mb-10'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.userName'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.users.create.enterUserName'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                },
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        <div className={'row mb-10'}>
          {/* begin::Form group Street */}
          <div className='col-md-4 mb-10'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.email'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.users.create.enterEmail'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          <div className={'col-md-4 mb-10'}>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.countryCode'})}
            </label>
            <PhoneInp
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white w-100',
                {
                  'is-invalid': formik.touched.countryCode && formik.errors.countryCode,
                },
                {
                  'is-valid': formik.touched.countryCode && !formik.errors.countryCode,
                }
              )}
              onSelect={(val: string | number) => {
                val && formik.setFieldValue('countryCode', '+' + val)
              }}
            />
            {formik.touched.countryCode && formik.errors.countryCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.countryCode}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-md-4 mb-10'>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.users.phone'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Freight.users.create.enterPhone'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('mobile')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.mobile && formik.errors.mobile,
                },
                {
                  'is-valid': formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobile}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group InitialPassword */}
          {/* <div className='col-md-4 mb-10'>
                        <label className='class="form-label fw-bolder text-dark fs-6'>
                            Varsayılan Şifre
                        </label>
                        <input
                            placeholder='Varsayılan Şifre Giriniz'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('initialPassword')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid bg-white',
                                {
                                    'is-invalid': formik.touched.initialPassword && formik.errors.initialPassword,
                                },
                                {
                                    'is-valid': formik.touched.initialPassword && !formik.errors.initialPassword,
                                },
                            )}
                        />
                        {formik.touched.initialPassword && formik.errors.initialPassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.initialPassword}</span>
                                </div>
                            </div>
                        )}
                    </div>*/}
          {/* end::Form group */}

          {/* end::Form group */}

          <div className={'mt-10'}>
            <button
              onClick={() => formik.handleSubmit()}
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-10'
            >
              {intl.formatMessage({id: 'Freight.users.create.addUser'})}
            </button>
          </div>
        </div>
      </div>
      {showSuccess && (
        <Modal
          closeBtnSettings={{
            show: false,
            onClick: () => setShowSuccess(false),
          }}
          mainTitle={intl.formatMessage({id: 'CreateNewUser.success.title'})}
          /* width={'70%'}
                     height={'70%'}*/
          customAll={
            <div className={'preview-success-container-user-page '}>
              <div className={'success-image-container text-center'}>
                <img
                  className={'success-image img-fluid m-auto mb-10'}
                  src='/media/user-success-black.png'
                  alt='success'
                />
                <h1 className={'m-auto'}>
                  {intl.formatMessage({id: 'CreateNewUser.success.user'})}
                </h1>
              </div>
              <div className='text-center mt-10 row m-auto'>
                <div className={'col-md-6'}>
                  <button
                    onClick={() => {
                      scrollUnlock()
                      window.location.reload()
                    }}
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-outline-info w-100'
                  >
                    {intl.formatMessage({id: 'CreateNewUser.createNewUser'})}
                  </button>
                </div>
                <div className={'col-md-6 '}>
                  <button
                    onClick={() => {
                      scrollUnlock()
                      history.push('/fr-op/users')
                    }}
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 '
                  >
                    {intl.formatMessage({id: 'CreateNewUser.goToUser'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export {CreateNewUser}
