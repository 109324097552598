import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import ViewDetailsFreightPopup from '../../../../Components/ViewDetailsFreightPost'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'

import {DashboardService} from '../../../../../services/CarrierServices/DashboardService'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime from '../../../../../utils/common'

import '../DashboardWrapper.scss'

const ShowFreightListPopup = ({containerFreightList, onClose, setLoading}: any) => {
  const intl = useIntl()
  const [showFreightDetailPopup, setShowFreightDetailPopup] = useState<boolean>(false)
  const [freightPostDetail, setfreightPostDetail] = useState<any>({})
  const {scrollLock, scrollUnlock} = useScrollLock()

  const dashServ = new DashboardService()

  useEffect(() => {
    scrollLock()
  }, [])

  const getFrightPostDetails = (freightId?: number) => {
    dashServ
      .getFreightDetailById(freightId)
      .then((resp: any) => {
        setLoading(false)
        setfreightPostDetail(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.freightPostings.view.title'})}
        isCloseIcon
        className='carrier-dashboard-show-wrap'
        closeOnClick={() => onClose()}
      >
        {!containerFreightList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_title'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
              accessor: 'company',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_value'}),
              accessor: 'value',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_from'}),
              accessor: 'fromLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_to'}),
              accessor: 'toLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_departureDate'}),
              accessor: 'departureDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_arriveDate'}),
              accessor: 'arriveDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_status'}),
              accessor: 'status',
            },
          ]}
          data={
            containerFreightList &&
            containerFreightList.map((item: any) => {
              return {
                id: item?.freightId,
                title: item?.description ?? '-',
                company: item?.company?.name ?? '-',
                value: (item?.value ?? '-') + ' ' + (item?.costCurrency?.currencyCode ?? ''),
                fromLocation: item?.fromLocation?.addressText ?? '-',
                toLocation: item?.toLocation?.addressText ?? '-',
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                arriveDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                status: item?.freightStatus?.description ?? '-',
              }
            })
          }
          editableRows={['']}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setShowFreightDetailPopup(true)
            getFrightPostDetails(row.values.id)
          }}
          pageMIndex={0}
        />

        <div className='mt-5 row justify-content-center'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100'
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
      {showFreightDetailPopup && Object.keys(freightPostDetail).length > 0 && (
        <ViewDetailsFreightPopup
          onClose={() => {
            setShowFreightDetailPopup(false)
          }}
          selectedFreightPost={freightPostDetail}
        />
      )}
    </>
  )
}

export default ShowFreightListPopup
