import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import EditPreferences from './Popups/EditPreferences'
import ShowDetails from './Popups/ShowDetails'
import CreateVehicle from './Popups/CreateVehicle'
import UploadFile from './Popups/UploadFile'

import {VehiclesService} from '../../../../services/CarrierServices/VehiclesService'
import useScrollLock from '../../../../utils/useScrollLock'

import './Vehicles.scss'

const Vehicles = () => {
  const intl = useIntl()

  /**
   ** UTILS
   **/
  const vehicleServ = new VehiclesService()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  /* const intl = useIntl()*/

  /*List States*/
  const [vehicleList, setVehicleList] = useState<any[]>([])
  const [vehicleTypes, setVehicleTypes] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [vehicleMetadata, setVehicleMetadata] = useState<any[]>([])

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showFilePopup &&
      !showDetailPopup &&
      !showCreatePopup &&
      !showDeletePopup &&
      !showUpdatePopup
    )
      scrollUnlock()
  }, [showFilePopup, showDetailPopup, showCreatePopup, showDeletePopup, showUpdatePopup])

  /*Popup States*/

  const [updatedVehicle, setUpdatedVehicle] = useState<any>({
    id: -1,
    year: 1,
    plate: '',
    /*capacity: 1,*/
    make: '',
    model: '',
    vehicleType: '',
  })

  /**
   *! API CALL START
   **/
  const getVehicleList = (pageIndex?: number) => {
    vehicleServ
      .getVehicles(pageIndex)
      .then((resp: any) => {
        setVehicleList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getVehicle = (id: number) => {
    return vehicleServ.getVehicle(id)
  }

  const getVehicleTypes = () => {
    vehicleServ
      .getVehicleTypes()
      .then((resp: any) => {
        setVehicleTypes(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const deleteVehicle = (id: number) => {
    vehicleServ
      .deleteVehicle(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.vehicleDeleteSuccess'}))
        getVehicleList(pageMIndex)
        setShowDeletePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const updateVehicle = (data: {
    id: number
    year: number
    plate: string
    /*capacity: number,*/ make: string
    model: string
    vehicleType: number
  }) => {
    vehicleServ
      .updateVehicle(
        data.id,
        data.year,
        data.plate,
        /*data.capacity,*/ data.make,
        data.model,
        data.vehicleType
      )
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.vehicleUpdateSuccess'}))
        getVehicleList(pageMIndex)
        setShowUpdatePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const createVehicle = (data: {
    year: number
    plate: string
    /*capacity: number,*/ make: string
    model: string
    vehicleType: number
  }) => {
    vehicleServ
      .createVehicle(
        data.year,
        data.plate,
        /*data.capacity,*/ data.make,
        data.model,
        data.vehicleType
      )
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.vehicleCreateSuccess'}))
        getVehicleList(pageMIndex)
        setShowCreatePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const uploadVehicleLicense = (id: number, file: any) => {
    vehicleServ
      .uploadVehicleLicense(id, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.licenseUploadSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const uploadVehiclePlate = (id: number, file: any) => {
    vehicleServ
      .uploadVehiclePlate(id, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.vehiclePlateUploadSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const uploadVehicleCarnet = (id: number, file: any) => {
    vehicleServ
      .uploadVehicleCarnet(id, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.vehicles.vehicleCarnetUploadSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getAllMetadata = (id: number) => {
    vehicleServ
      .getAllMetadata(id)
      .then((resp: any) => {
        setVehicleMetadata(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const downloadFiles = (url: string, type: string, name: string) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application+file/json',
      },
    })
      .then((res: any) => res.blob())
      .then((resp: any) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(new Blob([resp]))
        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', type + '.' + name.split('.').reverse()[0]) //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        setLoading(false)
      })
  }
  /**
   *! API CALL END
   **/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getVehicleList(page)
      setPageMIndex(page)
    }

    setIsNextPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getVehicleList(pageMIndex)
    setLoading(true)
    getVehicleTypes()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Carrier.vehicles.title'})}</PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => setShowCreatePopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'Carrier.vehicles.createNewVehicle'})}
        </button>
      </div>
      <div>
        {!vehicleList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.vehicles.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.vehicles.vehicleType'}),
              accessor: 'vehicleType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.vehicles.numberPate'}),
              accessor: 'plate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.vehicles.brand'}),
              accessor: 'make',
            },
          ]}
          editableRows={['']}
          data={
            vehicleList &&
            vehicleList?.map((item: any) => {
              return {
                id: item?.vehicleId,
                plate: item?.plate,
                make: item?.make,
                /* capacity: item?.capacity,*/
                vehicleType: item?.vehicleType.description,
              }
            })
          }
          editBtn={(row: any) => {
            setLoading(true)
            setSelectedVehicle(row.values)
            getVehicle(row.values.id)
              .then((resp: any) => {
                setUpdatedVehicle((item: any) => ({
                  ...item,
                  id: resp.data.vehicleId,
                  year: resp.data.year,
                  plate: resp.data.plate,
                  /* capacity: resp.data.capacity,*/
                  make: resp.data.make,
                  model: resp.data.model,
                  vehicleType: resp.data.vehicleType.vehicleTypeId,
                }))
                setLoading(false)
                setShowUpdatePopup(true)
              })
              .catch((e) => {
                toast.error(e.response.data.message + ' | ' + e.response.data.code)
              })
          }}
          showDetailBtn={(row: any) => {
            setLoading(true)
            getAllMetadata(row.values.id)
            setSelectedVehicle(row.values)
            getVehicle(row.values.id)
              .then((resp: any) => {
                setUpdatedVehicle((item: any) => ({
                  ...item,
                  id: resp.data.vehicleId,
                  year: resp.data.year,
                  plate: resp.data.plate,
                  /*capacity: resp.data.capacity,*/
                  make: resp.data.make,
                  model: resp.data.model,
                  vehicleType: resp.data.vehicleType.vehicleTypeId,
                }))
                setLoading(false)
                setShowDetailPopup(true)
              })
              .catch((e) => {
                toast.error(e.response.data.message + ' | ' + e.response.data.code)
              })
          }}
          showFile={(row: any) => {
            setLoading(true)
            getAllMetadata(row.values.id)
            setSelectedVehicle(row.values)
            setShowFilePopup(true)
          }}
          deleteRow={(row: any) => {
            setSelectedVehicle(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Carrier.Vehicle.tabel.deleteText'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.vehicles.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.vehicles.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteVehicle(selectedVehicle.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.vehicles.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*CREATE POPUP*/}
      {showCreatePopup && (
        <CreateVehicle
          vehicleTypes={vehicleTypes}
          createVehicle={(val: any) => {
            setLoading(true)
            createVehicle(val)
          }}
          onClose={() => setShowCreatePopup(false)}
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditPreferences
          updatedVehicles={updatedVehicle}
          vehicleTypes={vehicleTypes}
          updateVehicle={(dat: any) => {
            setLoading(true)
            updateVehicle(dat)
          }}
          onClose={() => setShowUpdatePopup(false)}
        />
      )}

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <ShowDetails
          vehicleMetadata={vehicleMetadata}
          vehicleTypes={vehicleTypes}
          getData={updatedVehicle}
          downloadFiles={(val: any) => {
            setLoading(true)
            downloadFiles(val?.url, val?.code, val?.name)
          }}
          onClose={() => setShowDetailPopup(false)}
        />
      )}

      {/*FILE POPUP*/}
      {showFilePopup && (
        <UploadFile
          selectedVehicle={selectedVehicle}
          vehicleMetadata={vehicleMetadata}
          uploadVehicleLicense={(val: any) => {
            setLoading(true)
            uploadVehicleLicense(val?.id, val.file)
          }}
          uploadVehiclePlate={(val: any) => {
            setLoading(true)
            uploadVehiclePlate(val?.id, val.file)
          }}
          uploadVehicleCarnet={(val: any) => {
            setLoading(true)
            uploadVehicleCarnet(val?.id, val.file)
          }}
          onClose={() => setShowFilePopup(false)}
        />
      )}
    </>
  )
}
export {Vehicles}
