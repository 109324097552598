import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from '../../../../setup'
import Modal from '../../../Components/Modal'
import CreateNewFreightPopup from '../../../Components/CreateNewFreightPopup'
import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import ViewFreightPosting from './Popup/ViewFreightPosting'
import ViewDetailsFreightPopup from '../../../Components/ViewDetailsFreightPost'
import ShowProposalList from './Popup/ShowProposalList'
import Dropbox from '../../../Components/Dropbox'
import ShowAllProposalList from './Popup/ShowAllProposalList'

import {scrollUnlock} from '../../../../utils/useScrollLock'
import {priceConversion, getCountries} from '../../../../utils/common'
import {PageTitle} from '../../../../_metronic/layout/core'
import getDateTime from '../../../../utils/common'
import {KTSVG} from '../../../../_metronic/helpers'

import {FreightPostingsService} from '../../../../services/CarrierServices/FreightPostingsServices'
import {ContainerService} from '../../../../services/AdminServices/ContainerService'
import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

const FreightPostings = () => {
  const intl = useIntl()
  /**
   ** UTILS
   **/
  const frieghtPostServ = new FreightPostingsService()
  const containerServ = new ContainerService()
  const advertServ = new AdvertService()
  const commonServ = new CommonService()
  const listingServ = new ListingService()

  const userRole: any = useSelector<RootState>(({auth}) => auth, shallowEqual)

  const [loading, setLoading] = useState<boolean>(false)
  const [transporterList, setTransporterList] = useState<any[]>([])
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [pageTransporterIndex, setPageTransporterIndex] = useState<number>(0)
  const [pageProposalListIndex, setPageProposalListIndex] = useState<number>(0)
  const [pageProposalAllListIndex, setPageProposalAllListIndex] = useState<number>(0)
  const [currencyList, setCurrencyList] = useState<any>([])
  const [proposalScopeList, setProposalScopeList] = useState<any[]>([])
  const [proposalList, setProposalList] = useState<any[]>([])
  const [selectedEditProposal, setSelectedEditProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
  })
  const [proposalDetails, setProposalDetails] = useState<any>({})

  /*List States*/
  const [freightPostingList, setFreightPostingList] = useState<any[]>([])
  const [selectedTransporterType, setSelectedTransporterType] = useState<any>(null)
  const [selectedTransporterId, setSelectedTransporterId] = useState<any>(null)
  const [selectedTransporterContainer, setSelectedTransporterContainer] = useState<any[]>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])
  const [transportTypeList, setTransportTypeList] = useState<any>([])

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('all')

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypePageIndex, setTransportTypePageIndex] = useState<number>(0)
  const [transportTypeId, setTransportTypeId] = useState<string>('all')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  /*Screens and modals*/
  const [selectedFreightPost, setSelectedFreightPost] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false)
  const [showFreightDetailsPopup, setShowFreightDetailPopup] = useState<boolean>(false)
  const [showProposalList, setShowProposalList] = useState<boolean>(false)
  const [showAllProposalListPopup, setShowAllProposalListPopup] = useState<boolean>(false)

  const [allProposalListData, setAllProposalListData] = useState<any>([])

  const [allCountryList, setAllCountryList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [selectedFilter, setSelectedFilter] = useState<'all' | 'domestic'>('all')

  const [containerDetails, setContainerDetails] = useState<any>({})
  const [freightPostDetail, setfreightPostDetail] = useState<any>({})

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)
  const [transportTypeScrollAPICalled, setTransportTypeScrollAPICalled] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isNextProposalListPage, setIsNextProposalListPage] = useState<boolean>(false)
  const [isAllNextProposalListPage, setIsAllNextProposalListPage] = useState<boolean>(false)

  const [advertisementListData, setAdevrtisementListData] = useState<any>([])

  /**
   *! API CALL START
   **/
  const advertisementList = () => {
    listingServ
      .getAdvertisementDisplayList({advertisementTypeId: '2'})
      .then((resp: any) => {
        setLoading(false)
        setAdevrtisementListData(
          resp?.data?.length > 0
            ? resp?.data?.map((item: any, index: any) => ({...item, index: (index + 1) * 10}))
            : resp?.data
        )
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFreightPostingList = (pageIndex?: number) => {
    frieghtPostServ
      .getFreightPostings(
        pageIndex,
        '',
        fromCountryId === 'all' ? '' : fromCountryId,
        fromCityId === 'all' ? '' : fromCityId,
        toCountryId === 'all' ? '' : toCountryId,
        toCityId === 'all' ? '' : toCityId,
        transportTypeId === 'all' ? '' : transportTypeId
      )
      .then((resp: any) => {
        setFreightPostingList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getFreightPostingList(pageMIndex)
  }, [fromCountryId, fromCityId, toCountryId, toCityId, transportTypeId])

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const deleteFreightPosting = () => {
    frieghtPostServ
      .deleteTransportApplication(selectedFreightPost?.id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.freightPosting.delete.success'}))
        getFreightPostingList(pageMIndex)
        setShowDeletePopup(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCurrencyList = () => {
    advertServ
      .currencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getProposalScopeList = () => {
    containerServ
      .getCarrierProposalScope()
      .then((resp: any) => {
        setProposalScopeList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  /**
   *! API CALL END
   **/

  const getTransporterList = (pageIndex?: number) => {
    frieghtPostServ
      .getTransporterList(pageIndex ?? 0)
      .then((resp: any) => {
        setTransporterList(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setToCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0)
          setToCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransportTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .transportTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransportTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setTransportTypeList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.description))
          )
        else setTransportTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTransportTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFrightPostDetails = (freightId?: number) => {
    frieghtPostServ
      .getFreightDetailById(freightId)
      .then((resp: any) => {
        setfreightPostDetail(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransporterContainerList = (containerId?: number) => {
    frieghtPostServ
      .getSelectedTransporterList(containerId)
      .then((resp: any) => {
        if (resp?.data.length === 1) {
          setShowFreightDetailPopup(true)
          getFrightPostDetails(resp?.data[0]?.freightId)
        } else {
          setShowDetailsPopup(true)
          setSelectedTransporterContainer(resp?.data)
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getProposalListData = (containerId?: number, pageIndex?: number) => {
    frieghtPostServ
      .getProposalList(containerId, pageIndex)
      .then((resp: any) => {
        setProposalList(resp?.data)
        setLoading(false)
        setIsNextProposalListPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextProposalListPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const createFreightPost = (price: any, currency: any, proposalScope: any, description: any) => {
    frieghtPostServ
      .createTransportApplication(
        selectedFreightPost.id,
        selectedTransporterId,
        price,
        currency,
        proposalScope,
        description
      )
      .then((resp: any) => {
        setLoading(false)
        setShowCreatePopup(false)
        toast.success(intl.formatMessage({id: 'Carrier.freightPosting.create.Success'}))
        getFreightPostingList(pageMIndex)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
    setSelectedTransporterType(null)
    setSelectedTransporterId(null)
    scrollUnlock()
  }

  const handleGetSelectedContainerProposal = (containerProposalId: number) => {
    containerServ
      .getSelectedCarrierContainerProposal(containerProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedEditProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          explanation: resp?.data?.explanation,
          proposalScope: resp?.data?.proposalScope?.proposalScopeId,
        }))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleGetSelectedContainerProposalDetails = (containerProposalId: number) => {
    containerServ
      .getSelectedCarrierContainerProposal(containerProposalId)
      .then((resp: any) => {
        setLoading(false)
        setProposalDetails(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleUpdateProposal = (data: any) => {
    containerServ
      .updateCarrierContainerProposal(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
        getProposalListData(selectedFreightPost?.id, pageProposalListIndex)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleDeleteFreightProposal = (containerProposalId: number) => {
    containerServ
      .deleteCarrierContainerProposal(containerProposalId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.delete.success'}))
        setLoading(false)
        getProposalListData(selectedFreightPost?.id, pageProposalListIndex)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getFreightPostingList(page)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const onTransporterPaginationChange = (page: number) => {
    getTransporterList(page)
    setPageTransporterIndex(page)
  }

  const getCountryList = () => {
    commonServ
      .countryList()
      .then((res: any) => {
        setAllCountryList(res?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getAllProposalByUser = (page: number) => {
    containerServ
      .getContainerProposalByUser(page)
      .then((res: any) => {
        setLoading(false)
        setIsAllNextProposalListPage(false)
        setAllProposalListData(res.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setIsAllNextProposalListPage(false)
      })
  }

  const handleGetContainerDetails = (containerId?: any) => {
    containerServ
      .getCarrierContainerWithId(containerId)
      .then((res: any) => {
        setLoading(false)
        setContainerDetails(res?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleProposalListPagination = (page: number) => {
    if (!isNextProposalListPage) {
      getProposalListData(selectedFreightPost?.id, page)
      setPageProposalListIndex(page)
    }
    setIsNextProposalListPage(true)
  }

  const handleAllProposalPagination = (page: number) => {
    if (!isAllNextProposalListPage) {
      getAllProposalByUser(page)
      setPageProposalAllListIndex(page)
    }

    setIsAllNextProposalListPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getFreightPostingList(pageMIndex)
    getFromCountryList()
    getToCountryList()
    getCountryList()
    getTransportTypeList()
    advertisementList()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.forwarderOperator.freightPosts'})}
      </PageTitle>

      <div
        className={`row ${
          userRole.role.includes('CARRIER_OPERATOR') || userRole.role.includes('FORWARDER_OPERATOR')
            ? showFilterPanel
              ? ''
              : 'justify-content-between mb-5'
            : showFilterPanel
            ? 'justify-content-between mb-5'
            : 'justify-content-end mb-5'
        }`}
      >
        {showFilterPanel && (
          <div className={'form-check col-md-3 radio-wrap'}>
            <input
              type='radio'
              id={'abroad'}
              className='form-check-input'
              onChange={() => {
                setSelectedFilter('all')
                if (selectedFilter === 'domestic') {
                  const result = allCountryList.find(
                    (item: any) => item.code === userRole?.user?.country
                  )
                  setPageMIndex(0)
                  setFromCountryId(userRole?.user?.country)
                  setSelectedFromFilterCountry(result?.name)
                  setSelectedToFilterCountry(result?.name)
                  setToCountryId(userRole?.user?.country)
                }
              }}
              checked={selectedFilter === 'all'}
            />
            <label htmlFor='abroad' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Carrier.freightPosting.filter.all'})}
            </label>
          </div>
        )}

        {showFilterPanel && (
          <div className={'form-check col-md-3 radio-wrap'}>
            <input
              type='radio'
              id={'domestic'}
              onChange={() => {
                setSelectedFilter('domestic')
                if (selectedFilter === 'all') {
                  const result = allCountryList.find(
                    (item: any) => item.code === userRole?.user?.country
                  )
                  setPageMIndex(0)
                  setSelectedFromFilterCountry(result?.name)
                  setSelectedToFilterCountry(result?.name)
                  setFromCountryId(userRole?.user?.country)
                  setToCountryId(userRole?.user?.country)
                }
              }}
              checked={selectedFilter === 'domestic'}
              className='form-check-input'
            />
            <label htmlFor='domestic' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Carrier.freightPosting.filter.domestic'})}
            </label>
          </div>
        )}

        {(userRole.role.includes('CARRIER_OPERATOR') ||
          userRole.role.includes('FORWARDER_OPERATOR')) && (
          <div className={`col-md-3 ${showFilterPanel ? 'carrier-show-proposal-wrap' : ''}`}>
            <button
              className={'btn btn-primary'}
              onClick={() => {
                setLoading(true)
                getAllProposalByUser(pageProposalAllListIndex)
                setShowAllProposalListPopup(true)
                getCurrencyList()
                getProposalScopeList()
              }}
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.filter.showAll'})}
            </button>
          </div>
        )}
        <span
          className={`${
            showFilterPanel ? 'col-md-3' : 'col-md-2'
          } menu-icon d-flex justify-content-end`}
        >
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>

      {showFilterPanel && (
        <div className='row justify-content-between'>
          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedFromFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={fromCountrySearch}
                customOnSearch={(e: any) => {
                  setFromCountryPageIndex(0)
                  setFromCountrySearch(e.target.value)
                  getFromCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setFromCountryPageIndex(val)
                  getFromCountryList(val, fromCountrySearch)
                }}
                setScrollCalled={() => {
                  setFromCountryScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCountryScrollAPICalled}
                disabled={selectedFilter === 'domestic'}
                isTemplate
                currentPageIndex={fromCountryPageIndex}
                menuList={
                  fromCountryList &&
                  fromCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.code,
                        title: (
                          <div className='countryWrap'>
                            <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                            {item?.name}
                          </div>
                        ),
                      }
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCountryId(item?.id)
                    setSelectedFromFilterCountry(item?.title)
                    setDependentFromCity(true)
                    setFromCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!fromCountryId || fromCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={fromCitySearch}
                customOnSearch={(e: any) => {
                  setFromCityPageIndex(0)
                  setFromCitySearch(e.target.value)
                  getFromSearchCityList(fromCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (fromCountryId) {
                    setFromCityPageIndex(val)
                    getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setFromCityScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCityScrollAPICalled}
                currentPageIndex={fromCityPageIndex}
                menuList={
                  fromCityList &&
                  fromCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: fromCityId}}
                dependentValue={dependentFromCity}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCityId(item?.id)
                    setDependentFromCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedToFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={toCountrySearch}
                customOnSearch={(e: any) => {
                  setToCountryPageIndex(0)
                  setToCountrySearch(e.target.value)
                  getToCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setToCountryPageIndex(val)
                  getToCountryList(val, toCountrySearch)
                }}
                setScrollCalled={() => {
                  setToCountryScrollAPICalled(true)
                }}
                scrollAPIResponse={toCountryScrollAPICalled}
                disabled={selectedFilter === 'domestic'}
                defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                currentPageIndex={toCountryPageIndex}
                isTemplate
                menuList={
                  toCountryList &&
                  toCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.code,
                        title: (
                          <div className='countryWrap'>
                            <KTSVG path={getCountries[item?.code]} className='svg-icon-2x' />
                            {item?.name}
                          </div>
                        ),
                      }
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCountryId(item?.id)
                    setSelectedToFilterCountry(item?.title)
                    setDependentToCity(true)
                    setToCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!toCountryId || toCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={toCitySearch}
                customOnSearch={(e: any) => {
                  setToCityPageIndex(0)
                  setToCitySearch(e.target.value)
                  getToSearchCityList(toCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (toCountryId) {
                    setToCityPageIndex(val)
                    getToSearchCityList(toCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setToCityScrollAPICalled(true)
                }}
                scrollAPIResponse={toCityScrollAPICalled}
                currentPageIndex={toCityPageIndex}
                dependentValue={dependentToCity}
                menuList={
                  toCityList &&
                  toCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{toCityId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCityId(item?.id)
                    setDependentToCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Freight.myAdvert.transportType'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Freight.myAdvert.transportType'})}
                customSearchValue={transportTypeSearch}
                customOnSearch={(e: any) => {
                  setTransportTypePageIndex(0)
                  setTransportTypeSearch(e.target.value)
                  getTransportTypeList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setTransportTypePageIndex(val)
                  getTransportTypeList(val, transportTypeSearch)
                }}
                setScrollCalled={() => {
                  setTransportTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={transportTypeScrollAPICalled}
                currentPageIndex={transportTypePageIndex}
                menuList={
                  transportTypeList &&
                  transportTypeList
                    ?.filter(function ({transportTypeId}: any) {
                      // @ts-ignore
                      return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.transportTypeId, title: item?.description}
                    })
                }
                defaultClearItem={{
                  id: '',
                  title: '',
                }}
                defaultSelected={{id: transportTypeId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setTransportTypeId(item?.id)
                  }

                  if (item?.id === '' && item?.title === '') {
                    setTransportTypeId('')
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        {!freightPostingList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.freightPosting.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.freightContentType'}),
              accessor: 'freightContentType',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.preview.freightType'}),
              accessor: 'freightType',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.transportType'}),
              accessor: 'transportType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.trailerType'}),
              accessor: 'trailerType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.target.price'}),
              accessor: 'price',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.currencyCode'}),
              accessor: 'currencyCode',
            },
            {
              Header: '',
              accessor: 'fromAddressIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  <KTSVG
                    path={getCountries[row?.values?.fromAddressIcon]}
                    className='svg-icon-2x'
                  />
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.fromAddress'}),
              accessor: 'fromAddress',
            },
            {
              Header: '',
              accessor: 'toAddressIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  <KTSVG path={getCountries[row?.values?.toAddressIcon]} className='svg-icon-2x' />
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.toAddress'}),
              accessor: 'toAddress',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.plannedDepartureDate'}),
              accessor: 'plannedDepartureDate',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.freightPosting.delete.status'}),
              accessor: 'status',
            },
            {
              Header: 'appliedForTransport',
              accessor: 'appliedForTransport',
              hidden: true,
            },
            {
              Header: 'freightDetail',
              accessor: 'freightDetail',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
            {
              Header: 'isContainer',
              accessor: 'isContainer',
              hidden: true,
            },
          ]}
          editableRows={['']}
          data={
            freightPostingList &&
            freightPostingList?.map((item: any) => {
              return {
                id: item?.containerId,
                freightContentType: item.freightContentType?.description,
                freightType: item.freightType?.description,
                transportType: item.transportType?.description,
                price:
                  item?.price && priceConversion(item?.price, item?.priceCurrency?.currencyCode),
                currencyCode: item?.priceCurrency?.currencyCode,
                fromAddressIcon: item?.fromAddress?.country?.code,
                fromAddress: item?.fromAddress?.city.name + ', ' + item?.fromAddress?.country.name,
                toAddressIcon: item?.toAddress?.country?.code,
                toAddress: item?.toAddress?.city.name + ', ' + item?.toAddress?.country.name,
                plannedDepartureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                trailerType: item?.trailerType?.description,
                appliedForTransport: item?.appliedForTransport,
                status:
                  item?.appliedForTransport === 'Y'
                    ? intl.formatMessage({id: 'Carrier.freightPosting.status'})
                    : '',
                isFreightPostings: true,
                freightDetail: item,
                isContainer: true,
                hiddenButtons: {
                  createBtn:
                    userRole.role.includes('CARRIER_OPERATOR') ||
                    userRole.role.includes('FORWARDER_OPERATOR')
                      ? item?.appliedForTransport === 'Y'
                      : true,
                  deleteRow: item?.appliedForTransport === 'N',
                  proposalBtn: item?.appliedForTransport !== 'Y',
                },
              }
            })
          }
          deleteRow={(row: any) => {
            setSelectedFreightPost(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Carrier.freightPosting.table.deleteText'})}
          createBtn={(row: any) => {
            setShowCreatePopup(true)
            setLoading(true)
            getCurrencyList()
            getProposalScopeList()
            getTransporterList(pageTransporterIndex)
            setSelectedFreightPost(row.values)
          }}
          proposalBtn={(row: any) => {
            setShowProposalList(true)
            setLoading(true)
            getCurrencyList()
            getProposalScopeList()
            setSelectedFreightPost(row.values)
            getProposalListData(Number(row?.values?.id))
          }}
          proposalBtnText={intl.formatMessage({
            id: 'Carrier.freightPosting.table.action.showProposal',
          })}
          showDetailBtn={(row: any) => {
            setLoading(true)
            getTransporterContainerList(row.values.id)
            setSelectedFreightPost(row.values)
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
          advertisementListData={advertisementListData}
        />

        {/*CREATE POPUP*/}
        {showCreatePopup && (
          <CreateNewFreightPopup
            onSubmit={(price: any, currency: any, proposalScope: any, description: any) => {
              setLoading(true)
              createFreightPost(price, currency, proposalScope, description)
            }}
            currencyList={currencyList}
            proposalScopeList={proposalScopeList}
            transporterList={transporterList}
            selectedTransporterType={selectedTransporterType}
            setSelectedTransporterId={setSelectedTransporterId}
            setSelectedTransporterType={setSelectedTransporterType}
            onClose={() => setShowCreatePopup(false)}
            pageTransporterIndex={pageTransporterIndex}
            onTransporterPaginationChange={onTransporterPaginationChange}
          />
        )}

        {showAllProposalListPopup && (
          <ShowAllProposalList
            proposalList={allProposalListData}
            onClose={() => setShowAllProposalListPopup(false)}
            getSelectedProposalDetail={(containerProposalId: any) =>
              handleGetSelectedContainerProposalDetails(containerProposalId)
            }
            getAllProposalByUser={getAllProposalByUser}
            proposalScopeList={proposalScopeList}
            setLoading={setLoading}
            currencyList={currencyList}
            proposalDetails={proposalDetails}
            getContainerDetail={(containerId: any) => handleGetContainerDetails(containerId)}
            containerDetails={containerDetails}
            pageProposalAllListIndex={pageProposalAllListIndex}
            handleAllProposalPagination={handleAllProposalPagination}
          />
        )}

        {showProposalList && (
          <ShowProposalList
            proposalList={proposalList}
            proposalScopeList={proposalScopeList}
            onClose={() => setShowProposalList(false)}
            currencyList={currencyList}
            setLoading={setLoading}
            getSelectedFreightProposal={(containerProposalId: any) =>
              handleGetSelectedContainerProposal(containerProposalId)
            }
            deleteFreigthProposal={(containerProposalId: any) =>
              handleDeleteFreightProposal(containerProposalId)
            }
            selectedEditProposal={selectedEditProposal}
            setSelectedEditProposal={setSelectedEditProposal}
            handleUpdateProposal={handleUpdateProposal}
            getSelectedProposalDetail={(containerProposalId: any) =>
              handleGetSelectedContainerProposalDetails(containerProposalId)
            }
            proposalDetails={proposalDetails}
            pageProposalListIndex={pageProposalListIndex}
            handleProposalListPagination={handleProposalListPagination}
          />
        )}

        {showDetailsPopup && (
          <ViewFreightPosting
            freightPostingList={selectedTransporterContainer}
            onClose={() => {
              setSelectedTransporterContainer([])
              setShowDetailsPopup(false)
              scrollUnlock()
            }}
          />
        )}

        {showFreightDetailsPopup && Object.keys(freightPostDetail).length > 0 && (
          <ViewDetailsFreightPopup
            onClose={() => {
              setShowFreightDetailPopup(false)
            }}
            selectedFreightPost={freightPostDetail}
          />
        )}

        {/*DELETE POPUP*/}
        {showDeletePopup && (
          <Modal
            closeBtnSettings={{
              show: true,
              onClick: () => setShowDeletePopup(false),
            }}
            customAll={
              <div className={''}>
                <h3 className={'mt-5 mb-15'}>
                  {intl.formatMessage({id: 'Carrier.freightPosting.delete.confirmDelete'})}
                </h3>
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-danger w-100'}
                      onClick={() => {
                        setShowDeletePopup(false)
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.freightPosting.delete.cancel'})}
                    </button>
                  </div>
                  <div className={'col-md-6'}>
                    <button
                      className={'btn btn-success w-100'}
                      onClick={() => {
                        setLoading(true)
                        deleteFreightPosting()
                      }}
                    >
                      {intl.formatMessage({id: 'Carrier.freightPosting.delete.approve'})}
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export {FreightPostings}
