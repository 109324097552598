import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import CreateClient from './popups/CreateClient'
import EditClient from './popups/EditClient'
import ClientDetails from './popups/ClientDetails'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'

import {ClientService} from '../../../../services/CarrierServices/ClientService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import './Client.scss'

const Client = () => {
  const intl = useIntl()
  const [createClientPopup, showCreateClientPopup] = useState<boolean>(false)
  const [editClientPopup, showEditClientPopup] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showClientDetailPopup, setShowClientDetailPopup] = useState<boolean>(false)

  const [selectedClientId, setSelectedClientId] = useState<any>(null)
  const [clientList, setClientList] = useState<any>([])
  const [selectedClientDetails, setSelectedClientDetails] = useState<any>({})
  const [clientTypeList, setClientTypeList] = useState<any>([])
  const [countriesList, setCountriesList] = useState<any>([])

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const clientServ = new ClientService()
  const commonServ = new CommonService()

  const getClientListData = (pageIndex: number) => {
    clientServ
      .getClientList({pageIndex: pageIndex})
      .then((res: any) => {
        setLoading(false)
        setIsNextPage(false)
        setClientList(res?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getClientListData(page)
      setPageIndex(page)
    }
    setIsNextPage(true)
  }

  const getCountriesList = () => {
    commonServ
      .publicCountryList()
      .then((res: any) => {
        setLoading(false)
        setCountriesList(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleCreateClient = (data: any) => {
    clientServ
      .createClient(data)
      .then((res: any) => {
        setLoading(false)
        getClientListData(pageIndex)
        toast.success(intl.formatMessage({id: 'CreateClient.create.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleUpdateClient = (data: any) => {
    clientServ
      .updateClient(data)
      .then((res: any) => {
        setLoading(false)
        getClientListData(pageIndex)
        setSelectedClientDetails({})
        toast.success(intl.formatMessage({id: 'CreateClient.update.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getClientTypeList = () => {
    clientServ
      .getClientTypeList()
      .then((res: any) => {
        setLoading(false)
        setClientTypeList(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleGetClientDetails = (clientId?: number) => {
    clientServ
      .getClientDetailById(clientId)
      .then((res: any) => {
        setLoading(false)
        setSelectedClientDetails(res.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handledeleteClient = () => {
    clientServ
      .deleteClient(selectedClientId)
      .then((res: any) => {
        setLoading(false)
        getClientListData(pageIndex)
        toast.success(intl.formatMessage({id: 'CreateClient.delete.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getClientListData(pageIndex)
    getCountriesList()
    getClientTypeList()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SideMenu.carrierOperator.clients'})}
      </PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => showCreateClientPopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'CreateClient.createClient.create'})}
        </button>
      </div>
      {!clientList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'Carrier.transporters.noData'})}
        </div>
      )}

      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.name'}),
            accessor: 'name',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.countryCode'}),
            accessor: 'countryCode',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.phone'}),
            accessor: 'phone',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.email'}),
            accessor: 'email',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.web'}),
            accessor: 'web',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.country'}),
            accessor: 'country',
          },
          {
            Header: intl.formatMessage({id: 'CreateClient.dataTable.companyType'}),
            accessor: 'companyType',
          },
        ]}
        data={
          clientList &&
          clientList?.map((item: any) => {
            return {
              id: item?.clientId,
              name:
                item?.name +
                ' ' +
                (item?.clientType?.code === 'CORPORATE' ? item?.title ?? '' : item?.surname ?? ''),
              countryCode: item?.countryCode,
              phone: item?.phone,
              email: item?.email,
              web: item?.web,
              country: item?.country?.name,
              companyType: item?.clientType?.description,
            }
          })
        }
        showDetailBtn={(row: any) => {
          setShowClientDetailPopup(true)
          handleGetClientDetails(Number(row.values.id))
        }}
        editBtn={(row: any) => {
          setLoading(true)
          showEditClientPopup(true)
          setSelectedClientId(Number(row.values.id))
          handleGetClientDetails(Number(row.values.id))
        }}
        deleteRow={(row: any) => {
          setSelectedClientId(Number(row.values.id))
          setShowDeletePopup(true)
        }}
        deleteText={intl.formatMessage({id: 'CreateClient.delete.row.title'})}
        editableRows={['']}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageIndex}
      />

      {showClientDetailPopup && (
        <ClientDetails
          onClose={() => setShowClientDetailPopup(false)}
          selectedClientDetails={selectedClientDetails}
        />
      )}

      {editClientPopup && Object.keys(selectedClientDetails).length > 0 && (
        <EditClient
          countriesList={countriesList}
          setLoading={setLoading}
          ClientList={clientTypeList}
          onClose={() => showEditClientPopup(false)}
          selectedClientDetails={selectedClientDetails}
          handleUpdateClient={(data: any) => {
            handleUpdateClient(data)
            showEditClientPopup(false)
          }}
          selectedClientId={selectedClientId}
        />
      )}

      {createClientPopup && (
        <CreateClient
          countriesList={countriesList}
          setLoading={setLoading}
          ClientList={clientTypeList}
          onClose={() => showCreateClientPopup(false)}
          handleCreateClient={(data: any) => {
            handleCreateClient(data)
            showCreateClientPopup(false)
          }}
        />
      )}

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'CreateClient.delete.modal.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeletePopup(false)
                      handledeleteClient()
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.freightPosting.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export {Client}
