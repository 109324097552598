import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import Modal from '../../../../Components/Modal'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import CreateRoute from './CreateRoute'

import useScrollLock from '../../../../../utils/useScrollLock'

import {PreferredRouteService} from '../../../../../services/CarrierServices/PreferredRouteService'
import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

const PreferredRoutes = ({
  onClose,
  getCompanies,
  setLoading,
  getPreferredRouteList,
  preferredRouteList,
  onPaginationChange,
  pageMIndex,
  selectedCompanyId,
  transportTypeList,
}: any) => {
  const intl = useIntl()

  const commonServ = new CommonService()

  const [countries, setCountries] = useState<any[]>([])
  const [fromCities, setFromCities] = useState<any[]>([])
  const [toCities, setToCities] = useState<any[]>([])

  const [fromCountryCustomSearch, setFromCountryCustomSearch] = useState<string>('')
  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)

  const [toCountryCustomSearch, setToCountryCustomSearch] = useState<string>('')
  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)

  const routeServ = new PreferredRouteService()

  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)

  const [selectedPreferredRouteId, setSelectedPreferredRouteId] = useState<any>(null)

  const {scrollLock} = useScrollLock()

  const handleCreatePreferredRoute = (data: any) => {
    routeServ
      .createPreferredRoute({...data, companyId: selectedCompanyId})
      .then((res: any) => {
        setLoading(false)
        getCompanies(0)
        toast.success(intl.formatMessage({id: 'Admin.companies.preferredRoutes.create.success'}))
        setShowCreatePopup(false)
        getPreferredRouteList(selectedCompanyId, 0)
      })
      .catch((e: any) => {
        setShowCreatePopup(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCities(resp?.data)
        else setFromCities((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: string) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCities(resp?.data)
        else setToCities((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCountries = () => {
    routeServ
      .getCountries()
      .then((resp: any) => {
        setCountries(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleDeletePreferredRoute = (preferredRouteId: any) => {
    routeServ
      .deleteRoute(preferredRouteId)
      .then((res: any) => {
        setLoading(false)
        setShowDeletePopup(false)
        getCompanies(0)
        toast.success(intl.formatMessage({id: 'Admin.companies.preferredRoutes.delete.success'}))
        getPreferredRouteList(selectedCompanyId, 0)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    scrollLock()
    getCountries()
  }, [])

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'Admin.Containers.preferredRoute'})}
      className='admin-details-company-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => {
            setShowCreatePopup(true)
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          {intl.formatMessage({id: 'Carrier.prefRoute.addNewRoute'})}
        </button>
      </div>
      {!preferredRouteList.length && (
        <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
          {intl.formatMessage({id: 'global.noData'})}
        </div>
      )}
      <DataTableAdvanced
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: intl.formatMessage({id: 'RouteWidget.from'}),
            accessor: 'fromWhere',
          },
          {
            Header: intl.formatMessage({id: 'RouteWidget.to'}),
            accessor: 'toWhere',
          },
          {
            Header: intl.formatMessage({id: 'prefRoute.route.transport'}),
            accessor: 'transportType',
          },
        ]}
        editableRows={['']}
        data={preferredRouteList?.map((item: any) => {
          return {
            id: item?.preferredRouteId,
            fromWhere: item?.fromCity?.name + ' / ' + item?.fromCountry?.name,
            toWhere: item?.toCity?.name + ' / ' + item?.toCountry?.name,
            transportType: item?.transportType?.description,
          }
        })}
        deleteRow={(row: any) => {
          setShowDeletePopup(true)
          setSelectedPreferredRouteId(row?.values?.id)
        }}
        deleteText={intl.formatMessage({id: 'RouteWidget.delete'})}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageMIndex}
      />

      {showCreatePopup && (
        <CreateRoute
          onClose={() => setShowCreatePopup(false)}
          countries={countries}
          fromCities={fromCities}
          fromCountryCustomSearch={fromCountryCustomSearch}
          setFromCountryCustomSearch={setFromCountryCustomSearch}
          toCountryCustomSearch={toCountryCustomSearch}
          setToCountryCustomSearch={setToCountryCustomSearch}
          fromCitySearch={fromCitySearch}
          setFromCityPageIndex={setFromCityPageIndex}
          setFromCitySearch={setFromCitySearch}
          setFromCityScrollAPICalled={setFromCityScrollAPICalled}
          fromCityScrollAPICalled={fromCityScrollAPICalled}
          fromCityPageIndex={fromCityPageIndex}
          getFromSearchCityList={getFromSearchCityList}
          toCities={toCities}
          createRoute={(data: any) => {
            setLoading(true)
            handleCreatePreferredRoute(data)
          }}
          transportTypeList={transportTypeList}
          toCitySearch={toCitySearch}
          setToCityPageIndex={setToCityPageIndex}
          setToCitySearch={setToCitySearch}
          setToCityScrollAPICalled={setToCityScrollAPICalled}
          toCityScrollAPICalled={toCityScrollAPICalled}
          toCityPageIndex={toCityPageIndex}
          getToSearchCityList={getToSearchCityList}
        />
      )}

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.drivers.delete.confirmDelete'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      handleDeletePreferredRoute(selectedPreferredRouteId)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.drivers.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      <div className='text-center mt-5 row view-details-freight-popup'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export default PreferredRoutes
