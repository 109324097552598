import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import ViewDetailsFreightPopup from '../../../../Components/ViewDetailsFreightPost'

import {FreightPostingsService} from '../../../../../services/CarrierServices/FreightPostingsServices'

import useScrollLock from '../../../../../utils/useScrollLock'
import getDateTime, {priceConversion} from '../../../../../utils/common'

import '../FreightPostings.scss'

const ViewFreightPosting = ({freightPostingList, onClose}: any) => {
  const intl = useIntl()
  const [pageViewPostIndex, setpageViewPostIndex] = useState<number>(0)
  const [freightPostDetail, setfreightPostDetail] = useState<any>({})
  const [showFreightDetailPopup, setShowFreightDetailPopup] = useState<boolean>(false)
  const frieghtPostServ = new FreightPostingsService()

  const {scrollLock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  const getFrightPostDetails = (freightId?: number) => {
    frieghtPostServ
      .getFreightDetailById(freightId)
      .then((resp: any) => {
        setfreightPostDetail(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  return (
    <>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'Carrier.freightPostings.view.list.title'})}
        className='carrier-view-freight-exchange-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        {!freightPostingList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Freight.createAdvert.freightContentType'}),
              accessor: 'freightContentType',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
              accessor: 'company',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_from'}),
              accessor: 'fromLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_to'}),
              accessor: 'toLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_departureDate'}),
              accessor: 'departureDate',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_weight'}),
              accessor: 'weight',
            },
          ]}
          data={
            freightPostingList &&
            freightPostingList.map((item: any) => {
              return {
                id: item?.freightId,
                freightContentType: item.freightContentType?.description,
                company: item?.company?.name ?? '-',
                fromLocation: item?.fromLocation?.addressText ?? '-',
                toLocation: item?.toLocation?.addressText ?? '-',
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                weight: item?.weight ?? '-',
              }
            })
          }
          editableRows={['']}
          showDetailBtn={(row: any) => {
            setShowFreightDetailPopup(true)
            getFrightPostDetails(row.values.id)
          }}
          pageMIndex={pageViewPostIndex}
        />

        <div className='text-center mt-5 row view-freight-popup view-details-freight-popup freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={onClose}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-85 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>

      {showFreightDetailPopup && Object.keys(freightPostDetail).length > 0 && (
        <ViewDetailsFreightPopup
          onClose={() => {
            setShowFreightDetailPopup(false)
          }}
          selectedFreightPost={freightPostDetail}
        />
      )}
    </>
  )
}

export default ViewFreightPosting
