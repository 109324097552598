import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'
import Dropbox from '../../../../Components/Dropbox'

import {TransporterGroupService} from '../../../../../services/CarrierServices/TransporterGroupService'

const CreateTransporterGroupManager = ({
  onClose,
  createTransporterGroupManager,
  setLoading,
}: any) => {
  const intl = useIntl()
  const transporterGroupServ = new TransporterGroupService()

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [userCustomSearch, setUserCustomSearch] = useState<string>('')

  const [selectedUser, setSelectedUser] = useState<string>('')

  const [userList, setUserList] = useState<any>([])

  const [userListScrollAPICalled, setUserListScrollAPICalled] = useState<boolean>(false)

  const {scrollLock} = useScrollLock()

  const getUserList = (pageIndex: number = 0, searchText?: string) => {
    transporterGroupServ
      .getUserList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setUserListScrollAPICalled(false)
        if (pageIndex === 0) setUserList(resp?.data?.filter((item: any) => item?.userId))
        else setUserList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setUserListScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    scrollLock()
    getUserList()
  }, [])

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.manager.title'})}
      closeOnClick={() => onClose()}
    >
      <div className='row mt-10 mb-10 col-md-12'>
        <h6 className={'mb-3'}>
          {intl.formatMessage({id: 'Carrier.transporterGroup.manager.user'})}
        </h6>
        <div className={''}>
          <Dropbox
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'Carrier.transporterGroup.manager.user'})}
            customSearchValue={userCustomSearch}
            customOnSearch={(e: any) => {
              setCurrentPageIndex(0)
              setUserCustomSearch(e.target.value)
              getUserList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setCurrentPageIndex(val)
              getUserList(val, userCustomSearch)
            }}
            setScrollCalled={() => {
              setUserListScrollAPICalled(true)
            }}
            scrollAPIResponse={userListScrollAPICalled}
            currentPageIndex={currentPageIndex}
            menuList={
              userList &&
              userList
                .filter(function ({userId}: any) {
                  // @ts-ignore
                  return !this[userId] && (this[userId] = userId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.userId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: '',
              title: '',
            }}
            defaultSelected={{id: selectedUser}}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setSelectedUser(item?.id)
              }

              if (item?.id === '' && item?.title === '') {
                setSelectedUser('')
              }
            }}
          />
        </div>
      </div>

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            type={'button'}
            disabled={!selectedUser}
            onClick={() => {
              onClose()
              setLoading(true)
              createTransporterGroupManager(selectedUser)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {CreateTransporterGroupManager}
