import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import CreateTrailer from './Popups/CreateTrailer'
import EditPreferences from './Popups/EditPreferences'
import TrailerDetails from './Popups/TrailerDetails'
import UploadFiles from './Popups/UploadFiles'

import useScrollLock from '../../../../utils/useScrollLock'

import {TrailersService} from '../../../../services/CarrierServices/TrailersService'

import './Trailers.scss'

const Trailers = () => {
  const intl = useIntl()
  /**
   ** UTILS
   **/
  const trailerServ = new TrailersService()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  /* const intl = useIntl()*/

  /*List States*/
  const [trailerList, setTrailerList] = useState<any[]>([])
  const [trailerTypes, setTrailerTypes] = useState<any[]>([])
  const [floorTypes, setFloorTypes] = useState<any[]>([])
  const [specificationList, setSpecificationList] = useState<any[]>([])
  const [trailerMetadata, setTrailerMetadata] = useState<any[]>([])

  /*Screens and modals*/
  const [selectedTrailer, setSelectedTrailer] = useState<any>(null)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false)
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showFilePopup &&
      !showDetailPopup &&
      !showCreatePopup &&
      !showDeletePopup &&
      !showUpdatePopup
    )
      scrollUnlock()
  }, [showFilePopup, showDetailPopup, showCreatePopup, showDeletePopup, showUpdatePopup])

  /*Popup States*/
  const [updatedTrailer, setUpdatedTrailer] = useState<any>({
    id: -1,
    year: 1,
    plate: '',
    /*capacity: 0,*/
    make: '',
    model: '',
    trailerType: '',
    specificationList: [],
    floorType: '',
  })
  const [specUpdatedSelected, setSpecUpdatedSelected] = useState<any>([])

  /**
   *! API CALL START
   **/
  const getTrailersList = (pageIndex?: number) => {
    trailerServ
      .getTrailers(pageIndex)
      .then((resp: any) => {
        setTrailerList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setIsNextPage(false)
      })
  }

  const getTrailer = (id: number) => {
    return trailerServ.getTrailer(id)
  }

  const getTrailerTypes = () => {
    trailerServ
      .getTrailerTypes()
      .then((resp: any) => {
        setTrailerTypes(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getFloorTypes = () => {
    trailerServ
      .getFloorTypes()
      .then((resp: any) => {
        setFloorTypes(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getSpecifications = (trailerType: number) => {
    trailerServ
      .getSpecifications(trailerType)
      .then((resp: any) => {
        setSpecificationList(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const deleteTrailer = (id: number) => {
    trailerServ
      .deleteTrailer(id)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.trailerDeleteSuccess'}))
        getTrailersList(pageMIndex)
        setShowDeletePopup(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const updateTrailer = (
    data: {
      id: number
      year: number
      plate: string
      /*capacity: number,*/ make: string
      model: string
      trailerType: number
      floorType: number
    },
    specs: any
  ) => {
    trailerServ
      .updateTrailer(
        data.id,
        data.year,
        data.plate,
        /*data.capacity,*/ data.make,
        data.model,
        data.trailerType,
        specs,
        data.floorType
      )
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.trailerUpdateSuccess'}))
        getTrailersList(pageMIndex)
        setShowUpdatePopup(false)
        setSpecificationList([])
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const createTrailer = (data: {
    year: number
    plate: string
    /*capacity: number,*/ make: string
    model: string
    specificationList: any
    trailerType: number
    floorType: number
  }) => {
    trailerServ
      .createTrailer(
        data.year,
        data.plate,
        /*data.capacity,*/ data.make,
        data.model,
        data.trailerType,
        data.specificationList,
        data.floorType
      )
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.trailerCreateSuccess'}))
        getTrailersList(pageMIndex)
        setShowCreatePopup(false)
        setSpecificationList([])
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const uploadTrailerPlate = (userId: number, file: any) => {
    trailerServ
      .uploadTrailerPlate(userId, file)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Carrier.trailer.plateUploadSuccess'}))
        setLoading(false)
        setShowFilePopup(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getAllMetadata = (id: number) => {
    trailerServ
      .getAllMetadata(id)
      .then((resp: any) => {
        setTrailerMetadata(resp.data)
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const downloadFiles = (url: string, type: string, name: string) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application+file/json',
      },
    })
      .then((res: any) => res.blob())
      .then((resp: any) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(new Blob([resp]))
        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', type + '.' + name.split('.').reverse()[0]) //or any other extension
        document.body.appendChild(link)
        link.click()
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        setLoading(false)
      })
  }
  /**
   *! API CALL END
   **/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getTrailersList(page)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  useEffect(() => {
    if (updatedTrailer?.trailerType?.trailerTypeId) {
      setLoading(true)
      getSpecifications(updatedTrailer?.trailerType?.trailerTypeId)
      setSpecUpdatedSelected([])
    }
  }, [updatedTrailer.trailerType])

  useEffect(() => {
    setLoading(true)
    getTrailersList(pageMIndex)
    setLoading(true)
    getTrailerTypes()
    setLoading(true)
    getFloorTypes()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Carrier.trailer.title'})}</PageTitle>
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => setShowCreatePopup(true)}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'Carrier.trailer.createTrailer'})}
        </button>
      </div>
      <div>
        {!trailerList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'Carrier.trailer.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.numberPlate'}),
              accessor: 'plate',
            },
            /*{
                            Header: 'Kapasite',
                            accessor: 'capacity',
                        },*/
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.trailerType'}),
              accessor: 'trailerType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.floorType'}),
              accessor: 'floorType',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.trailer.specification'}),
              accessor: 'specifications',
            },
          ]}
          data={
            trailerList &&
            trailerList?.map((item: any) => {
              return {
                id: item?.trailerId,
                plate: item?.plate,
                /* capacity: item?.capacity,*/
                trailerType: item?.trailerType?.description,
                floorType: item?.floorType?.description,
                specifications: item?.specificationList?.map(
                  (item: any) => item.description + '\n'
                ),
              }
            })
          }
          editBtn={(row: any) => {
            setLoading(true)
            setSelectedTrailer(row.values)
            getTrailer(row.values.id)
              .then((resp: any) => {
                setUpdatedTrailer((item: any) => ({
                  ...item,
                  id: resp.data.trailerId,
                  year: resp.data.year,
                  plate: resp.data.plate,
                  /*  capacity: resp.data.capacity,*/
                  make: resp.data.make,
                  model: resp.data.model,
                  trailerType: resp.data.trailerType.trailerTypeId,
                  floorType: resp.data.floorType.floorTypeId,
                  specificationList: resp.data.specificationList,
                }))
                setSpecUpdatedSelected(
                  resp.data.specificationList?.map((item: any) => item?.specificationId)
                )
                setLoading(false)
                setShowUpdatePopup(true)
              })
              .catch((e) => {
                toast.error(e.response.data.message + ' | ' + e.response.data.code)
                setLoading(false)
              })
          }}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setSelectedTrailer(row.values)
            getTrailer(row.values.id)
              .then((resp: any) => {
                getAllMetadata(row.values.id)
                setUpdatedTrailer((item: any) => ({
                  ...item,
                  id: resp.data.trailerId,
                  year: resp.data.year,
                  plate: resp.data.plate,
                  /*  capacity: resp.data.capacity,*/
                  make: resp.data.make,
                  model: resp.data.model,
                  trailerType: resp.data.trailerType,
                  floorType: resp.data.floorType.description,
                  specificationList: resp.data.specificationList,
                }))
                setSpecUpdatedSelected(
                  resp.data.specificationList?.map((item: any) => item?.specificationId)
                )
                setLoading(false)
                setShowDetailPopup(true)
              })
              .catch((e) => {
                toast.error(e.response.data.message + ' | ' + e.response.data.code)
                setLoading(false)
              })
          }}
          showFile={(row: any) => {
            setLoading(true)
            getAllMetadata(row.values.id)
            setSelectedTrailer(row.values)
            setShowFilePopup(true)
          }}
          deleteRow={(row: any) => {
            setSelectedTrailer(row.values)
            setShowDeletePopup(true)
          }}
          deleteText={intl.formatMessage({id: 'Carrier.trailer.tabel.deleteText'})}
          editableRows={['']}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {/*DELETE POPUP*/}
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Carrier.trailer.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.trailer.delete.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      deleteTrailer(selectedTrailer.id)
                    }}
                  >
                    {intl.formatMessage({id: 'Carrier.trailer.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/*CREATE POPUP*/}
      {showCreatePopup && (
        <CreateTrailer
          specificationList={specificationList}
          trailerTypes={trailerTypes}
          floorTypes={floorTypes}
          createTrailer={(val: any) => {
            setLoading(true)
            createTrailer(val)
          }}
          getSpecifications={(val: any) => {
            getSpecifications(val)
          }}
          onClose={() => {
            setShowCreatePopup(false)
            setSpecificationList([])
          }}
        />
      )}

      {/*UPDATE POPUP*/}
      {showUpdatePopup && (
        <EditPreferences
          selectedSpecs={specUpdatedSelected}
          selectedFeatures={updatedTrailer}
          floorTypes={floorTypes}
          specificationList={specificationList}
          trailerTypes={trailerTypes}
          updateTrailer={(val: any) => {
            setLoading(true)
            updateTrailer(val.trailer, val.selected)
          }}
          getSpecifications={(val: any) => {
            setLoading(true)
            getSpecifications(val)
          }}
          onClose={() => {
            setShowUpdatePopup(false)
            setSpecificationList([])
          }}
        />
      )}

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <TrailerDetails
          floorTypes={floorTypes}
          trailerTypes={trailerTypes}
          trailerMetadata={trailerMetadata}
          selectedFeatures={updatedTrailer}
          selectedSpecs={specUpdatedSelected}
          specificationList={specificationList}
          downloadFiles={(val: any) => {
            setLoading(true)
            downloadFiles(val.url, val.code, val.name)
          }}
          getSpecifications={(val: any) => {
            setLoading(true)
            getSpecifications(val)
          }}
          onClose={() => setShowDetailPopup(false)}
        />
      )}

      {/*FILE POPUP*/}
      {showFilePopup && (
        <UploadFiles
          selectedTrailer={selectedTrailer}
          trailerMetadata={trailerMetadata}
          uploadTrailerPlate={(val: any) => {
            setLoading(true)
            uploadTrailerPlate(val.id, val.file)
          }}
          onClose={() => setShowFilePopup(false)}
        />
      )}
    </>
  )
}
export {Trailers}
