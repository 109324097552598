/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import {geocodeByPlaceId} from 'react-google-places-autocomplete'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import Map from '../../../Components/GoogleMap/Map'
import Dropbox from '../../../Components/Dropbox'
import {loadMapApi} from '../../../Components/GoogleMap/utils/GoogleMapsUtils'
import MapAutoCompleteSelect from '../../../Components/MapAutoCompleteSelect'
import RightLayoutPopup from '../../../Components/RightLayoutPopup'

import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import './styles.scss'

const CreateNewAddress = ({
  popupMode,
  popupModeOnCancel,
  popupModeOnSubmit,
}: {
  popupMode: boolean
  popupModeOnCancel: any
  popupModeOnSubmit: any
}) => {
  const intl = useIntl()
  const history = useHistory()
  const commonServ = new CommonService()
  const addressServ = new AddressService()
  const isEmpty = (obj: any) => !Object.keys(obj).length

  const [loading, setLoading] = useState<boolean>(false)

  const [searchLong, setSearchLong] = useState<any>(null)
  const [searchLat, setSearchLat] = useState<any>(null)
  const [searchLabel, setSearchLabel] = useState<any>(null)

  /*List States*/
  const [countryList, setCountryList] = useState<any>([])
  const [cityList, setCityList] = useState([])

  const initialValues = {
    title: '',
    country: '',
    city: null,
    district: '',
    neighborhood: '',
    postCode: '',
    addressText: '',
    street: '',
    streetNumber: '',
    latitude: '',
    longitude: '',
  }
  const registrationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({id: 'addNewAddressPopup.area1.title'})),
    country: Yup.string().required(intl.formatMessage({id: 'addNewAddressPopup.area1.country'})),
    city: Yup.string()
      .nullable()
      .required(intl.formatMessage({id: 'addNewAddressPopup.area1.city'})),
    district: Yup.string(),
    neighborhood: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.string(),
    postCode: Yup.string(),
    // .required(intl.formatMessage({id: 'addNewAddressPopup.area1.postCode'})),
    addressText: Yup.string(),
    // .max(500, intl.formatMessage({id: 'addNewAddressPopup.area1.addressText.max'}))
    // .required(intl.formatMessage({id: 'addNewAddressPopup.area1.addressText'})),
    latitude: Yup.string(),
    longitude: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      createNewAddress(
        formik.values.title,
        formik.values.country,
        formik.values.city ?? -1,
        formik.values.district,
        formik.values.neighborhood,
        formik.values.street,
        formik.values.streetNumber,
        formik.values.postCode,
        formik.values.addressText,
        formik.values.latitude,
        formik.values.longitude
      )
    },
  })

  const inputActions = (action: 'clear' | 'fill', data?: any) => {
    if (action === 'clear') {
      formik.setFieldValue('title', '')
      formik.setFieldValue('postCode', '')
      formik.setFieldValue('district', '')
      formik.setFieldValue('neighborhood', '')
      formik.setFieldValue('street', '')
      formik.setFieldValue('streetNumber', '')
      formik.setFieldValue('country', '')
      formik.setFieldValue('addressText', '')
      formik.setFieldValue('city', null)
      formik.setFieldValue('latitude', '')
      formik.setFieldValue('longitude', '')
    } else if (data) {
      data?.title && formik.setFieldValue('title', data?.title)
      data?.postCode && formik.setFieldValue('postCode', data?.postCode)
      data?.district && formik.setFieldValue('district', data?.district)
      data?.neighborhood && formik.setFieldValue('neighborhood', data?.neighborhood)
      data?.street && formik.setFieldValue('street', data?.street)
      data?.streetNumber && formik.setFieldValue('streetNumber', data?.streetNumber)
      data?.country && formik.setFieldValue('country', data?.country)
      data?.addressText && formik.setFieldValue('addressText', data?.addressText)
      data?.city && formik.setFieldValue('city', data?.city)
      data?.latitude && formik.setFieldValue('latitude', data?.latitude)
      data?.longitude && formik.setFieldValue('longitude', data?.longitude)
    }
  }

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp) => {
        setCountryList(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  const getCityList = (countryCode: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp) => {
        setCityList(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  /** Country and City List api call End **/

  /** Create New Address api call Start **/
  const createNewAddress = (
    desc: string,
    countryCode: string,
    cityId: number,
    district: string,
    neighborhood: string,
    street: string,
    streetNumber: string,
    postCode: string,
    addressText: string,
    latitude: string,
    longitude: string
  ) => {
    addressServ
      .createAddress(
        desc,
        countryCode,
        cityId,
        district,
        neighborhood,
        street,
        streetNumber,
        postCode,
        addressText,
        latitude,
        longitude
      )
      .then(() => {
        popupModeOnSubmit && popupModeOnSubmit()
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Address.create.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
        !popupMode && history.push('/fr-op/addresses')
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  /** Create New Address api call End **/

  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [distanceInKm, setDistanceInKm] = useState<number>(-1)

  const renderDistanceSentence = () => {
    return (
      <div className='distance-info'>
        {intl.formatMessage({id: 'Admin.trackOnMap.renderDistance'}, {value: distanceInKm})}
      </div>
    )
  }

  const searchSelectedControl = async (addressData: any) => {
    !isEmpty(addressData) &&
      inputActions('fill', {
        district: addressData?.district,
        neighborhood: addressData?.neighborhood,
        addressText: addressData?.addressText,
        country: addressData?.country,
        postCode: addressData?.postCode,
        street: addressData?.street,
        streetNumber: addressData?.streetNumber,
        latitude: addressData?.latitude,
        longitude: addressData?.longitude,
      })
    const resp = await commonServ.cityList(addressData?.country)
    await setCityList(resp.data)
    await setLoading(false)
    let eq = resp?.data?.find(
      (item: any) => addressData?.city?.toLowerCase() === item?.originalName?.toLowerCase()
    )
    await formik.setFieldValue('city', eq ? eq?.cityId : null)
  }

  useEffect(() => {
    formik.values.country &&
      setTimeout(() => !formik.values.city && getCityList(formik.values.country), 200)
  }, [formik.values.country])

  useEffect(() => {
    setLoading(true)
    getCountryList()

    scriptLoaded && window.location.reload()

    const googleMapScript = loadMapApi()
    googleMapScript.addEventListener('load', () => setScriptLoaded(true))
  }, [])

  return (
    <>
      <RightLayoutPopup
        className='create-new-address-wrap'
        isCloseIcon
        closeOnClick={() => popupModeOnCancel()}
      >
        {loading && <Loading />}
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'Freight.addresses.create.addAddress'})}
        </PageTitle>
        <div>
          {/* <div className={'mb-5 bg-secondary border border-1 rounded p-3 col-4'}>
                    <div className="form-check form-switch ">
                        <input className="form-check-input mt-1 ml-3"
                               type="checkbox"
                               role="switch"
                               id="visionSwitch"
                               onChange={() => {
                                   inputActions("clear");
                                   setVision(!vision);
                               }}
                        />
                        <label className="form-check-label fs-2 " htmlFor="visionSwitch">
                            {vision ? intl.formatMessage({id: 'Freight.addresses.create.mapView'}) : intl.formatMessage({id: 'Freight.addresses.create.searchView'})}
                        </label>
                    </div>
                </div> */}

          {/*Search Input*/}
          <div className={'mb-5 mt-5'}>
            <label className='class="form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.addresses.create.searchAddress'})}
            </label>
            <MapAutoCompleteSelect
              selectedLoc={async (item: any) => {
                let locationData = item?.value?.terms?.sort((a: any, b: any) => b.offset - a.offset)

                let con: any = countryList?.find(
                  (item: any) => locationData[0]?.value === item.name
                )
                const [place] = await geocodeByPlaceId(item?.value?.place_id)
                let addressData = {
                  country: con?.code,
                  city: item?.value?.terms[1]?.value,
                  district: item?.value?.terms[3]?.value,
                  neighborhood: item?.value?.terms[2]?.value,
                  addressText: item?.value?.description,
                }
                setSearchLong(place.geometry.location.lng())
                setSearchLat(place.geometry.location.lat())
                setSearchLabel(item?.label)
                searchSelectedControl(addressData)
              }}
            />
          </div>

          {/**Map**/}
          <div className='map-container'>
            <Map
              mapType={google.maps.MapTypeId.ROADMAP}
              mapTypeControl={true}
              setDistanceInKm={setDistanceInKm}
              location={(item: any) => {
                // const address_text_index = item?.allData[0]?.address_components.findIndex((item: any) => item.types.includes('administrative_area_level_2'));

                // const address_text_data = item?.allData[0]?.address_components.map((item: any, index: any) => {
                //     if(index < address_text_index) {
                //         return item?.short_name
                //     }
                // }).filter((itemData: any) => itemData).reverse();
                let addressData = {
                  country: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('country')
                  )?.short_name,
                  city:
                    item?.allData[0]?.address_components.find((item: any) =>
                      item?.types?.includes('locality')
                    )?.long_name ||
                    item?.allData[0]?.address_components.find((item: any) =>
                      item?.types?.includes('administrative_area_level_1')
                    )?.short_name,
                  postCode: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('postal_code')
                  )?.short_name,
                  district: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('administrative_area_level_2')
                  )?.short_name,
                  neighborhood: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('administrative_area_level_4')
                  )?.short_name,
                  street: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('route')
                  )?.short_name,
                  streetNumber: item?.allData[0]?.address_components.find((item: any) =>
                    item?.types?.includes('street_number')
                  )?.short_name,
                  addressText: item?.allData[0]?.formatted_address,
                  latitude: item?.allData[0]?.geometry?.location?.lat(),
                  longitude: item?.allData[0]?.geometry?.location?.lng(),
                }
                searchSelectedControl(addressData)
              }}
              searchLabel={searchLabel}
              searchLat={searchLat}
              searchLong={searchLong}
            />

            {/** Form **/}
            <div className={'col-md-4 form-display-wrap'}>
              <div className={'row'}>
                {/* begin::Form group Title */}
                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.title'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.title.placeholder',
                    })}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('title')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.title && formik.errors.title,
                      },
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.title}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                {/* begin::Form group Country */}
                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                  </label>
                  <select
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.country.placeholder',
                    })}
                    {...formik.getFieldProps('country')}
                    className={'form-control form-control-lg'}
                  >
                    <option value='' selected disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                    </option>
                    {countryList?.map((item: any, key: number) => (
                      <option key={key} /*selected={country === item?.code}*/ value={item?.code}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.country}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group City */}
                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                  </label>
                  <select
                    disabled={!formik.values.country}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.city.placeholder',
                    })}
                    {...formik.getFieldProps('city')}
                    className={'form-control form-control-lg'}
                  >
                    <option value='' selected={!formik.values.city} disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                    </option>
                    {formik.values.country &&
                      cityList?.map((item: any) => (
                        <option key={item.cityId} value={item?.cityId}>
                          {item?.originalName}
                        </option>
                      ))}
                  </select>
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.city}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>

              {/* begin::Form group Street */}
              <div className='col-md-12 mb-5'>
                <label className='class="form-label fw-bolder text-dark fs-6'>
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                </label>
                <input
                  placeholder={intl.formatMessage({
                    id: 'addNewAddressPopup.area2.district1.placeholder',
                  })}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('district')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid bg-white',
                    {
                      'is-invalid': formik.touched.district && formik.errors.district,
                    },
                    {
                      'is-valid': formik.touched.district && !formik.errors.district,
                    }
                  )}
                />
                {formik.touched.district && formik.errors.district && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className={'text-danger'} role='alert'>
                        {formik.errors.district}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              <div className={'row'}>
                {/* begin::Form group neighborhood */}
                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                    })}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('neighborhood')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.neighborhood && formik.errors.neighborhood,
                      },
                      {
                        'is-valid': formik.touched.neighborhood && !formik.errors.neighborhood,
                      }
                    )}
                  />
                  {formik.touched.neighborhood && formik.errors.neighborhood && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.neighborhood}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group PostCode */}
                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                    })}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('postCode')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.postCode && formik.errors.postCode,
                      },
                      {
                        'is-valid': formik.touched.postCode && !formik.errors.postCode,
                      }
                    )}
                  />
                  {formik.touched.postCode && formik.errors.postCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.postCode}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.street.placeholder',
                    })}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('street')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.street && formik.errors.street,
                      },
                      {
                        'is-valid': formik.touched.street && !formik.errors.street,
                      }
                    )}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.street}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-md-12 mb-5'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                    })}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('streetNumber')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber,
                      },
                      {
                        'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                      }
                    )}
                  />
                  {formik.touched.streetNumber && formik.errors.streetNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.streetNumber}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={'row'}>
                {/* begin::Form group Address */}
                <div>
                  <label className='class="form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.address1'})}
                  </label>
                  <textarea
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.address1.placeholder',
                    })}
                    autoComplete='off'
                    {...formik.getFieldProps('addressText')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid bg-white',
                      {
                        'is-invalid': formik.touched.addressText && formik.errors.addressText,
                      },
                      {
                        'is-valid': formik.touched.addressText && !formik.errors.addressText,
                      }
                    )}
                  />
                  {formik.touched.addressText && formik.errors.addressText && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.addressText}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
            {distanceInKm > -1 && renderDistanceSentence()}
          </div>

          <hr />

          {/*Add adress button area*/}
          {popupMode ? (
            <div className='text-center mt-5 row'>
              <div className={'col-md-6'}>
                <button
                  onClick={() => popupModeOnCancel()}
                  type='button'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-danger w-100 mb-5'
                >
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.cancel'})}
                </button>
              </div>
              <div className={'col-md-6'}>
                <button
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                >
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.approve'})}
                </button>
              </div>
            </div>
          ) : (
            <div className='text-center mt-5'>
              <button
                onClick={() => formik.handleSubmit()}
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {intl.formatMessage({id: 'addNewAddressPopup.area2.approve'})}
              </button>
            </div>
          )}
        </div>
      </RightLayoutPopup>
    </>
  )
}

export {CreateNewAddress}
