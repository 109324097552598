/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {shallowEqual, useSelector} from 'react-redux'

import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Modal'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {RootState} from '../../../../setup'

import useScrollLock from '../../../../utils/useScrollLock'

import {UserManagementService} from '../../../../services/FreightOperatorServices/UserManagementService'

const Users = () => {
  const intl = useIntl()
  /*   const commonServ = new CommonService();*/
  const history = useHistory()
  const role: any = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const [loading, setLoading] = useState<boolean>(false)
  const userServ = new UserManagementService()

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [deletingUserId, setDeletingUserId] = useState<number>(-1)
  /* const [countryList, setCountryList] = useState<any>([]);*/
  const [userList, setUserList] = useState<any>([])
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [isNextPage, setIsNextPage] = useState<boolean>(false)

  /** Api Calls Start**/
  const getUsers = (pageIndex?: number) => {
    userServ
      .getUserList(pageIndex)
      .then((item: any) => {
        setUserList(item?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const updateUser = (data: any) => {
    userServ
      .updateUser(data.userId, data.name, data.surname, data.email, data.countryCode, data.mobile)
      .then((item: any) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Freight.User.update.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const deleteUser = (userId: number) => {
    userServ
      .deleteUser(userId)
      .then((item: any) => {
        setLoading(false)
        getUsers(pageMIndex)
        toast.success(intl.formatMessage({id: 'Freight.User.delete.success'}), {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  /*  const getCountryList = () => {
      commonServ.countryList().then((resp) => {
          setCountryList(resp?.data);
          setLoading(false);
      })
  }*/

  /*    const getCountryName = (ctCode: any) => {
        let data = countryList?.find((coun: any) => ctCode === coun?.code);
        return data?.name;
    }*/
  /** Api Calls End**/

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    setUserList((old: any) =>
      old.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const {scrollUnlock} = useScrollLock()

  useEffect(() => {
    setLoading(true)
    getUsers(pageMIndex)
    setLoading(true)
    /*getCountryList();*/
  }, [])

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getUsers(page)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Freight.users.title'})}</PageTitle>
      <div>
        <div className={'d-flex justify-content-end'}>
          <button
            onClick={() => {
              if (role.includes('CARRIER_OPERATOR')) {
                history.push('/carrier/create-new-user')
              } else if (role.includes('FORWARDER_OPERATOR')) {
                history.push('/forwarder/create-new-user')
              } else {
                history.push('/fr-op/create-new-user')
              }
            }}
            className='btn btn-sm btn-light-primary mb-5 border border-2 fs-6'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({id: 'Freight.users.createNewUser'})}
          </button>
        </div>
        <div className={'mb-10'}>
          {!userList.length && (
            <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
              {intl.formatMessage({id: 'Freight.users.noData'})}
            </div>
          )}
          <DataTableAdvanced
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
              },
              {
                Header: intl.formatMessage({id: 'Freight.users.name'}),
                accessor: 'name',
              },

              {
                Header: intl.formatMessage({id: 'Freight.users.surName'}),
                accessor: 'surname',
              },

              {
                Header: intl.formatMessage({id: 'Freight.users.userName'}),
                accessor: 'username',
              },

              {
                Header: intl.formatMessage({id: 'Freight.users.email'}),
                accessor: 'email',
              },

              {
                Header: intl.formatMessage({id: 'Freight.users.countryCode'}),
                accessor: 'countryCode',
              },
              {
                Header: intl.formatMessage({id: 'Freight.users.phone'}),
                accessor: 'mobile',
              },
              {
                Header: intl.formatMessage({id: 'Freight.users.defaultPass'}),
                accessor: 'initialPassword',
              },
            ]}
            editableRows={['name', 'surname', 'email', 'countryCode', 'mobile']}
            data={
              userList &&
              userList?.map((item: any) => {
                return {
                  id: item?.userId ?? '',
                  name: item?.name ?? '',
                  surname: item?.surname ?? '',
                  username: item?.username ?? '',
                  email: item?.email ?? '',
                  countryCode: item?.countryCode,
                  mobile: item?.mobile ?? '',
                  initialPassword: item?.initialPassword ?? '',
                }
              })
            }
            updateMyData={updateMyData}
            updateRow={(row: any) => {
              setLoading(true)
              updateUser(row.values)
            }}
            skipPageReset={() => {}}
            deleteRow={(row: any) => {
              setDeletingUserId(row.values.id)
              setShowDeletePopup(true)
            }}
            deleteText={intl.formatMessage({id: 'Freight.users.tabel.deleteText'})}
            setPageMIndex={onPaginationChange}
            pageMIndex={pageMIndex}
          />
        </div>
      </div>
      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Freight.users.delete.title'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                      scrollUnlock()
                    }}
                  >
                    {intl.formatMessage({id: 'Freight.users.delete.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    setShowDeletePopup(false)
                    deleteUser(deletingUserId)
                    setLoading(true)
                    scrollUnlock()
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'Freight.users.delete.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export {Users}
