import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import TabManagerListingComponent from '../../../../Components/TabManagerListing/TabManagerListingComponent'
import Dropbox from '../../../../Components/Dropbox'
import PhoneInp from '../../../../Components/PhoneInp/PhoneInp'
import {KTSVG} from '../../../../../_metronic/helpers'
import {apiUrl} from '../../../../../services/conf'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'
import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

import {exceptThisSymbols} from '../../../../../utils/common'

const CreateTrailer = ({onClose, setLoading}: any) => {
  const intl = useIntl()
  const listingServ = new ListingService()
  const commonServ = new CommonService()

  const [listingId, setListingId] = useState<any>(null)
  const [showImageInPopup, setShowImageInPopup] = useState<any>(false)

  const [showFileName, setShowFileName] = useState<any>(null)

  const [registrationYear, setregistrationYear] = useState<any>(null)
  const [gwr, setGwr] = useState<any>(null)
  const [payload, setPayload] = useState<any>(null)
  const [model, setModel] = useState<any>('')

  const [brandList, setBrandList] = useState<any>([])
  const [selectedBrand, setSelectedBrand] = useState<any>([])

  const [currencyList, setCurrencyList] = useState<any>([])
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('')

  const [price, setPrice] = useState<any>(null)

  const [description, setDescription] = useState<any>('')

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [fromCityList, setFromCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)

  const [selectedListImageList, setSelectedListImageList] = useState<any>([])

  const [isValidation, setIsValidation] = useState<boolean>(false)

  const [uploadPicList, setUploadPicList] = useState<any>([])

  const [dynamicFormField, setDynamicFormField] = useState<any>([
    {
      id: Math.random().toString(16).slice(-4),
      name: '',
      phoneCode: '+90',
      mobile: '',
      email: '',
      web: '',
    },
  ])

  const [formErrors, setFormErrors] = useState<any>([
    {name: false, phoneCode: false, mobile: false},
  ])

  const disabledFirstStep = () =>
    !registrationYear ||
    !gwr ||
    !payload ||
    !model ||
    !selectedBrand ||
    !price ||
    !selectedCurrencyId ||
    !fromCountryId ||
    fromCountryId === 'all' ||
    !fromCityId ||
    fromCityId === 'all'

  const getBrandList = () => {
    listingServ
      .getBrandList()
      .then((resp: any) => {
        setBrandList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCurrencyList = () => {
    listingServ
      .getCurrencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0) setFromCountryList(resp?.data?.filter((item: any) => item?.name))
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCityList(resp?.data?.filter((item: any) => item?.name))
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    getBrandList()
    getCurrencyList()
    getFromCountryList()
  }, [])

  const deleteFile = (itemId: any) => {
    listingServ
      .deleteFile(itemId)
      .then((res: any) => {
        setLoading(false)
        getListingFile(listingId)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getListingFile = (itemId: any) => {
    listingServ
      .getListingFileList(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListImageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const uploadFile = async (data: any) => {
    await listingServ
      .uploadListingFile(data)
      .then((resp: any) => {
        setLoading(false)
        getListingFile(listingId)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const uploadAllListingFile = async (data: any) => {
    const promises = await data?.file?.map((item: any) =>
      uploadFile({listingId: data?.listingId, file: item})
    )
    await Promise.all(promises).then(() => {
      toast.success(intl.formatMessage({id: 'freight.myListing.truck.picture.create.success'}))
      setUploadPicList([])
    })
  }

  const uploadMultiplFile = (e: any) => {
    setLoading(true)
    setUploadPicList([...uploadPicList, ...e.target.files])
    uploadAllListingFile({listingId: listingId, file: [...uploadPicList, ...e.target.files]})
  }

  const createContact = async (data: any) => {
    await listingServ
      .createContactListing(data)
      .then((resp: any) => {
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    const createAllData = async () => {
      if (isValidation) {
        if (!formErrors.some((item: any) => item?.name || item.phoneCode || item?.mobile)) {
          setLoading(true)
          const promises = await dynamicFormField.map(async (item: any) =>
            createContact({
              email: item?.email,
              countryCode: item?.phoneCode,
              phone: item?.mobile,
              name: item?.name,
              web: item?.web,
              listing: {
                listingId: listingId,
              },
            })
          )

          await Promise.all(promises).then(() => {
            setListingId(null)
            toast.success(
              intl.formatMessage({id: 'freight.myListing.truck.contact.create.success'})
            )
            onClose()
          })
        }
      }
    }
    createAllData()
  }, [formErrors, isValidation])

  const handleAddNewContactForm = () => {
    setDynamicFormField([
      ...dynamicFormField,
      {
        id: Math.random().toString(16).slice(-4),
        name: '',
        phoneCode: '+90',
        mobile: '',
        email: '',
        web: '',
      },
    ])
    setFormErrors([...formErrors, {name: false, phoneCode: false, mobile: false}])
  }

  const handleRemoveContact = (id: any, index: any) => {
    const filterDynamicFormField = dynamicFormField.filter((item: any) => item.id !== id)
    const filterErrors = formErrors.filter((item: any, errorIndex: any) => errorIndex !== index)

    setFormErrors([...filterErrors])
    setDynamicFormField([...filterDynamicFormField])
  }

  const firstStepContent = () => (
    <div className='row'>
      <div className={'col-md-4 mb-5'}>
        <label className={'form-label fw-bolder text-dark fs-6 mb-2'}>
          {intl.formatMessage({id: 'freight.myListing.truck.registrationYear'})}
        </label>
        <select
          id={'currency'}
          className='form-select'
          onChange={(event: any) => {
            setregistrationYear(event?.target?.value)
          }}
          value={registrationYear}
        >
          <option value='' selected disabled>
            {intl.formatMessage({id: 'freight.myListing.truck.year'})}
          </option>
          {Array.from(new Array(new Date().getFullYear() - 1969), (val, index) => 1970 + index).map(
            (item: any, key: number) => (
              <option key={`${key}${item}`} value={item}>
                {item}
              </option>
            )
          )}
        </select>
      </div>

      <div className='mb-5 col-md-4'>
        <label className='fs-4 text-muted'>
          {intl.formatMessage({id: 'freight.listing.model'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'freight.listing.model'})}
          className={'form-control form-control-lg form-control-solid bg-white'}
          onChange={(event: any) => {
            setModel(event.target.value)
          }}
          value={model}
          type='text'
          name='model'
          autoComplete='off'
        />
      </div>

      <div className='col-md-4 mb-5'>
        <label htmlFor='publishDate' className={'text-muted fs-4'}>
          {intl.formatMessage({id: 'freight.listing.brand'})}
        </label>

        <select
          id={'brand'}
          className='form-select'
          onChange={(event: any) => {
            setSelectedBrand(event?.target?.value)
          }}
          value={selectedBrand}
        >
          <option value='' selected disabled>
            {intl.formatMessage({id: 'freight.myListing.truck.select'})}
          </option>
          {brandList &&
            brandList?.map((item: any, key: number) => (
              <option key={`${key}${item?.brandId}`} value={item?.brandId}>
                {item?.name}
              </option>
            ))}
        </select>
      </div>

      <div className='col-md-4 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'freight.listing.price'})}
        </label>
        <input
          type='number'
          id={'price'}
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          placeholder={intl.formatMessage({id: 'freight.listing.price'})}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          min={0}
          className={clsx('form-control', {
            'price-input-wrap': price,
          })}
        />
      </div>

      <div className={'col-md-4 mb-5'}>
        <label className={'form-label text-muted  fs-4'}>
          {intl.formatMessage({id: 'Admin.Adverts.currency'})}
        </label>
        <Dropbox
          hideArrow
          id={'currency'}
          customClass={'form-select'}
          placeholder={intl.formatMessage({
            id: 'Admin.Adverts.currency',
          })}
          customSearchValue={''}
          customOnSearch={(e: any) => {}}
          disabledSearch
          onScroll={() => {}}
          setScrollCalled={() => {}}
          currentPageIndex={0}
          menuList={
            currencyList &&
            currencyList.map((item: any) => {
              return {
                id: item?.currencyId,
                title: item?.description,
              }
            })
          }
          selectedItemData={(dataItem: any) => {
            if (dataItem?.id) {
              setSelectedCurrencyId(Number(dataItem?.id))
            }
          }}
        />
      </div>

      <div className='col-md-4 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'freight.myListing.trailer.gwr'})}
        </label>
        <input
          type='number'
          id={'GWR'}
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          placeholder={intl.formatMessage({id: 'freight.myListing.trailer.gwr'})}
          value={gwr}
          onChange={(e) => setGwr(e.target.value)}
          min={0}
          className={clsx('form-control', {
            'price-input-wrap': gwr,
          })}
        />
      </div>

      <div className='col-md-4 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'freight.myListing.trailer.payload'})}
        </label>
        <input
          type='number'
          id={'Payload'}
          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          placeholder={intl.formatMessage({id: 'freight.myListing.trailer.payload'})}
          value={payload}
          onChange={(e) => setPayload(e.target.value)}
          min={0}
          className={clsx('form-control', {
            'price-input-wrap': payload,
          })}
        />
      </div>

      <div className='mb-5 col-md-4'>
        <label htmlFor='publishDate' className={'text-muted fs-4'}>
          {intl.formatMessage({id: 'freight.listing.country'})}
        </label>
        <div className={''}>
          <Dropbox
            key={selectedFromFilterCountry}
            hideArrow
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'freight.listing.country',
            })}
            customSearchValue={fromCountrySearch}
            customOnSearch={(e: any) => {
              setFromCountryPageIndex(0)
              setFromCountrySearch(e.target.value)
              getFromCountryList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setFromCountryPageIndex(val)
              getFromCountryList(val, fromCountrySearch)
            }}
            setScrollCalled={() => {
              setFromCountryScrollAPICalled(true)
            }}
            scrollAPIResponse={fromCountryScrollAPICalled}
            currentPageIndex={fromCountryPageIndex}
            menuList={
              fromCountryList &&
              fromCountryList
                ?.filter(function ({code}: any) {
                  // @ts-ignore
                  return !this[code] && (this[code] = code)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.code, title: item?.name}
                })
            }
            defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setFromCountryId(item?.id)
                setSelectedFromFilterCountry(item?.title)
                setDependentFromCity(true)
                setFromCityId('')
              }
            }}
          />
        </div>
      </div>

      <div className='mb-5 col-md-4'>
        <label htmlFor='publishDate' className={'text-muted fs-4'}>
          {intl.formatMessage({id: 'freight.listing.city'})}
        </label>
        <div className={''}>
          <Dropbox
            hideArrow
            disabled={!fromCountryId || fromCountryId === 'all'}
            customClass={'form-select'}
            placeholder={intl.formatMessage({id: 'freight.listing.city'})}
            customSearchValue={fromCitySearch}
            customOnSearch={(e: any) => {
              setFromCityPageIndex(0)
              setFromCitySearch(e.target.value)
              getFromSearchCityList(fromCountryId, 0, e.target.value)
            }}
            onScroll={(val: any) => {
              if (fromCountryId) {
                setFromCityPageIndex(val)
                getFromSearchCityList(fromCountryId, val, fromCountrySearch)
              }
            }}
            setScrollCalled={() => {
              setFromCityScrollAPICalled(true)
            }}
            scrollAPIResponse={fromCityScrollAPICalled}
            currentPageIndex={fromCityPageIndex}
            menuList={
              fromCityList &&
              fromCityList
                ?.filter(function ({cityId}: any) {
                  // @ts-ignore
                  return !this[cityId] && (this[cityId] = cityId)
                }, {})
                ?.map((item: any) => {
                  return {id: item?.cityId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            defaultSelected={{id: fromCityId}}
            dependentValue={dependentFromCity}
            selectedItemData={(item: any) => {
              if (item?.id) {
                setFromCityId(item?.id)
                setDependentFromCity(false)
              }
            }}
          />
        </div>
      </div>

      <div className='mb-5 col-md-12'>
        <label className='fs-4 text-muted'>
          {intl.formatMessage({id: 'freight.listing.description'})}
        </label>
        <input
          placeholder={'Description'}
          className={'form-control form-control-lg form-control-solid bg-white'}
          onChange={(event: any) => {
            setDescription(event.target.value)
          }}
          value={description}
          type='text'
          name='description'
          autoComplete='off'
        />
      </div>
    </div>
  )

  const secondStepContent = () => (
    <div className='row'>
      <div className='col-md-12 mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'freight.myListing.truck.uploadPic'})}
        </label>
        <div className='image-main-wrap'>
          <input
            style={{color: 'transparent'}}
            placeholder={intl.formatMessage({id: 'freight.myListing.truck.uploadPic'})}
            className={clsx('form-control form-control-lg')}
            onChange={uploadMultiplFile}
            type='file'
            name='Upload Picture'
            multiple
          />
          <div className='upload-pic-wrap'>
            <div>
              {selectedListImageList.length}{' '}
              {intl.formatMessage({id: 'freight.myListing.truck.selected'})}
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex' style={{flexWrap: 'nowrap', overflowX: 'auto'}}>
        {selectedListImageList &&
          selectedListImageList?.length > 0 &&
          selectedListImageList.map((item: any, index: any) => {
            return (
              <div
                key={item}
                className='col-md-2 mb-2'
                style={{
                  display: 'block',
                  border: '1px solid #eff2f5',
                  marginRight: '10px',
                  overflow: 'hidden',
                  height: '175px',
                  paddingRight: 0,
                  width: '100%',
                  maxWidth: '175px',
                }}
              >
                <div className='image-main-wrap' style={{height: '100%'}}>
                  <img
                    src={item?.url}
                    alt=''
                    style={{display: 'block', height: '100%', maxWidth: '175px', cursor: 'pointer'}}
                    onClick={() => {
                      setShowFileName(item?.fileName)
                      setShowImageInPopup(true)
                    }}
                    className='image-hover-wrap'
                  />
                  <div className='overlay-upload-close-icon'>
                    <div
                      onClick={() => {
                        setLoading(true)
                        deleteFile(item?.listingFileId)
                      }}
                    >
                      <i className='bi bi-x delete-icon-wrap'></i>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )

  const thirdStepContent = () => (
    <div>
      {dynamicFormField.map((item: any, index: any) => (
        <div>
          <span
            className='menu-icon d-flex justify-content-end'
            onClick={() => handleRemoveContact(item?.id, index)}
          >
            <div
              className={`btn btn-icon btn-custom delete-icon-wrap`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              style={{height: 'auto'}}
            >
              <KTSVG path='/media/icons/duotune/general/trash_icon.svg' className='svg-icon-1' />
            </div>
          </span>
          <div className='row mt-5' key={item + index}>
            <div className='mb-5 col-md-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'freight.myListing.truck.name'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'freight.myListing.truck.name'})}
                className={'form-control form-control-lg form-control-solid'}
                onChange={(event: any) => {
                  let data = [...dynamicFormField]
                  data[index]['name'] = event.target.value
                  setDynamicFormField(data)

                  let editFormErrors = [...formErrors]
                  editFormErrors[index]['name'] = false
                  setFormErrors(editFormErrors)
                  if (isValidation) setIsValidation(false)
                }}
                value={item[index]?.name}
                type='text'
                name='name'
                autoComplete='off'
              />

              {formErrors[index]?.name && (
                <div className='fv-plugins-message-container' key={item}>
                  <div className='fv-help-block'>
                    <span role='alert'>{intl.formatMessage({id: 'global.mandatory'})}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6 mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'freight.myListing.truck.email'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'freight.myListing.truck.email'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) => {
                  let data = [...dynamicFormField]
                  data[index]['email'] = event.target.value
                  setDynamicFormField(data)
                }}
                value={item[index]?.email}
                type='text'
                name='email'
                autoComplete='off'
              />
            </div>

            <div className={'col-md-3 mb-5'}>
              <label
                htmlFor={'phoneCode'}
                className='class="form-label fw-bolder text-dark fs-6 mb-2'
              >
                {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
              </label>
              <PhoneInp
                id={'phoneCode'}
                onSelect={(event: any) => {
                  let data = [...dynamicFormField]
                  data[index]['phoneCode'] = event.length > 0 ? '+' + event : event
                  setDynamicFormField(data)

                  let editFormErrors = [...formErrors]
                  editFormErrors[index]['phoneCode'] = false
                  setFormErrors(editFormErrors)
                  if (isValidation) setIsValidation(false)
                }}
              />

              {formErrors[index]?.phoneCode && (
                <div className='fv-plugins-message-container' key={item}>
                  <div className='fv-help-block'>
                    <span role='alert'>{intl.formatMessage({id: 'global.mandatory'})}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-5 mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
                maxLength={13}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) => {
                  if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                    let editFormErrors = [...formErrors]
                    editFormErrors[index]['mobile'] = false
                    setFormErrors(editFormErrors)
                    if (isValidation) setIsValidation(false)

                    let data = [...dynamicFormField]
                    data[index]['mobile'] = event.target.value
                    setDynamicFormField(data)
                  }
                }}
                value={item[index]?.mobile}
                type='number'
                autoComplete='off'
                required
              />

              {formErrors[index]?.mobile && (
                <div className='fv-plugins-message-container' key={item}>
                  <div className='fv-help-block'>
                    <span role='alert'>{intl.formatMessage({id: 'global.mandatory'})}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-12 mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {intl.formatMessage({id: 'CreateClient.create.web'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'CreateClient.create.web'})}
                className={clsx('form-control form-control-lg form-control-solid')}
                onChange={(event: any) => {
                  let data = [...dynamicFormField]
                  data[index]['web'] = event.target.value
                  setDynamicFormField(data)
                }}
                value={item[index]?.web}
                type='text'
                name='web'
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      ))}

      <div className='row d-flex justify-content-center mt-5'>
        <button
          className={'btn btn-success px-10 w-auto'}
          onClick={() => {
            handleAddNewContactForm()
          }}
        >
          {intl.formatMessage({id: 'freight.myListing.truck.addOther'})}
        </button>
      </div>
    </div>
  )

  const createTrailerListing = (data: any) => {
    listingServ
      .createTrailerListing(data)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'freight.myListing.trailer.create.success'}))
        setLoading(false)
        setListingId(resp?.data?.listing?.listingId)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  return (
    <RightLayoutPopup
      title={intl.formatMessage({id: 'freight.myListing.trailer.title'})}
      className='right-layout-show-wrap'
      isCloseIcon
      closeOnClick={() => onClose()}
    >
      <TabManagerListingComponent
        onSubmit={() => {
          const validateData = dynamicFormField
          let validationErrors = formErrors
          validateData.map((item: any, index: number) => {
            if (!item['name']) {
              validationErrors[index]['name'] = true
            }

            if (!item['mobile']) {
              validationErrors[index]['mobile'] = true
            }

            if (!item['phoneCode']) {
              validationErrors[index]['phoneCode'] = true
            }
          })
          const newData = [...dynamicFormField]
          setDynamicFormField(newData)
          setFormErrors(validationErrors)

          setIsValidation(true)
        }}
        showCancelButton
        handleCancel={() => {
          onClose()
          setListingId(null)
        }}
        checkValidation={(value: any) => {
          if (value === 0) {
            if (!disabledFirstStep()) {
              setLoading(true)
              createTrailerListing({
                listing: {
                  description: description ? description : null,
                  price: price ? Number(price) : null,
                  currency: {
                    currencyId: selectedCurrencyId,
                  },
                  brand: {
                    brandId: selectedBrand,
                  },
                  model: model,
                  country: {
                    code: fromCountryId,
                  },
                  city: {
                    cityId: fromCityId,
                  },
                  listingType: {
                    listingTypeId: 2,
                  },
                },
                gwr: gwr ? Number(gwr) : null,
                registrationYear: registrationYear ? Number(registrationYear) : null,
                payload: payload ? Number(payload) : null,
              })
            }
          }
        }}
        submitBtnText={intl.formatMessage({id: 'Carrier.drivers.create.create'})}
        defaultStep={0}
        data={[
          {
            disabled: disabledFirstStep(),
            content: firstStepContent(),
          },
          {
            disabled: !(selectedListImageList.length > 0),
            content: secondStepContent(),
            nextBtnTitle: intl.formatMessage({id: 'freight.myListing.truck.next'}),
          },
          {
            content: thirdStepContent(),
          },
        ]}
      />

      {showImageInPopup && (
        <div id='modal' className='modal-image'>
          <span
            id='modal-close'
            className='modal-close'
            onClick={() => {
              setShowImageInPopup(false)
              setShowFileName(null)
            }}
          >
            &times;
          </span>
          <img
            src={`${apiUrl}/listing-management/listings/files/name/${showFileName}?size=big`}
            id='modal-content'
            className='modal-content-image'
            style={{maxHeight: 'calc(100vh - 170px)'}}
          />
        </div>
      )}
    </RightLayoutPopup>
  )
}

export {CreateTrailer}
