/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {toAbsoluteUrl} from '../../../helpers'
import * as auth from '../../../../app/pages/auth/redux/AuthRedux'
import {RootState} from '../../../../setup'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

import {UserService} from '../../../../services/MainServices/UserService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

// const languages = [
//   {
//     lang: 'tr',
//     name: 'Türkçe',
//     flag: toAbsoluteUrl('/media/flags/turkey.svg'),
//   },
//   {
//     lang: 'en',
//     name: 'English',
//     flag: toAbsoluteUrl('/media/flags/united-states.svg'),
//   },
//   {
//     lang: 'ru',
//     name: 'Russian',
//     flag: toAbsoluteUrl('/media/flags/russia.svg'),
//   },
//   /*{
//       lang: 'ja',
//       name: 'Japanese',
//       flag: toAbsoluteUrl('/media/flags/japan.svg'),
//     },
//     {
//       lang: 'de',
//       name: 'German',
//       flag: toAbsoluteUrl('/media/flags/germany.svg'),
//     },
//     {
//       lang: 'fr',
//       name: 'French',
//       flag: toAbsoluteUrl('/media/flags/france.svg'),
//     },*/
// ]

const flagList: any = {
  ['en']: toAbsoluteUrl('/media/flags/united-states.svg'),
  ['ru']: toAbsoluteUrl('/media/flags/russia.svg'),
  ['tr']: toAbsoluteUrl('/media/flags/turkey.svg'),
  ['de']: toAbsoluteUrl('/media/flags/germany.svg'),
  ['es']: toAbsoluteUrl('/media/flags/spain.svg'),
  ['zh']: toAbsoluteUrl('/media/flags/china.svg'),
  ['ja']: toAbsoluteUrl('/media/flags/japan.svg'),
  ['fr']: toAbsoluteUrl('/media/flags/france.svg'),
  ['bg']: toAbsoluteUrl('/media/flags/bulgaria.svg'),
  ['ro']: toAbsoluteUrl('/media/flags/romania.svg'),
  ['ka']: toAbsoluteUrl('/media/flags/georgia.svg'),
}

const Languages: FC = ({hideLangText}: any) => {
  const lang = useLang()
  const commonServ = new CommonService()
  const [languageList, setLanguageList] = useState<any>([])
  const currentLanguage = languageList.find((x: any) => x.code === lang)
  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  const dispatch = useDispatch()

  const intl = useIntl()

  const saveInfos = async (token: any, role: any) => {
    dispatch(auth.actions.login(token))
    return dispatch(auth.actions.role(role))
  }

  useEffect(() => {
    commonServ
      .languageList()
      .then((resp: any) => {
        setLanguageList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }, [])

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative' style={{position: 'relative'}}>
          {!hideLangText && intl.formatMessage({id: 'Header.menu.language'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={flagList[currentLanguage?.code]}
              alt={currentLanguage?.code}
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languageList.map((l: any) => (
          <div
            className='menu-item px-3'
            key={l.code}
            onClick={() => {
              const userServ = new UserService()
              userServ.updateLanguage(l.code).then(async (resp: any) => {
                await saveInfos(resp.data.token, role)
                setLanguage(l.code)
              })
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.code === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={flagList[l.code]} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
