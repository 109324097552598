import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {Link, useHistory} from 'react-router-dom'

import Seo from '../../../../utils/seo'
import Loading from '../../../Components/Loading'
import Dropbox from '../../../Components/Dropbox'
import {ListWidgetComponent} from '../../../Components/ListWidgetComponent/ListWidgetComponent'
import {TrailerFilter} from './Components/TrailerFilter'
import {TruckFilter} from './Components/TruckFilter'
import {TrailerDetails} from './Components/TrailerDetails'
import {TruckDetails} from './Components/TruckDetails'

import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

import {KTSVG} from '../../../../_metronic/helpers'

import './Listings.scss'

const vehicleBuySellDataStructured: any = {
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: 'Vehicle Buying & Selling',
  url: 'https://www.rivalog.com/public/vehicle-buying-selling',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const vehicleBuyingSellBreadCrumbStructured: any = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.rivalog.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Create Freight',
      item: 'https://www.rivalog.com/public-freight-create',
    },
  ],
}

const PublicVehicleBuyingSelling = () => {
  const intl = useIntl()
  const history = useHistory()

  const listingServ = new ListingService()

  const [loading, setLoading] = useState<boolean>(false)
  const [showTruckDetails, setShowTruckDetail] = useState<boolean>(false)
  const [showTrailerDetails, setShowTrailerDetail] = useState<boolean>(false)

  const [selectedListTruckDetails, setSelectedListTruckDetails] = useState<any>({})
  const [selectedListTrailerDetails, setSelectedListTrailerDetails] = useState<any>({})

  const [selectedListImageList, setSelectedListImageList] = useState<any>([])
  const [advertisementListData, setAdevrtisementListData] = useState<any>([])

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [listingTypeFilter, setListingTypeFilter] = useState<string>('all')

  const [listingData, setListingData] = useState<any>([])
  const [listingType, setListingType] = useState<any>([])

  const [listingTypeCode, setListingTypeCode] = useState<any>('')

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)

  const getListingTypesData = () => {
    listingServ
      .getPublicListingType()
      .then((resp: any) => {
        setListingType(
          [
            {
              listingTypeId: 'all',
              description: intl.formatMessage({id: 'Admin.Adverts.all'}),
            },
          ].concat(resp?.data)
        )
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getListingData = (data: any) => {
    listingServ
      .getPublicListings(data)
      .then((resp: any) => {
        setListingData(resp?.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTrailerDetails = (itemId: any) => {
    listingServ
      .getPublicTrailerDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTrailerDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTruckDetails = (itemId: any) => {
    listingServ
      .getPublicTruckDetails(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListTruckDetails(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getListingFile = (itemId: any) => {
    listingServ
      .getPublicListingFileList(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListImageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const advertisementList = () => {
    listingServ
      .getPublicAdvertisementDisplayList({advertisementTypeId: '3'})
      .then((resp: any) => {
        setLoading(false)
        setAdevrtisementListData(
          resp?.data?.length > 0
            ? resp?.data?.map((item: any, index: any) => ({...item, index: (index + 1) * 10}))
            : resp?.data
        )
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const onPaginationChange = (page: number) => {
    getListingData({pageIndex: page})
    setPageIndex(page)
  }

  useEffect(() => {
    setLoading(true)
    getListingData({pageIndex: pageIndex})
    getListingTypesData()
    advertisementList()
  }, [])

  return (
    <div className='public-vehicle-main-wrap'>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.publicVehicleBuySelling.title'}),
          metaDescription: intl.formatMessage({id: 'Seo.publicVehicleBuySelling.description'}),
          href: 'https://www.rivalog.com/public/vehicle-buying-selling',
        }}
      >
        <script type='application/ld+json'>{JSON.stringify(vehicleBuySellDataStructured)}</script>
        <script type='application/ld+json'>
          {JSON.stringify(vehicleBuyingSellBreadCrumbStructured)}
        </script>
      </Seo>
      <h1 style={{opacity: 0, position: 'absolute'}}>
        {intl.formatMessage({id: 'Seo.publicVehicleBuySelling.h1.tag'})}
      </h1>
      {loading && <Loading />}
      <div className='row col-md-2 justify-content-end w-100'>
        <Link
          to='/'
          className='link-primary fs-6 fw-bolder'
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {intl.formatMessage({id: 'freight.public.home'})}
        </Link>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
        <h1>{intl.formatMessage({id: 'freight.truckBuyingSelling.title'})}</h1>
      </div>

      <div className='row'>
        <div className='mb-10 col-md-2'>
          <h6 className={'mb-3'}>{intl.formatMessage({id: 'freight.listing.listingType'})}</h6>
          <div className={''}>
            <Dropbox
              hideArrow
              customClass={'form-select'}
              placeholder={intl.formatMessage({id: 'freight.listing.listingType'})}
              customSearchValue={''}
              customOnSearch={(e: any) => {}}
              disabledSearch
              onScroll={() => {}}
              setScrollCalled={() => {}}
              currentPageIndex={0}
              menuList={
                listingType &&
                listingType.map((item: any) => {
                  return {
                    id: item?.listingTypeId,
                    title: item?.description,
                    code: item?.code,
                  }
                })
              }
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setLoading(true)
                  setShowFilterPanel(false)
                  setListingTypeCode(item?.code ?? '')
                  setListingTypeFilter(item?.id)
                  getListingData({pageIndex: pageIndex, listingTypeId: item?.id})
                }
              }}
            />
          </div>
        </div>

        <div className='col-md-8' />

        {listingTypeCode && (
          <div className={'col-md-2 d-flex mb-10 align-items-center justify-content-end'}>
            <span className='menu-icon d-flex justify-content-end'>
              <div
                className={`btn btn-icon btn-active-light-primary btn-custom ${
                  showFilterPanel ? 'menu-button-active' : ''
                }`}
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => setShowFilterPanel(!showFilterPanel)}
              >
                <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
              </div>
            </span>
          </div>
        )}
      </div>

      {listingTypeCode === 'TRAILER' && showFilterPanel && (
        <TrailerFilter
          hadleTrailerFilterData={(data: any) =>
            getListingData({...data, listingTypeId: listingTypeFilter})
          }
        />
      )}

      {listingTypeCode === 'TRUCK' && showFilterPanel && (
        <TruckFilter
          hadleTruckFilterData={(data: any) =>
            getListingData({...data, listingTypeId: listingTypeFilter})
          }
        />
      )}

      <ListWidgetComponent
        data={listingData}
        advertisementListData={advertisementListData}
        setPageMIndex={onPaginationChange}
        pageMIndex={pageIndex}
        handleShowButton={(type: any, id: any) => {
          if (type === 'TRUCK') {
            setLoading(true)
            setShowTruckDetail(true)
            getTruckDetails(id)
            getListingFile(id)
            // getListingContactDetails(id)
          }

          if (type === 'TRAILER') {
            setLoading(true)
            setShowTrailerDetail(true)
            getTrailerDetails(id)
            getListingFile(id)
            // getListingContactDetails(id)
          }
        }}
      />

      {showTrailerDetails && (
        <TrailerDetails
          trailerDetails={selectedListTrailerDetails}
          onClose={() => setShowTrailerDetail(false)}
          selectedListImageList={selectedListImageList}
          // contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}

      {showTruckDetails && (
        <TruckDetails
          truckDetails={selectedListTruckDetails}
          onClose={() => setShowTruckDetail(false)}
          selectedListImageList={selectedListImageList}
          // contactList={selectedContactList}
          setLoading={setLoading}
        />
      )}
    </div>
  )
}

export {PublicVehicleBuyingSelling}
