import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import MultiRangeSlider from 'multi-range-slider-react'
import Multiselect from 'multiselect-react-dropdown'
import _ from 'lodash'

import Dropbox from '../../../../Components/Dropbox'

import {ListingService} from '../../../../../services/FreightOperatorServices/ListingService'
import {CommonService} from '../../../../../services/FreightOperatorServices/CommonService'

const TruckFilter = ({hadleTruckFilterData}: any) => {
  const intl = useIntl()
  const listingServ = new ListingService()
  const commonServ = new CommonService()

  const [description, setDescription] = useState<any>('')
  const [actualDescription, setActualDescription] = useState<any>('')
  const [model, setModel] = useState<any>('')

  const [minYearValue, set_minYearValue] = useState(1980)
  const [maxYearValue, set_maxYearValue] = useState(new Date().getFullYear() - 30)
  const [currencyList, setCurrencyList] = useState<any>([])
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('')

  const [brandList, setBrandList] = useState<any>([])
  const [selectedBrandList, setSelectedBrandList] = useState<any>([])

  const [cabinTypeList, setCabinTypeList] = useState<any>([])
  const [selectedCabinTypeList, setSelectedCabinTypeList] = useState<any>([])

  const [axleTypeList, setAxleTypeList] = useState<any>([])
  const [selectedAxleTypeList, setSelectedAxleTypeList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [fromCityList, setFromCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)

  const [minPrice, setMinPrice] = useState<number>(225)
  const [maxPrice, setMaxPrice] = useState<number>(725)

  const [minPowerOHP, setMinPowerOHP] = useState<number>(225)
  const [maxPowerOHP, setMaxPowerOHP] = useState<number>(725)

  const [minPowerOKWH, setMinPowerOKWH] = useState<number>(225)
  const [maxPowerOKWH, setMaxPowerOKWH] = useState<number>(725)

  const [minMileage, setMinMileage] = useState<number>(225)
  const [maxMileage, setMaxMileage] = useState<number>(725)

  const handlePriceInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinPrice(e.minValue)
        setMaxPrice(e.maxValue)
      }, 1000),
    []
  )

  const handlePowerOHPInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinPowerOHP(e.minValue)
        setMaxPowerOHP(e.maxValue)
      }, 1000),
    []
  )

  const handlePowerOKWHInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinPowerOKWH(e.minValue)
        setMaxPowerOKWH(e.maxValue)
      }, 1000),
    []
  )

  const handleMileageInput = React.useMemo(
    () =>
      _.debounce((e) => {
        setMinMileage(e.minValue)
        setMaxMileage(e.maxValue)
      }, 1000),
    []
  )

  const handleYearInput = React.useMemo(
    () =>
      _.debounce((e) => {
        set_minYearValue(e.minValue)
        set_maxYearValue(e.maxValue)
      }, 1000),
    []
  )

  const getCurrencyList = () => {
    listingServ
      .getPublicCurrencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getBrandList = () => {
    listingServ
      .getPublicBrandList()
      .then((resp: any) => {
        setBrandList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCabinTypeList = () => {
    listingServ
      .getPublicCabinTypeList()
      .then((resp: any) => {
        setCabinTypeList(resp?.data.map((item: any) => ({...item, name: item.description})))
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getAxleTypeList = () => {
    listingServ
      .getPublicAxleTypeList()
      .then((resp: any) => {
        setAxleTypeList(resp?.data.map((item: any) => ({...item, name: item.description})))
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .publicCountrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .publicCitySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleDescriptionSearch = React.useMemo(
    () =>
      _.debounce((value) => {
        setActualDescription(value)
      }, 1000),
    []
  )

  useEffect(() => {
    getCurrencyList()
    getBrandList()
    getFromCountryList()
    getCabinTypeList()
    getAxleTypeList()
  }, [])

  useEffect(() => {
    hadleTruckFilterData({
      description: description,
      startPrice: minPrice,
      endPrice: maxPrice,
      startPowrOutputHp: minPowerOHP,
      endPowerOutputHp: maxPowerOHP,
      startPowerOutputKwh: minPowerOKWH,
      endPowerOutputKwh: maxPowerOKWH,
      startMileage: minMileage,
      endMileage: maxMileage,
      model: model,
      currencyId: selectedCurrencyId,
      country: fromCountryId === 'all' ? '' : fromCountryId,
      cityId: fromCityId === 'all' ? '' : fromCityId,
      startRegistrationYear: minYearValue,
      endRegistrationYear: maxYearValue,
      brandIdList: selectedBrandList.map((item: any) => item?.brandId).toString(),
      axleTypeIdList: selectedAxleTypeList.map((item: any) => item?.axleTypeId).toString(),
      cabinTypeIdList: selectedCabinTypeList.map((item: any) => item?.cabinTypeId).toString(),
    })
  }, [
    actualDescription,
    minPrice,
    maxPrice,
    minPowerOHP,
    maxPowerOHP,
    minPowerOKWH,
    maxPowerOKWH,
    minMileage,
    maxMileage,
    model,
    selectedCurrencyId,
    fromCountryId,
    fromCityId,
    minYearValue,
    maxYearValue,
    selectedBrandList,
    selectedAxleTypeList,
    selectedCabinTypeList,
  ])

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  return (
    <>
      <div className='row'>
        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.description'})}
          </label>
          <input
            placeholder={'Description'}
            className={'form-control form-control-lg form-control-solid bg-white'}
            onChange={(event: any) => {
              setDescription(event.target.value)
              handleDescriptionSearch(event.target.value)
            }}
            value={description}
            type='text'
            name='description'
            autoComplete='off'
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.price'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minPrice}
            maxValue={maxPrice}
            onInput={(e) => {
              handlePriceInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.powerOHp'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minPowerOHP}
            maxValue={maxPowerOHP}
            onInput={(e) => {
              handlePowerOHPInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.powerOKWH'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minPowerOKWH}
            maxValue={maxPowerOKWH}
            onInput={(e) => {
              handlePowerOKWHInput(e)
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.mileage'})}
          </label>
          <MultiRangeSlider
            min={0}
            max={1000}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minMileage}
            maxValue={maxMileage}
            onInput={(e) => {
              handleMileageInput(e)
            }}
          />
        </div>

        <div className='col-md-4 mb-5'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.cabinType'})}
          </label>
          <Multiselect
            options={cabinTypeList}
            className='brand-list-wrap'
            selectedValues={selectedCabinTypeList}
            onSelect={(value: any) => setSelectedCabinTypeList(value)}
            onRemove={(value: any) => setSelectedCabinTypeList(value)}
            displayValue='name'
            style={{
              inputField: {
                minHeight: '30px',
                height: 'auto',
              },
            }}
          />
        </div>

        <div className='col-md-4 mb-5'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.axleType'})}
          </label>
          <Multiselect
            options={axleTypeList}
            className='brand-list-wrap'
            selectedValues={selectedAxleTypeList}
            onSelect={(value: any) => setSelectedAxleTypeList(value)}
            onRemove={(value: any) => setSelectedAxleTypeList(value)}
            displayValue='name'
            style={{
              inputField: {
                minHeight: '30px',
                height: 'auto',
              },
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.model'})}
          </label>
          <input
            placeholder={'model'}
            className={'form-control form-control-lg form-control-solid bg-white'}
            onChange={(event: any) => {
              setModel(event.target.value)
            }}
            value={model}
            type='text'
            name='model'
            autoComplete='off'
          />
        </div>

        <div className={'col-md-4 mb-5'}>
          <label className={'form-label text-muted  fs-4'}>
            {intl.formatMessage({id: 'Admin.Adverts.currency'})}
          </label>
          <Dropbox
            hideArrow
            id={'currency'}
            customClass={'form-select'}
            placeholder={intl.formatMessage({
              id: 'Admin.Adverts.currency',
            })}
            customSearchValue={''}
            customOnSearch={(e: any) => {}}
            disabledSearch
            onScroll={() => {}}
            setScrollCalled={() => {}}
            currentPageIndex={0}
            menuList={
              currencyList &&
              currencyList.map((item: any) => {
                return {
                  id: item?.currencyId,
                  title: item?.description,
                }
              })
            }
            selectedItemData={(dataItem: any) => {
              if (dataItem?.id) {
                setSelectedCurrencyId(Number(dataItem?.id))
              }
            }}
          />
        </div>

        <div className='col-md-4 mb-5'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.brand'})}
          </label>
          <Multiselect
            options={brandList}
            className='brand-list-wrap'
            selectedValues={selectedBrandList}
            onSelect={(value: any) => setSelectedBrandList(value)}
            onRemove={(value: any) => setSelectedBrandList(value)}
            displayValue='name'
            style={{
              inputField: {
                minHeight: '30px',
                height: 'auto',
              },
            }}
          />
        </div>

        <div className='mb-5 col-md-4'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.country'})}
          </label>
          <div className={''}>
            <Dropbox
              key={selectedFromFilterCountry}
              hideArrow
              customClass={'form-select'}
              placeholder={intl.formatMessage({
                id: 'freight.listing.country',
              })}
              customSearchValue={fromCountrySearch}
              customOnSearch={(e: any) => {
                setFromCountryPageIndex(0)
                setFromCountrySearch(e.target.value)
                getFromCountryList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setFromCountryPageIndex(val)
                getFromCountryList(val, fromCountrySearch)
              }}
              setScrollCalled={() => {
                setFromCountryScrollAPICalled(true)
              }}
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              scrollAPIResponse={fromCountryScrollAPICalled}
              currentPageIndex={fromCountryPageIndex}
              menuList={
                fromCountryList &&
                fromCountryList
                  ?.filter(function ({code}: any) {
                    // @ts-ignore
                    return !this[code] && (this[code] = code)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.code, title: item?.name}
                  })
              }
              defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setFromCountryId(item?.id)
                  setSelectedFromFilterCountry(item?.title)
                  setDependentFromCity(true)
                  setFromCityId('')
                }
              }}
            />
          </div>
        </div>

        <div className='mb-5 col-md-4'>
          <label htmlFor='publishDate' className={'text-muted fs-4'}>
            {intl.formatMessage({id: 'freight.listing.city'})}
          </label>
          <div className={''}>
            <Dropbox
              hideArrow
              disabled={!fromCountryId || fromCountryId === 'all'}
              customClass={'form-select'}
              placeholder={intl.formatMessage({id: 'freight.listing.city'})}
              customSearchValue={fromCitySearch}
              customOnSearch={(e: any) => {
                setFromCityPageIndex(0)
                setFromCitySearch(e.target.value)
                getFromSearchCityList(fromCountryId, 0, e.target.value)
              }}
              onScroll={(val: any) => {
                if (fromCountryId) {
                  setFromCityPageIndex(val)
                  getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                }
              }}
              setScrollCalled={() => {
                setFromCityScrollAPICalled(true)
              }}
              scrollAPIResponse={fromCityScrollAPICalled}
              currentPageIndex={fromCityPageIndex}
              defaultClearItem={{
                id: 'all',
                title: intl.formatMessage({id: 'Admin.Adverts.all'}),
              }}
              defaultSelected={{id: fromCityId}}
              menuList={
                fromCityList &&
                fromCityList
                  ?.filter(function ({cityId}: any) {
                    // @ts-ignore
                    return !this[cityId] && (this[cityId] = cityId)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.cityId, title: item?.name}
                  })
              }
              dependentValue={dependentFromCity}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  setFromCityId(item?.id)
                  setDependentFromCity(false)
                }
              }}
            />
          </div>
        </div>

        <div className='mb-5 col-md-4'>
          <label className='fs-4 text-muted'>
            {intl.formatMessage({id: 'freight.listing.year'})}
          </label>
          <MultiRangeSlider
            min={1960}
            max={new Date().getFullYear()}
            step={1}
            ruler={false}
            className='multi-range-wrap'
            style={{border: 'none', boxShadow: 'none'}}
            barInnerColor='#5e72e4'
            barLeftColor='#ddd'
            barRightColor='#ddd'
            minValue={minYearValue}
            maxValue={maxYearValue}
            onInput={(e) => {
              handleYearInput(e)
            }}
          />
        </div>
      </div>
    </>
  )
}

export {TruckFilter}
