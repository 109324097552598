import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'
import Modal from '../../../../Components/Modal'
import useScrollLock from '../../../../../utils/useScrollLock'
import {CreateTransporterGroupMamber} from './CreateTransporterGroupMamber'

import {TransporterGroupService} from '../../../../../services/CarrierServices/TransporterGroupService'
import ShowTransporterGroupMember from './ShowTransporterGroupMember'

const TransporterGroupMambersList = ({
  onClose,
  transporterGroupMambersList,
  transporterGroupId,
  handleGetTransporterMambers,
  setLoading,
  isTransportGroupMamberNextPage,
  setIsTransportGroupMamberNextPage,
}: any) => {
  const intl = useIntl()
  const {scrollLock, scrollUnlock} = useScrollLock()

  const transporterGroupServ = new TransporterGroupService()

  const [transporterGroupMamberId, setTransporterGroupMamberId] = useState<any>('')
  const [pageMIndex, setPageMIndex] = useState<number>(0)

  const [showCreateTranporterMamber, setShowCreateTranporterMamber] = useState<boolean>(false)
  const [showDeleteTransporterMamber, setShowDeleteTransporterMamber] = useState<boolean>(false)
  const [showTransporterMamberDetails, setShowTransporterMamberDetails] = useState<boolean>(false)

  const [transporterMemberDetails, setTransporterMemberDetails] = useState<any>({})

  useEffect(() => {
    scrollLock()
  }, [])

  const handleCreateTransporterGroupMamber = (userId: any) => {
    transporterGroupServ
      .createTransporterGroupMamber(transporterGroupId, userId)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.member.create.success'}))
        handleGetTransporterMambers({transporterGroupId: transporterGroupId, pageIndex: pageMIndex})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleDeleteTransporterGroupMamber = () => {
    transporterGroupServ
      .deleteTransporterGroupMamber(transporterGroupMamberId)
      .then(() => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Carrier.transporterGroup.member.delete.success'}))
        handleGetTransporterMambers({transporterGroupId: transporterGroupId, pageIndex: pageMIndex})
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const handleGetTransporterShowMember = (transporterMamberId: any) => {
    transporterGroupServ
      .getTransporterGroupMamberDetails(transporterMamberId)
      .then((res: any) => {
        setLoading(false)
        setTransporterMemberDetails(res?.data?.transporter)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const onPaginationChange = (page: number) => {
    if (!isTransportGroupMamberNextPage) {
      handleGetTransporterMambers({
        pageIndex: page,
      })
      setPageMIndex(page)
    }
    setIsTransportGroupMamberNextPage(true)
  }

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.member.list.title'})}
      closeOnClick={() => onClose()}
    >
      <div className={'d-flex align-items-center justify-content-end'}>
        <button
          onClick={() => {
            setShowCreateTranporterMamber(true)
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 fs-4'
        >
          {intl.formatMessage({id: 'Carrier.transporterGroup.member.list.create.title'})}
        </button>
      </div>

      <div>
        {!transporterGroupMambersList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Carrier.transporterGroup.manager.list.table.name'}),
              accessor: 'name',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.manager.list.table.surName',
              }),
              accessor: 'surname',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.member.list.table.vehiclePlate',
              }),
              accessor: 'vehiclePlate',
            },
            {
              Header: intl.formatMessage({
                id: 'Carrier.transporterGroup.member.list.table.trailerPlate',
              }),
              accessor: 'trailerPlate',
            },
          ]}
          editableRows={['']}
          data={
            transporterGroupMambersList &&
            transporterGroupMambersList.map((item: any) => {
              return {
                id: item?.transporterGroupMemberId,
                name: item?.transporter?.driver?.name ?? '-',
                surname: item?.transporter?.driver?.surname ?? '-',
                vehiclePlate: item?.transporter?.vehicle?.plate ?? '-',
                trailerPlate: item?.transporter?.trailer?.plate ?? '-',
              }
            })
          }
          deleteText={intl.formatMessage({id: 'addressListsWidget.delete'})}
          deleteRow={(row: any) => {
            setTransporterGroupMamberId(row?.values?.id)
            setShowDeleteTransporterMamber(true)
          }}
          showDetailBtn={(row: any) => {
            setLoading(true)
            setShowTransporterMamberDetails(true)
            handleGetTransporterShowMember(row?.values?.id)
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showCreateTranporterMamber && (
        <CreateTransporterGroupMamber
          onClose={() => setShowCreateTranporterMamber(false)}
          createTransporterGroupMamber={(userId: any) => handleCreateTransporterGroupMamber(userId)}
          setLoading={setLoading}
        />
      )}

      {showDeleteTransporterMamber && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeleteTransporterMamber(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'ADMIN.TRANSPORTS.DELETE.ASK'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeleteTransporterMamber(false)
                    }}
                  >
                    {intl.formatMessage({id: 'global.cancel'})}
                  </button>
                </div>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-success w-100'}
                    onClick={() => {
                      setLoading(true)
                      setShowDeleteTransporterMamber(false)
                      handleDeleteTransporterGroupMamber()
                    }}
                  >
                    {intl.formatMessage({id: 'global.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showTransporterMamberDetails && (
        <ShowTransporterGroupMember
          onClose={() => setShowTransporterMamberDetails(false)}
          selectedTransporter={transporterMemberDetails}
        />
      )}

      <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={() => {
              onClose()
              scrollUnlock()
            }}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {TransporterGroupMambersList}
