import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import _ from 'lodash'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {format} from 'date-fns'

import Seo from '../../../../utils/seo'
import useScrollLock from '../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../utils/common'

import DatePicker from '../../../Components/DatePicker'
import Loading from '../../../Components/Loading'
import MyTimePicker from '../../../Components/TimePicker'
import PhoneInp from '../../../Components/PhoneInp/PhoneInp'
import Dropbox from '../../../Components/Dropbox'
import {CreateMapAddress} from './Popup/CreateMapViewPopup'

import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'
import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'

import './CreatePublicFreight.scss'

const publicFreightCreateDataStructured: any = {
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: 'Create Freight',
  url: 'https://www.rivalog.com/public-freight-create',
  publisher: {
    '@type': 'Organization',
    name: 'Rivalog',
    logo: 'https://www.rivalog.com/media/logo.png',
  },
}

const publicFreightCreatedBreadcrumbStructured: any = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.rivalog.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Vehicles Looking For Freight',
      item: ' https://www.rivalog.com/public/available-vehicles',
    },
  ],
}

const CreatePublicFreight = () => {
  const intl = useIntl()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  const advertServ = new AdvertService()
  const addressServ = new AddressService()
  const commonServ = new CommonService()

  const [loading, setLoading] = useState<boolean>(false)
  const [isPreviewSuccess, setIsPreviewSuccess] = useState<boolean>(false)
  const [showAddressPopup, setShowAddressPopup] = useState<boolean>(false)
  const [ownerCompanyList, setOwnerCompanyList] = useState<any>([])

  const {scrollUnlock} = useScrollLock()

  useEffect(() => {
    if (!showAddressPopup) scrollUnlock()
  }, [showAddressPopup])

  const [transporterTypeList, setTransporterTypeList] = useState<any[]>([])
  const [selectedTransportType, setSelectedTransportType] = useState<any>(null)
  const [selectedTransportTypeName, setSelectedTransportTypeName] = useState<any>(null)
  const [currentTransporterPageIndex, setCurrentTransporterPageIndex] = useState<number>(0)
  const [transporterCustomSearch, setTransporterCustomSearch] = useState<string>('')

  /*Freight States Start*/
  const [freightTypes, setFreightTypes] = useState<any>([])
  const [freightContentTypes, setFreightContentTypes] = useState<any>([])
  /*Freight States End*/

  /*Other Lists Start*/
  const [distanceState, setDistanceState] = useState<any>({loadCityId: 0, arrivalCityId: 0})

  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])

  const [currencyList, setCurrencyList] = useState<any>([])

  const [isMapFromView, setIsMapFromView] = useState<any>(false)
  const [isMapToView, setIsMapToView] = useState<any>(false)
  const [showFromAddress, setShowFromAddress] = useState<any>(false)
  const [showToAddress, setShowToAddress] = useState<any>(false)
  const [clearTransportType, setClearTransportType] = useState<any>(false)

  const [distanceInfo, setDistanceInfo] = useState<any>(null)

  const [transporterTypeScrollAPICalled, setTransporterTypeScrollAPICalled] =
    useState<boolean>(false)
  /*Other Lists End*/

  const getTransportTypeList = (pageIndex: number = 0, searchText?: string) => {
    advertServ
      .transportPublicTypesList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransporterTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setTransporterTypeList(resp?.data?.filter((item: any) => item?.transportTypeId))
        else setTransporterTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTransporterTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getArrivalText = (dist: any) => {
    const day = dist?.day ? dist?.day + ' gün' : ''
    const hour = dist?.hour ? dist?.hour + ' saat' : ''
    const minute = dist?.minute ? dist?.minute + ' dakika' : ''
    return day || hour || minute ? `${day} ${hour} ${minute}` : '-'
  }

  /** Currencies api call Start **/
  const getCurrencyList = () => {
    advertServ
      .publicCurrencyList()
      .then((resp) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Currencies api call End **/

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    freightType: '',
    transportType: '',
    freightContentTypes: '',
    freightPackageType: '',
    freightLoadType: '',
    adr1: false,
    stackable: false,
    distanceMatrixId: '',

    fromCountry: '',
    fromCity: '',
    fromDistrict: '',
    fromNeighborhood: '',
    fromPostCode: '',
    fromStreet: '',
    fromStreetNumber: '',
    fromLatitude: '',
    fromLongitude: '',

    toCountry: '',
    toCity: '',
    toDistrict: '',
    toNeighborhood: '',
    toPostCode: '',
    toStreet: '',
    toStreetNumber: '',
    toLatitude: '',
    toLongitude: '',
    targetCost: '',
    targetCostCurrency: '',
    weight: '',

    name: '',
    surname: '',
    mobile: '',
    email: '',
    countryCode: '+90',
  }
  const loginSchema = Yup.object().shape(
    {
      floorType: Yup.string().notRequired(),

      loadDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      loadTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),

      freightType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      transportType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      freightContentTypes: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      freightPackageType: Yup.string().notRequired(),
      freightLoadType: Yup.string().notRequired(),
      distanceMatrixId: Yup.string(),
      adr1: Yup.boolean(),
      stackable: Yup.boolean(),

      fromCountry: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      fromCity: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      fromDistrict: Yup.string().notRequired(),
      fromNeighborhood: Yup.string().notRequired(),
      fromPostCode: Yup.string().notRequired(),
      fromStreet: Yup.string().notRequired(),
      fromStreetNumber: Yup.string().notRequired(),
      fromLatitude: Yup.string().notRequired(),
      fromLongitude: Yup.string().notRequired(),

      toCountry: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      toCity: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      toDistrict: Yup.string().notRequired(),
      toNeighborhood: Yup.string().notRequired(),
      toPostCode: Yup.string().notRequired(),
      toStreet: Yup.string().notRequired(),
      toStreetNumber: Yup.string().notRequired(),
      toLatitude: Yup.string().notRequired(),
      toLongitude: Yup.string().notRequired(),
      weight: Yup.string().notRequired(),
      targetCost: Yup.string().when(['targetCostCurrency'], {
        is: (targetCostCurrency: any) => !targetCostCurrency,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      targetCostCurrency: Yup.string().when(['targetCost'], {
        is: (targetCost: any) => !targetCost,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),

      name: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      surname: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      mobile: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      email: Yup.string()
        .email()
        .required(intl.formatMessage({id: 'global.mandatory'})),
      countryCode: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    },
    [['targetCostCurrency', 'targetCost']]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      createNewAdvert()
    },
  })
  /** Formik Preferences End**/

  const clearInputs = () => {
    formik.setValues(initialValues)
    setSelectedTransportTypeName(null)
    formik.setErrors({})
    setClearTransportType(true)
    formik.setTouched({}, false)
  }
  /** Create Advert api call Start **/
  const createNewAdvert = () => {
    const fromAddressData = {
      latitude: formik.values.fromLatitude,
      longitude: formik.values.fromLongitude,
      neighborhood: formik.values.fromNeighborhood,
      postCode: formik.values.fromPostCode,
      street: formik.values.fromStreet,
      streetNumber: formik.values.fromStreetNumber,
      city: {
        cityId: parseInt(formik.values.fromCity),
      },
      country: {
        code: formik.values.fromCountry,
      },
    }

    const toAddressData = {
      latitude: formik.values.toLatitude,
      longitude: formik.values.toLongitude,
      neighborhood: formik.values.toNeighborhood,
      postCode: formik.values.toPostCode,
      street: formik.values.toStreet,
      streetNumber: formik.values.toStreetNumber,
      city: {
        cityId: parseInt(formik.values.toCity),
      },
      country: {
        code: formik.values.toCountry,
      },
    }
    advertServ
      .createPublicAdvert(
        new Date(formik.values.loadDate + ' ' + formik.values.loadTime + ':00')
          .getTime()
          .toString(),
        new Date(formik.values.destDate + ' ' + formik.values.destTime + ':00')
          .getTime()
          .toString(),
        parseInt(formik.values.freightType),
        parseInt(formik.values.transportType),
        parseInt(formik.values.freightContentTypes),
        distanceInfo ? parseInt(distanceInfo?.distanceMatrixId) : -1,
        fromAddressData,
        toAddressData,
        formik.values.targetCost,
        formik.values.targetCostCurrency,
        formik.values.weight,
        formik.values.name,
        formik.values.surname,
        formik.values.mobile,
        formik.values.countryCode,
        formik.values.email,
        query.get('companyCode') ?? ''
      )
      .then((resp) => {
        setLoading(false)
        setIsPreviewSuccess(true)
        clearInputs()
        toast.success(intl.formatMessage({id: 'Carrier.freight.create.success'}))
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Create Advert api call End **/

  /** Freights api call Start **/
  const getFreightTypes = () => {
    advertServ
      .freightPublicTypes()
      .then((resp) => {
        setFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const getFreightContentTypes = () => {
    advertServ
      .freightPublicContentTypes()
      .then((resp) => {
        setFreightContentTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Freights api call End **/

  /** Address api call Start **/
  const getNewAddressList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getAdressList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        if (pageIndex === 0) setOwnerCompanyList(resp?.data?.filter((item: any) => item?.addressId))
        else setOwnerCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Address api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryPublicList()
      .then((resp) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCityList = (countryCode: string, key?: string) => {
    commonServ
      .cityPublicList(countryCode)
      .then((resp) => {
        if (key === 'fromCity') {
          setFromCityList(resp.data)
        } else if (key === 'toCity') {
          setToCityList(resp.data)
        } else {
          setCities(resp.data)
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Country and City List api call End **/

  /** Create New Address api call Start **/
  const popupSubmit = (data: any) => {
    /*
        setLoading(true);
        createNewAddress(data?.title, data?.country, data?.city, data?.district, data?.town, data?.postCode, data?.addressText);*/
    getNewAddressList()
    setTimeout(() => setShowAddressPopup(false), 150)
  }

  const getDistanceByCities = (fromCityId: number, toCityId: number, date?: number) => {
    advertServ
      .distanceFromCities(fromCityId, toCityId, date ?? 0)
      .then((resp: any) => {
        setDistanceInfo(resp?.data)
        if (resp?.data?.arrivalDate) {
          const dat = resp?.data?.arrivalDate
          // const arrivalTime = new Date(dat)?.toLocaleTimeString().split(':')
          formik.setFieldValue('destDate', format(dat, 'yyyy-MM-dd'))
          formik.setFieldValue('destNonFormatDate', dat)
          formik.setFieldValue(
            'destTime',
            new Date(dat).getHours() + ':' + new Date(dat).getMinutes()
          )
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
      })
  }
  /** Create New Address api call End **/

  useEffect(() => {
    if (distanceState?.loadCityId && distanceState?.arrivalCityId) {
      setLoading(true)
      getDistanceByCities(distanceState?.loadCityId, distanceState?.arrivalCityId)
    }
  }, [distanceState?.loadCityId, distanceState?.arrivalCityId])

  useEffect(() => {
    setLoading(true)
    getCountryList()
    setLoading(true)
    getFreightTypes()
    setLoading(true)
    getFreightContentTypes()
    setLoading(true)
    getCurrencyList()
    // getNewAddressList()
    getTransportTypeList()
  }, [])

  /*disabled days for arrive date input */
  let disDate = new Date(formik.values.loadNonFormatDate)

  const controlTheTime = (val: string) => {
    let str1: any = val + ':00'
    let str2: any = formik.values.destTime + ':00'

    str1 = str1.split(':')
    str2 = str2.split(':')

    let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0])
    let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0])

    if (totalSeconds1 < totalSeconds2) {
      toast.warning(intl.formatMessage({id: 'Address.create.time.warning'}))
      return false
    }
    return true
  }

  const vehicleContent = () => (
    <>
      <div>
        <div className={'row'}>
          <div className={'col-md-6 mb-10'}>
            <label htmlFor='freightType' className={'fs-4 mb-2 danger-text'}>
              {intl.formatMessage({id: 'Freight.createAdvert.freightType'})}
            </label>
            <select
              id={'freightType'}
              className='form-select'
              defaultValue={formik.values.freightType}
              {...formik.getFieldProps('freightType')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectLoadType'})}
              </option>
              {freightTypes &&
                freightTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {formik.touched.freightType && formik.errors.freightType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightType}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={'col-md-6 mb-10'}>
            <label htmlFor='transporterType' className={'fs-4 mb-2 danger-text'}>
              {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
            </label>
            <Dropbox
              id={'transporterType'}
              {...formik.getFieldProps('transporterType')}
              hideArrow
              customClass={'form-select transporter-search-overlay'}
              placeholder={intl.formatMessage({
                id: 'Carrier.transporters.create.transportType.placeHolder',
              })}
              customSearchValue={transporterCustomSearch}
              customOnSearch={(e: any) => {
                setCurrentTransporterPageIndex(0)
                setTransporterCustomSearch(e.target.value)
                getTransportTypeList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setCurrentTransporterPageIndex(val)
                getTransportTypeList(val, transporterCustomSearch)
              }}
              setScrollCalled={() => {
                setTransporterTypeScrollAPICalled(true)
              }}
              scrollAPIResponse={transporterTypeScrollAPICalled}
              currentPageIndex={currentTransporterPageIndex}
              menuList={
                transporterTypeList &&
                transporterTypeList
                  .filter(function ({transportTypeId}: any) {
                    // @ts-ignore
                    return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.transportTypeId, title: item?.description}
                  })
              }
              defaultClearItem={{
                id: '',
                title: '',
              }}
              defaultSelected={{id: formik.values.transportType, title: selectedTransportTypeName}}
              dependentValue={clearTransportType}
              selectedItemData={(item: any) => {
                if (item?.id === '' && clearTransportType) {
                  setClearTransportType(false)
                }
                if (item?.id) {
                  formik.setFieldValue('transportType', item?.id)
                  setSelectedTransportType(item?.id)
                  setSelectedTransportTypeName(item?.title)
                  // setLoading(true)
                }

                if (item?.id === '' && item?.title === '') {
                  formik.setFieldValue('transportType', '')
                  setSelectedTransportType('')
                  setSelectedTransportTypeName(null)
                }
              }}
            />
            {Object.keys(formik.touched).length > 0 && formik.errors.transportType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.transportType}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {locationContent()}
    </>
  )

  useEffect(() => {
    formik.values.fromCountry &&
      setTimeout(
        () =>
          (!formik.values.fromCity || formik.values.fromCity.length > 0) &&
          getCityList(formik.values.fromCountry, 'fromCity'),
        200
      )
  }, [formik.values.fromCountry])

  useEffect(() => {
    formik.values.toCountry &&
      setTimeout(
        () =>
          (!formik.values.toCity || formik.values.toCity.length > 0) &&
          getCityList(formik.values.toCountry, 'toCity'),
        200
      )
  }, [formik.values.toCountry])

  const locationContent = () => (
    <>
      {/* <SidebarGeneral /> */}
      <h4 className={'form-label fw-bolder text-dark fs-3'}>
        {intl.formatMessage({id: 'Freight.addresses.fullAddress'})}
      </h4>
      <div className='border-wrap'>
        <div className='tab-content' style={{padding: '20px'}}>
          {/* <div
            // className={clsx('tab-pane', {active: activeTab === 1})}
            id='kt_sidebar_tab_pane_2'
            role='tabpanel'
          > */}
          <div className='row'>
            {/* from address */}
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-md-6 mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                  </label>
                  <select
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.country.placeholder',
                    })}
                    {...formik.getFieldProps('fromCountry')}
                    className={'form-control form-control-lg'}
                    onChange={(e: any) => {
                      formik.setFieldValue('fromCountry', e.target.value)
                      formik.setFieldValue('fromCity', '')
                    }}
                  >
                    <option value='' selected disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                    </option>
                    {countries &&
                      countries?.map((item: any, key: number) => (
                        <option key={key} /*selected={country === item?.code}*/ value={item?.code}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                  {formik.touched?.fromCountry && formik.errors?.fromCountry && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors?.fromCountry}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-5 mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                  </label>
                  <select
                    disabled={!formik.values.fromCountry}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.city.placeholder',
                    })}
                    {...formik.getFieldProps('fromCity')}
                    className={'form-control form-control-lg'}
                  >
                    <option value='' selected={!formik.values.fromCity} disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                    </option>
                    {formik.values.fromCountry &&
                      fromCityList?.map((item: any) => (
                        <option key={item.cityId} value={item?.cityId}>
                          {item?.originalName}
                        </option>
                      ))}
                  </select>
                  {formik.touched.fromCity && formik.errors.fromCity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.fromCity}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='d-flex col-md-1 align-items-center map-content-warp'>
                  <img
                    src='/media/google_maps.svg'
                    className='map-wrap'
                    onClick={() => setIsMapFromView(true)}
                  />
                </div>
                <div className={'form-check mb-4 col-md-6 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr1'}
                    onChange={() => setShowFromAddress(!showFromAddress)}
                    checked={showFromAddress}
                    className={'form-check-input'}
                  />
                  <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                  </label>
                </div>
              </div>

              <div className='row'>
                {showFromAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.district1.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('fromDistrict')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}

                {showFromAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('fromNeighborhood')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>

              <div className='row'>
                {showFromAddress && (
                  <div className='col-md-12 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('fromPostCode')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>

              <div className='row'>
                {showFromAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.street.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('fromStreet')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}

                {showFromAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('fromStreetNumber')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='col-md-6'>
              {/* to Address */}
              <div className='row'>
                <div className='col-md-6 mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                  </label>
                  <select
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.country.placeholder',
                    })}
                    {...formik.getFieldProps('toCountry')}
                    className={'form-control form-control-lg'}
                    onChange={(e: any) => {
                      formik.setFieldValue('toCountry', e.target.value)
                      formik.setFieldValue('toCity', '')
                    }}
                  >
                    <option value='' selected disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                    </option>
                    {countries?.map((item: any, key: number) => (
                      <option key={key} /*selected={country === item?.code}*/ value={item?.code}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched?.toCountry && formik.errors?.toCountry && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors?.toCountry}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-5 mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                  </label>
                  <select
                    disabled={!formik.values.toCountry}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.city.placeholder',
                    })}
                    {...formik.getFieldProps('toCity')}
                    className={'form-control form-control-lg'}
                  >
                    <option value='' selected={!formik.values.toCity} disabled>
                      {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                    </option>
                    {formik.values.toCountry &&
                      toCityList?.map((item: any) => (
                        <option key={item.cityId} value={item?.cityId}>
                          {item?.originalName}
                        </option>
                      ))}
                  </select>
                  {formik.touched.toCity && formik.errors.toCity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>
                          {formik.errors.toCity}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-flex col-md-1 align-items-center map-content-warp'>
                  <img
                    src='/media/google_maps.svg'
                    className='map-wrap'
                    onClick={() => setIsMapToView(true)}
                  />
                </div>
                <div className={'form-check mb-4 col-md-6 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr2'}
                    onChange={() => setShowToAddress(!showToAddress)}
                    checked={showToAddress}
                    className={'form-check-input '}
                  />
                  <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                  </label>
                </div>
              </div>

              <div className='row'>
                {showToAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.district1.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('toDistrict')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}

                {showToAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('toNeighborhood')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>

              <div className='row'>
                {showToAddress && (
                  <div className='col-md-12 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('toPostCode')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>

              <div className='row'>
                {showToAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.street.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('toStreet')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}

                {showToAddress && (
                  <div className='col-md-6 mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                      })}
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('toStreetNumber')}
                      className={clsx('form-control form-control-lg form-control-solid bg-white')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {timeContent()}
    </>
  )

  const timeContent = () => (
    <>
      <div className={'row mb-10 mt-10'}>
        <div className={'col-md-3'}>
          <label htmlFor='loadDate' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.departureDate'})}
          </label>
          <DatePicker
            value={formik.values.loadNonFormatDate}
            onSelect={(data: any) => {
              formik.setFieldValue('loadDate', format(data, 'yyyy-MM-dd'))
              formik.setFieldValue('loadNonFormatDate', data)
              if (distanceState?.loadCityId && distanceState?.arrivalCityId) {
                setLoading(true)
                let myDate = new Date(
                  format(data, 'yyyy-MM-dd') + ' ' + (formik.values.loadTime ?? '00:00') + ':00'
                ).getTime()
                getDistanceByCities(distanceState?.loadCityId, distanceState?.arrivalCityId, myDate)
              }
            }}
            selected={!!formik.values.loadDate}
            customDate={formik.values.loadNonFormatDate}
            disabledDays={new Date()}
          />
          {formik.touched.loadDate && formik.errors.loadDate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.loadDate}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={'col-md-3'}>
          <label htmlFor='loadTime' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.departureTime'})}
          </label>
          <MyTimePicker
            onChange={(val: string) => {
              formik.setFieldValue('loadTime', val)
              if (
                ((distanceState?.loadCityId && distanceState?.arrivalCityId) ||
                  (formik.values.fromCountry &&
                    formik.values.fromCity &&
                    formik.values.toCountry &&
                    formik.values.toCity)) &&
                val &&
                formik.values.loadDate &&
                formik.values.loadNonFormatDate
              ) {
                setLoading(true)
                let myDate = new Date(formik.values.loadDate + ' ' + val + ':00').getTime()
                getDistanceByCities(
                  distanceState?.loadCityId || formik.values.fromCity,
                  distanceState?.arrivalCityId || formik.values.toCity,
                  myDate
                )
              }
            }}
            value={formik.values?.loadTime}
          />
          {formik.touched.loadTime && formik.errors.loadTime && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.loadTime}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className={'col-md-3'}>
          <label htmlFor='destDate' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.arrivalDate'})}
          </label>
          <DatePicker
            disabled={!formik.values.loadDate}
            value={formik.values.destNonFormatDate}
            selected={!!formik.values.destDate}
            onSelect={(data: any) => {
              formik.setFieldValue('destDate', format(data, 'yyyy-MM-dd'))
              formik.setFieldValue('destNonFormatDate', data)
            }}
            customDate={formik.values.destNonFormatDate}
            disabledDays={disDate.setDate(disDate.getDate())}
          />
          {formik.touched.destDate && formik.errors.destDate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.destDate}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={'col-md-3'}>
          <label htmlFor='loadTime' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.arrivalTime'})}
          </label>
          <MyTimePicker
            onChange={(val: string) => {
              controlTheTime(val) && formik.setFieldValue('destTime', val)
            }}
            value={formik.values?.destTime}
            /*min={formik.values.loadTime ?? ''}*/
          />
          {formik.touched.destTime && formik.errors.destTime && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.destTime}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {loadContent()}
    </>
  )

  const loadContent = () => (
    <>
      <div>
        <div className={'row mb-10'}>
          <div className={'col-md-5 mb-10'}>
            <label htmlFor='freightContentTypes' className={'danger-text fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
            </label>
            <select
              id={'freightContentTypes'}
              className='form-select'
              {...formik.getFieldProps('freightContentTypes')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectLoadContentType'})}
              </option>
              {freightContentTypes &&
                freightContentTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightContentTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {formik.touched.freightContentTypes && formik.errors.freightContentTypes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightContentTypes}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label
              htmlFor='freightLoadType'
              className={`${
                formik.errors.targetCost || formik.errors.targetCostCurrency
                  ? 'danger-text'
                  : 'text-muted'
              } fs-4 mb-2`}
            >
              {intl.formatMessage({id: 'Freight.createAdvert.targetCurrency'})}
            </label>
            <div className={'row'}>
              <div className={'col-md-7 mb-5'}>
                <input
                  type='number'
                  id={'targetCost'}
                  step='any'
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  placeholder={intl.formatMessage({id: 'Freight.createAdvert.enterValue'})}
                  value={formik.values.targetCost}
                  onChange={(e) => formik.setFieldValue('targetCost', e.target.value)}
                  min={0}
                  className={clsx('form-control', {
                    'price-input-wrap': formik.values.targetCost,
                  })}
                />
                {formik.touched.targetCost && formik.errors.targetCost && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.targetCost}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={'col-md-5'}>
                <select
                  id={'targetCostCurrency'}
                  className='form-select'
                  {...formik.getFieldProps('targetCostCurrency')}
                >
                  <option value='' selected disabled>
                    {intl.formatMessage({id: 'Freight.createAdvert.currency'})}
                  </option>
                  {currencyList &&
                    currencyList.map((item: any, key: number) => (
                      <option key={key} value={item?.currencyId}>
                        {item?.description}
                      </option>
                    ))}
                </select>
                {formik.touched.targetCostCurrency && formik.errors.targetCostCurrency && (
                  <div className='fv-plugins-message-container' style={{position: 'absolute'}}>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.targetCostCurrency}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={'col-md-3 mb-10'}>
            <label htmlFor='weight' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.grossKg'})}
            </label>
            <input
              type='number'
              id={'weight'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.grossKg.placeHolder'})}
              value={formik.values.weight}
              min={1}
              onChange={(e) => formik.setFieldValue('weight', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.weight && formik.errors.weight && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.weight}
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>

      <h4 className={'form-label fw-bolder text-dark fs-3'}>
        {intl.formatMessage({id: 'freight.public.contactInfo'})}
      </h4>
      <hr />
      <div className='row'>
        <div className='col-md-3 fv-row mb-10'>
          <label className='fs-4 danger-text'>
            {intl.formatMessage({id: 'CreateClient.create.name.name'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
            className={clsx('form-control form-control-lg form-control-solid bg-white')}
            onChange={(event: any) => formik.setFieldValue('name', event.target.value)}
            value={formik.values.name}
            type='text'
            name='name'
            autoComplete='off'
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.name}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-3 fv-row mb-10'>
          <label className='fs-4 danger-text'>
            {intl.formatMessage({id: 'CreateClient.create.surName'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
            className={clsx('form-control form-control-lg form-control-solid bg-white')}
            onChange={(event: any) => formik.setFieldValue('surname', event.target.value)}
            value={formik.values.surname}
            type='text'
            name='name'
            autoComplete='off'
          />
          {formik.touched.surname && formik.errors.surname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.surname}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className={'col-md-2'}>
          <label htmlFor={'phoneCode'} className='danger-text fs-4 mb-2'>
            {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
          </label>
          <PhoneInp
            id={'countryCode'}
            onSelect={(event: any) => {
              formik.setFieldValue('countryCode', event.length > 0 ? '+' + event : event)
            }}
            className='form-control form-control-lg form-control-solid w-100 bg-white'
          />
          {formik.touched.countryCode && formik.errors.countryCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.countryCode}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row col-md-4'>
          <label className='fs-4 danger-text'>
            {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Carrier.drivers.create.phone.placeHolder'})}
            maxLength={13}
            className={clsx('form-control form-control-lg form-control-solid bg-white')}
            onChange={(event: any) => {
              if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                formik.setFieldValue('mobile', event.target.value)
              }
            }}
            value={formik.values.mobile}
            type='text'
            name='mobile'
            autoComplete='off'
            required
          />
          {formik.touched.mobile && formik.errors.mobile && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.mobile}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='mb-10 col-md-6'>
          <label className='fs-4 danger-text'>
            {intl.formatMessage({id: 'CreateClient.create.email'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
            className={clsx('form-control form-control-lg form-control-solid bg-white')}
            onChange={(event: any) => formik.setFieldValue('email', event.target.value)}
            value={formik.values.email}
            type='text'
            name='name'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.email}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )

  return (
    <div className='create-public-wrap'>
      <Seo
        data={{
          title: intl.formatMessage({id: 'Seo.freight.create.title'}),
          description: intl.formatMessage({id: 'Seo.freight.create.description'}),
          href: 'https://www.rivalog.com/public-freight-create',
        }}
      >
        <script type='application/ld+json'>
          {JSON.stringify(publicFreightCreateDataStructured)}
        </script>
        <script type='application/ld+json'>
          {JSON.stringify(publicFreightCreatedBreadcrumbStructured)}
        </script>
      </Seo>
      <h1 style={{opacity: 0, position: 'absolute'}}>
        {intl.formatMessage({id: 'Seo.freight.create.h1.tag'})}
      </h1>
      {loading && <Loading />}
      <div className='row col-md-2 justify-content-end w-100'>
        <Link
          to='/'
          className='link-primary fs-6 fw-bolder'
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {intl.formatMessage({id: 'freight.public.home'})}
        </Link>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
        <h1>{intl.formatMessage({id: 'freight.create.createFreight'})}</h1>
      </div>
      <div>
        {vehicleContent()}
        <div className='row d-flex justify-content-end'>
          <div className={'col-md-2'}>
            <button
              className={'btn btn-primary w-100'}
              onClick={() => formik.handleSubmit()}
              type={'submit'}
            >
              {intl.formatMessage({id: 'Carrier.freight.create'})}
            </button>
          </div>
        </div>
      </div>

      {(isMapFromView || isMapToView) && (
        <CreateMapAddress
          handleClose={() => {
            if (isMapFromView) {
              setIsMapFromView(false)
            }

            if (isMapToView) {
              setIsMapToView(false)
            }
          }}
          setFillupAddress={(data: any) => {
            if (isMapFromView) {
              formik.setFieldValue('fromCountry', data.countryCode)
              formik.setFieldValue('fromCity', data.cityId)
              formik.setFieldValue('fromDistrict', data.district)
              formik.setFieldValue('fromNeighborhood', data.neighborhood)
              formik.setFieldValue('fromPostCode', data.postCode)
              formik.setFieldValue('fromStreet', data.street)
              formik.setFieldValue('fromStreetNumber', data.streetNumber)
              formik.setFieldValue('fromLatitude', data.latitude)
              formik.setFieldValue('fromLongitude', data.longitude)
            }

            if (isMapToView) {
              formik.setFieldValue('toCountry', data.countryCode)
              formik.setFieldValue('toCity', data.cityId)
              formik.setFieldValue('toDistrict', data.district)
              formik.setFieldValue('toNeighborhood', data.neighborhood)
              formik.setFieldValue('toPostCode', data.postCode)
              formik.setFieldValue('toStreet', data.street)
              formik.setFieldValue('toStreetNumber', data.streetNumber)
              formik.setFieldValue('toLatitude', data.latitude)
              formik.setFieldValue('toLongitude', data.longitude)
            }
          }}
        />
      )}
    </div>
  )
}

export {CreatePublicFreight}
