import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {format} from 'date-fns'

import {PageTitle} from '../../../../_metronic/layout/core'

import Loading from '../../../Components/Loading'
import TabManagerComponent from '../../../Components/TabManager/TabManagerComponent'
import DatePicker from '../../../Components/DatePicker'
import PhoneInp from '../../../Components/PhoneInp/PhoneInp'
import AddNewAddressPopup from '../../../Components/AddNewAddressPopup'
import {CreateMapAddress} from './Popups/CreateMapViewPopup'
import CheckGroup from '../../../Components/CheckGroup'
import PreviewScreen from './Popups/PreviewScreen'
import MyTimePicker from '../../../Components/TimePicker'
import Dropbox from '../../../Components/Dropbox'

import useScrollLock from '../../../../utils/useScrollLock'
import {exceptThisSymbols} from '../../../../utils/common'

import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {AddressService} from '../../../../services/FreightOperatorServices/AddressService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import './CreateNewAdvert.scss'

const CreateNewAdvert = () => {
  const intl = useIntl()
  const advertServ = new AdvertService()
  const addressServ = new AddressService()
  const commonServ = new CommonService()

  const [activeTab, setActiveTab] = useState(0)
  const [activeClientTab, setActiveClientTab] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [showAddressPopup, setShowAddressPopup] = useState<boolean>(false)
  const [companyDeptCustomSearch, setCompanyDeptCustomSearch] = useState<string>('')
  const [ownerCompanyList, setOwnerCompanyList] = useState<any>([])

  const [consignorClientCustomSearch, setConsignorClientCustomSearch] = useState<string>('')
  const [consignorClientPageIndex, setConsignorClientPageIndex] = useState<number>(0)
  const [consignorCompanyList, setConsignorCompanyList] = useState<any>([])
  const [selectedConsignorName, setSelectedConsignorName] = useState<any>(null)

  const [consigneeClientCustomSearch, setConsigneeClientCustomSearch] = useState<string>('')
  const [consigneeClientPageIndex, setConsigneeClientPageIndex] = useState<number>(0)
  const [consigneeCompanyList, setConsigneeCompanyList] = useState<any>([])
  const [selectedConsigneeName, setSelectedConsigneeName] = useState<any>(null)

  const [companyArrivalSearch, setCompanyArrivalSearch] = useState<string>('')

  const [showPreviewScreen, setShowPreviewScreen] = useState<boolean>(false)
  const [showPreviewSuccess, setShowPreviewSuccess] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (!showAddressPopup && !showPreviewScreen && !showPreviewSuccess) scrollUnlock()
  }, [showPreviewScreen, showPreviewSuccess, showAddressPopup])

  const [transporterTypeList, setTransporterTypeList] = useState<any[]>([])
  const [selectedTransportType, setSelectedTransportType] = useState<any>(null)
  const [selectedTransportTypeName, setSelectedTransportTypeName] = useState<any>(null)
  const [selectedLoadAddressName, setSelectedLoadAddressName] = useState<any>(null)
  const [selectedDestinationAddressName, setSelectedDestinationAddressName] = useState<any>(null)
  const [currentTransporterPageIndex, setCurrentTransporterPageIndex] = useState<number>(0)
  const [transporterCustomSearch, setTransporterCustomSearch] = useState<string>('')
  /*Trailer States Start*/
  const [trailerTypes, setTrailerTypes] = useState<any>([])
  const [trailerTypesData, setTrailerTypesData] = useState<any>([])
  const [floorTypes, setFloorTypes] = useState<any>([])
  const [trailerSpecifications, setTrailerSpecifications] = useState<any>([])
  /*Trailer States End*/

  /*Transport States Start*/
  const [transportTypes, setTransportTypes] = useState<any>([])
  /*Trailer States End*/

  /*Freight States Start*/
  const [freightTypes, setFreightTypes] = useState<any>([])
  const [freightContentTypes, setFreightContentTypes] = useState<any>([])
  const [freightLoadTypes, setFreightLoadTypes] = useState<any>([])
  const [freightPackageTypes, setFreightPackageTypes] = useState<any>([])
  /*Freight States End*/

  /*Other Lists Start*/
  const [addressList, setAddressList] = useState<any>([])
  const [currencyList, setCurrencyList] = useState<any>([])
  const [distanceState, setDistanceState] = useState<any>({loadCityId: 0, arrivalCityId: 0})

  const [gtipList, setGtipList] = useState<any>([])
  const [gtipSearch, setGtipSearch] = useState<string>('')
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [currentDeptLocPageIndex, setCurrentDeptLocPageIndex] = useState<number>(0)

  const [countries, setCountries] = useState<any>([])
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [cities, setCities] = useState<any>([])

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])

  const [isMapFromView, setIsMapFromView] = useState<any>(false)
  const [isMapToView, setIsMapToView] = useState<any>(false)
  const [showFromAddress, setShowFromAddress] = useState<any>(false)
  const [showToAddress, setShowToAddress] = useState<any>(false)
  const [requiredLocationContent, setRequiredLocationContent] = useState<any>(false)

  const [addressesDetail, setAddressesDetail] = useState<any>({
    departureDetail: null,
    arrivalDetail: null,
  })
  const [distanceInfo, setDistanceInfo] = useState<any>(null)
  /*Other Lists End*/

  /*Specification Component State*/
  const [specSelected, setSpecSelected] = useState<any>([])

  const [transporterTypeScrollAPICalled, setTransporterTypeScrollAPICalled] =
    useState<boolean>(false)
  const [consignorClientScrollAPICalled, setConsignorClientScrollAPICalled] =
    useState<boolean>(false)
  const [consigneeClientScrollAPICalled, setConsigneeClientScrollAPICalled] =
    useState<boolean>(false)
  const [fromAddressScrollAPICalled, setFromAddressScrollAPICalled] = useState<boolean>(false)
  const [toAddressScrollAPICalled, setToAddressScrollAPICalled] = useState<boolean>(false)
  const [harmonizedCodeScrollAPICalled, setHarmonizedCodeScrollAPICalled] = useState<boolean>(false)

  useEffect(() => {
    specSelected && formik.setFieldValue('trailerSpecification', specSelected)
  }, [specSelected])

  const getTransportTypeList = (pageIndex: number = 0, searchText?: string) => {
    advertServ
      .transportTypesList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setTransporterTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setTransporterTypeList(resp?.data?.filter((item: any) => item?.transportTypeId))
        else setTransporterTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setTransporterTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getArrivalText = (dist: any) => {
    const day = dist?.day ? dist?.day + ' gün' : ''
    const hour = dist?.hour ? dist?.hour + ' saat' : ''
    const minute = dist?.minute ? dist?.minute + ' dakika' : ''
    return day || hour || minute ? `${day} ${hour} ${minute}` : '-'
  }
  const DistanceCard = () => (
    <div className={'card-header border border-2 border-info px-8 py-3 d-flex align-items-center'}>
      <div className={'text-muted'} style={{marginRight: '20px'}}>
        <p className={'text-muted mb-0'}>
          {intl.formatMessage({id: 'Freight.createAdvert.distance'})}
        </p>
        <b className={'text-dark'}>
          {distanceInfo?.distance + ' ' + distanceInfo?.unit?.description}
        </b>
      </div>
      <div>
        <p className={'text-muted mb-0'}>
          {intl.formatMessage({id: 'Freight.createAdvert.eliminatedTime'})}
        </p>
        <b className={'text-dark'}>{getArrivalText(distanceInfo)}</b>
      </div>
    </div>
  )

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    title: '',
    trailerType: '',
    trailerSpecification: [],
    floorType: '',

    loadDate: '',
    loadNonFormatDate: new Date().getTime(),
    loadTime: '',

    destDate: '',
    destNonFormatDate: new Date().getTime(),
    destTime: '',

    loadAddress: '',
    destinationAddress: '',
    freightType: '',
    transportType: '',
    note: '',
    freightContentTypes: '',
    freightPackageType: '',
    freightLoadType: '',
    weight: '',
    value: '',
    currency: '',
    adr1: false,
    stackable: false,

    width: '',
    height: '',
    freightLength: '',
    desi: '',
    ldm: '',
    harmonizedSystemCode: '',
    distanceMatrixId: '',

    fromCountry: '',
    fromCity: '',
    fromDistrict: '',
    fromNeighborhood: '',
    fromPostCode: '',
    fromStreet: '',
    fromStreetNumber: '',
    fromLatitude: '',
    fromLongitude: '',

    toCountry: '',
    toCity: '',
    toDistrict: '',
    toNeighborhood: '',
    toPostCode: '',
    toStreet: '',
    toStreetNumber: '',
    toLatitude: '',
    toLongitude: '',
    targetCost: '',
    targetCostCurrency: '',

    consignorName: '',
    consignorSurName: '',
    consignorCountryCode: '+90',
    consignorPhone: '',
    consignorEmail: '',
    consignorTaxNumber: '',
    consignorTaxOffice: '',
    consignorReferenceClientId: '',

    consigneeName: '',
    consigneeSurName: '',
    consigneeCountryCode: '+90',
    consigneePhone: '',
    consigneeEmail: '',
    consigneeTaxNumber: '',
    consigneeTaxOffice: '',
    consigneeReferenceClientId: '',
  }
  const loginSchema = Yup.object().shape(
    {
      width: Yup.string().notRequired(),
      height: Yup.string().notRequired(),
      freightLength: Yup.string().notRequired(),
      desi: Yup.string().notRequired(),
      ldm: Yup.string().notRequired(),
      note: Yup.string().notRequired(),

      title: Yup.string().notRequired(),
      trailerType: Yup.string().notRequired(),
      floorType: Yup.string().notRequired(),
      /*.required(intl.formatMessage({id: 'global.mandatory'}))*/ trailerSpecification:
        Yup.array().notRequired() /*min(1, intl.formatMessage({id: 'global.mandatory'}))*/,

      loadDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      loadTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destDate: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      destTime: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),

      loadAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      destinationAddress: Yup.string().when(['fromCountry', 'toCountry'], {
        is: (fromCountry: any, toCountry: any) => !fromCountry && !toCountry,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      value: Yup.string().when(['currency'], {
        is: (currency: any) => !currency,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      currency: Yup.string().when(['value'], {
        is: (value: any) => !value,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      freightType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      transportType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      freightContentTypes: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      freightPackageType: Yup.string().notRequired(),
      freightLoadType: Yup.string().notRequired(),
      weight: Yup.string().notRequired(),
      harmonizedSystemCode: Yup.string().notRequired(),
      distanceMatrixId: Yup.string(),
      adr1: Yup.boolean(),
      stackable: Yup.boolean(),

      fromCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      fromCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      fromDistrict: Yup.string().notRequired(),
      fromNeighborhood: Yup.string().notRequired(),
      fromPostCode: Yup.string().notRequired(),
      fromStreet: Yup.string().notRequired(),
      fromStreetNumber: Yup.string().notRequired(),
      fromLatitude: Yup.string().notRequired(),
      fromLongitude: Yup.string().notRequired(),

      toCountry: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      toCity: Yup.string().when(['loadAddress'], {
        is: (loadAddress: any) => !loadAddress,
        then: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
        otherwise: Yup.string(), // unnecessary
      }),
      toDistrict: Yup.string().notRequired(),
      toNeighborhood: Yup.string().notRequired(),
      toPostCode: Yup.string().notRequired(),
      toStreet: Yup.string().notRequired(),
      toStreetNumber: Yup.string().notRequired(),
      toLatitude: Yup.string().notRequired(),
      toLongitude: Yup.string().notRequired(),
      targetCost: Yup.string().when(['targetCostCurrency'], {
        is: (targetCostCurrency: any) => !targetCostCurrency,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),
      targetCostCurrency: Yup.string().when(['targetCost'], {
        is: (targetCost: any) => !targetCost,
        then: Yup.string(), // unnecessary
        otherwise: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
      }),

      consignorName: Yup.string().notRequired(),
      consignorSurName: Yup.string().notRequired(),
      consignorCountryCode: Yup.string().notRequired(),
      consignorPhone: Yup.string().notRequired(),
      consignorEmail: Yup.string().notRequired(),
      consignorTaxNumber: Yup.string().notRequired(),
      consignorTaxOffice: Yup.string().notRequired(),
      consignorReferenceClientId: Yup.string().notRequired(),

      consigneeName: Yup.string().notRequired(),
      consigneeSurName: Yup.string().notRequired(),
      consigneeCountryCode: Yup.string().notRequired(),
      consigneePhone: Yup.string().notRequired(),
      consigneeEmail: Yup.string().notRequired(),
      consigneeTaxNumber: Yup.string().notRequired(),
      consigneeTaxOffice: Yup.string().notRequired(),
      consigneeReferenceClientId: Yup.string().notRequired(),
    },
    [
      ['fromCountry', 'toCountry'],
      ['loadAddress', 'fromCountry'],
      ['loadAddress', 'toCountry'],
      ['loadAddress', 'fromCity'],
      ['loadAddress', 'toCity'],
      ['destinationAddress', 'fromCountry'],
      ['destinationAddress', 'toCountry'],
      ['targetCostCurrency', 'targetCost'],
      ['value', 'currency'],
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setShowPreviewScreen(true)
    },
  })
  /** Formik Preferences End**/

  /** Create Advert api call Start **/
  const createNewAdvert = () => {
    const fromAddressData = {
      latitude: formik.values.fromLatitude,
      longitude: formik.values.fromLongitude,
      neighborhood: formik.values.fromNeighborhood,
      postCode: formik.values.fromPostCode,
      street: formik.values.fromStreet,
      streetNumber: formik.values.fromStreetNumber,
      city: {
        cityId: parseInt(formik.values.fromCity),
      },
      country: {
        code: formik.values.fromCountry,
      },
    }

    const toAddressData = {
      latitude: formik.values.toLatitude,
      longitude: formik.values.toLongitude,
      neighborhood: formik.values.toNeighborhood,
      postCode: formik.values.toPostCode,
      street: formik.values.toStreet,
      streetNumber: formik.values.toStreetNumber,
      city: {
        cityId: parseInt(formik.values.toCity),
      },
      country: {
        code: formik.values.toCountry,
      },
    }

    let consignor = null
    let consignee = null

    if (
      formik.values.consignorName ||
      formik.values.consignorSurName ||
      formik.values.consignorPhone ||
      formik.values.consignorEmail ||
      formik.values.consignorTaxNumber ||
      formik.values.consignorTaxOffice ||
      formik.values.consignorReferenceClientId
    ) {
      consignor = {
        name: formik?.values?.consignorName || null,
        surname: formik?.values?.consignorSurName || null,
        countryCode: formik?.values?.consignorPhone ? formik.values.consignorCountryCode : null,
        phone: formik?.values?.consignorPhone || null,
        email: formik?.values?.consignorEmail || null,
        taxNumber: formik?.values?.consignorTaxNumber || null,
        taxOffice: formik?.values?.consignorTaxOffice || null,
        referenceClientId: formik?.values?.consignorReferenceClientId || null,
      }
    }

    if (
      formik.values.consigneeName ||
      formik.values.consigneeSurName ||
      formik.values.consigneePhone ||
      formik.values.consigneeEmail ||
      formik.values.consigneeTaxNumber ||
      formik.values.consigneeTaxOffice ||
      formik.values.consigneeReferenceClientId
    ) {
      consignee = {
        name: formik?.values?.consigneeName || null,
        surname: formik?.values?.consigneeSurName || null,
        countryCode: formik?.values?.consigneePhone ? formik.values.consigneeCountryCode : null,
        phone: formik?.values?.consigneePhone || null,
        email: formik?.values?.consigneeEmail || null,
        taxNumber: formik?.values?.consigneeTaxNumber || null,
        taxOffice: formik?.values?.consigneeTaxOffice || null,
        referenceClientId: formik?.values?.consigneeReferenceClientId || null,
      }
    }

    advertServ
      .createAdvert(
        formik.values.title,
        formik.values.value,
        formik.values.weight,
        formik.values.width,
        formik.values.height,
        formik.values.freightLength,
        formik.values.desi,
        formik.values.ldm,
        formik.values.adr1,
        formik.values.stackable,
        parseFloat(formik.values.currency),
        new Date(formik.values.loadDate + ' ' + formik.values.loadTime + ':00')
          .getTime()
          .toString(),
        new Date(formik.values.destDate + ' ' + formik.values.destTime + ':00')
          .getTime()
          .toString(),
        parseInt(formik.values.loadAddress),
        parseInt(formik.values.destinationAddress),
        parseInt(formik.values.trailerType),
        parseInt(formik.values.floorType),
        formik.values.trailerSpecification,
        parseInt(formik.values.freightType),
        parseInt(formik.values.transportType),
        formik.values.note,
        parseInt(formik.values.freightContentTypes),
        parseInt(formik.values.freightLoadType),
        parseInt(formik.values.freightPackageType),
        formik.values.harmonizedSystemCode,
        distanceInfo ? parseInt(distanceInfo?.distanceMatrixId) : -1,
        fromAddressData,
        toAddressData,
        formik.values.targetCost,
        formik.values.targetCostCurrency,
        consignor,
        consignee
      )
      .then((resp) => {
        setLoading(false)
        setShowPreviewSuccess(true)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Create Advert api call End **/

  /** Trailer api call Start **/
  const getTrailerTypes = () => {
    advertServ
      .trailerTypes()
      .then((resp) => {
        setTrailerTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTrailerTypesData = (selectedTransporterType?: number) => {
    advertServ
      .trailerTypesList(selectedTransporterType)
      .then((resp) => {
        setLoading(false)
        setTrailerTypesData(resp?.data)
        if (
          !(
            selectedTransporterType &&
            formik.values.transportType &&
            Number(selectedTransporterType) === Number(formik.values.transportType)
          )
        ) {
          formik.setFieldValue('trailerType', '')
          setSpecSelected([])
          setTrailerSpecifications([])
        }
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const getFloorTypes = () => {
    advertServ
      .floorTypes()
      .then((resp) => {
        setFloorTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTrailerSpecifications = (trailerId: number) => {
    advertServ
      .trailerSpecifications(trailerId)
      .then((resp) => {
        setTrailerSpecifications(resp?.data)
        setLoading(false)
        setSpecSelected([])
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Trailer api call End **/

  /** Freights api call Start **/
  const getFreightTypes = () => {
    advertServ
      .freightTypes()
      .then((resp) => {
        setFreightTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const getFreightContentTypes = () => {
    advertServ
      .freightContentTypes()
      .then((resp) => {
        setFreightContentTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const getFreightLoadTypes = () => {
    advertServ
      .freightLoadingTypes()
      .then((resp) => {
        setFreightLoadTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const getFreightPackageTypes = () => {
    advertServ
      .freightPackageTypes()
      .then((resp) => {
        setFreightPackageTypes(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Freights api call End **/

  /** Address api call Start **/
  const getAddressList = (pageIndex?: number) => {
    addressServ
      .getAdressList(pageIndex ?? 0)
      .then((resp) => {
        setAddressList(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getNewAddressList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getAdressList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setFromAddressScrollAPICalled(false)
        setToAddressScrollAPICalled(false)
        if (pageIndex === 0) setOwnerCompanyList(resp?.data?.filter((item: any) => item?.addressId))
        else setOwnerCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromAddressScrollAPICalled(false)
        setToAddressScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getConsignorClientsList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getClientsList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setConsignorClientScrollAPICalled(false)
        if (pageIndex === 0)
          setConsignorCompanyList(resp?.data?.filter((item: any) => item?.clientId))
        else setConsignorCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setConsignorClientScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getConsigneeClientsList = (pageIndex: number = 0, searchText?: string) => {
    addressServ
      .getClientsList(pageIndex ?? 0, searchText ?? '')
      .then((resp: any) => {
        setConsigneeClientScrollAPICalled(false)
        if (pageIndex === 0)
          setConsigneeCompanyList(resp?.data?.filter((item: any) => item?.clientId))
        else setConsigneeCompanyList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setConsigneeClientScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Address api call End **/

  /** Currencies api call Start **/
  const getCurrencyList = () => {
    advertServ
      .currencyList()
      .then((resp) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Currencies api call End **/

  /** Gtip api call Start **/
  const getGtips = (searchText?: string, pageIndex: number = 0) => {
    advertServ
      .gtipList(searchText ?? '', pageIndex)
      .then((resp: any) => {
        setHarmonizedCodeScrollAPICalled(false)
        if (pageIndex === 0) setGtipList(resp?.data)
        else {
          setGtipList((item: any) => [...item, ...resp.data])
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setHarmonizedCodeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Gtip api call End **/

  /** Country and City List api call Start **/
  const getCountryList = () => {
    commonServ
      .countryList()
      .then((resp) => {
        setCountries(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCityList = (countryCode: string, key?: string) => {
    commonServ
      .cityList(countryCode)
      .then((resp) => {
        if (key === 'fromCity') {
          setFromCityList(resp.data)
        } else if (key === 'toCity') {
          setToCityList(resp.data)
        } else {
          setCities(resp.data)
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  /** Country and City List api call End **/

  /** Create New Address api call Start **/
  const popupSubmit = (data: any) => {
    /*
        setLoading(true);
        createNewAddress(data?.title, data?.country, data?.city, data?.district, data?.town, data?.postCode, data?.addressText);*/
    getAddressList()
    getNewAddressList()
    setTimeout(() => setShowAddressPopup(false), 150)
  }

  // const createNewAddress = (
  //   desc: string,
  //   countryCode: string,
  //   cityId: number,
  //   district: string,
  //   postCode: string,
  //   addressText: string
  // ) => {
  //   addressServ
  //     .createAddress(desc, countryCode, cityId, district, postCode, addressText)
  //     .then(() => {
  //       getAddressList()
  //       toast.success(intl.formatMessage({id: 'Address.create.success'}), {
  //         position: toast.POSITION.TOP_RIGHT,
  //       })
  //     })
  //     .catch((e: any) => {
  //       setLoading(false)
  //       toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
  //     })
  // }

  const getAddressesDetail = (type: 'arrival' | 'departure', id: number) => {
    addressServ
      .getAdress(id)
      .then((resp: any) => {
        type === 'arrival'
          ? setAddressesDetail({
              ...addressesDetail,
              arrivalDetail: resp?.data,
            })
          : setAddressesDetail({
              ...addressesDetail,
              departureDetail: resp?.data,
            })
        type === 'arrival'
          ? setDistanceState((item: any) => ({...item, arrivalCityId: resp?.data?.city?.cityId}))
          : setDistanceState((item: any) => ({...item, loadCityId: resp?.data?.city?.cityId}))
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
      })
  }

  const getDistanceByCities = (fromCityId: number, toCityId: number, date?: number) => {
    advertServ
      .distanceFromCities(fromCityId, toCityId, date ?? 0)
      .then((resp: any) => {
        setDistanceInfo(resp?.data)
        if (resp?.data?.arrivalDate) {
          const dat = resp?.data?.arrivalDate
          // const arrivalTime = new Date(dat)?.toLocaleTimeString().split(':')
          formik.setFieldValue('destDate', format(dat, 'yyyy-MM-dd'))
          formik.setFieldValue('destNonFormatDate', dat)
          formik.setFieldValue(
            'destTime',
            new Date(dat).getHours() + ':' + new Date(dat).getMinutes()
          )
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' ' + e?.response?.data?.code)
      })
  }
  /** Create New Address api call End **/

  useEffect(() => {
    selectedCountry && getCityList(selectedCountry)
    setLoading(true)
  }, [selectedCountry])

  useEffect(() => {
    setSpecSelected([])
  }, [formik.values.trailerType])

  useEffect(() => {
    if (distanceState?.loadCityId && distanceState?.arrivalCityId) {
      setLoading(true)
      getDistanceByCities(distanceState?.loadCityId, distanceState?.arrivalCityId)
    }
  }, [distanceState?.loadCityId, distanceState?.arrivalCityId])

  useEffect(() => {
    setLoading(true)
    getCountryList()
    setLoading(true)
    getTrailerTypes()
    setLoading(true)
    getFloorTypes()
    setLoading(true)
    getFreightTypes()
    setLoading(true)
    getFreightContentTypes()
    setLoading(true)
    getFreightLoadTypes()
    setLoading(true)
    getFreightPackageTypes()
    setLoading(true)
    getAddressList()
    setLoading(true)
    getCurrencyList()
    setLoading(true)
    getGtips()
    setLoading(true)
    getNewAddressList()
    getConsigneeClientsList()
    getConsignorClientsList()
    getTransportTypeList()
  }, [])

  /*disabled days for arrive date input */
  let disDate = new Date(formik.values.loadNonFormatDate)

  const vehicleDisableCheck = () =>
    !formik.values.freightType || !formik.values.transportType
  /*!formik.values.title ||
    !formik.values
      .trailerType || (formik.values.trailerType === '1' ? false : formik.values.trailerSpecification.length < 1) || !formik.values.floorType */

  const timeDisableCheck = () =>
    !formik.values.loadDate ||
    !formik.values.loadTime ||
    !formik.values.destDate ||
    !formik.values.destTime
  const locationDisableCheck = () => {
    if (formik.values.loadAddress && formik.values.destinationAddress) {
      return false
    }
    if (
      formik.values.fromCountry &&
      formik.values.fromCity &&
      formik.values.toCountry &&
      formik.values.toCity
    ) {
      return false
    }
    return true
  }
  const loadDisableCheck = () => !formik.values.freightContentTypes
  /*!formik.values.value ||
    !formik.values.currency ||
    !formik.values.freightType ||
    !formik.values.transportType ||
    !formik.values.freightPackageType ||
    !formik.values.freightLoadType ||
    !formik.values.freightLength ||
    !formik.values.width ||
    !formik.values.height ||
    !formik.values.weight ||
    !formik.values.desi ||
    !formik.values.ldm ||
    !formik.values.harmonizedSystemCode */

  const controlTheTime = (val: string) => {
    let str1: any = val + ':00'
    let str2: any = formik.values.destTime + ':00'

    str1 = str1.split(':')
    str2 = str2.split(':')

    let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0])
    let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0])

    if (totalSeconds1 < totalSeconds2) {
      toast.warning(intl.formatMessage({id: 'Address.create.time.warning'}))
      return false
    }
    return true
  }

  const vehicleContent = () => (
    <>
      <div>
        <div className={'row'}>
          <div className={'col-md-6 mb-10'}>
            <label htmlFor='freightType' className={'fs-4 mb-2 danger-text'}>
              {intl.formatMessage({id: 'Freight.createAdvert.freightType'})}
            </label>
            <select
              id={'freightType'}
              className='form-select'
              defaultValue={formik.values.freightType}
              {...formik.getFieldProps('freightType')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectLoadType'})}
              </option>
              {freightTypes &&
                freightTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {formik.touched.freightType && formik.errors.freightType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightType}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={'col-md-6 mb-10'}>
            <label htmlFor='transporterType' className={'fs-4 mb-2 danger-text'}>
              {intl.formatMessage({id: 'Carrier.transporters.create.transportType'})}
            </label>
            <Dropbox
              id={'transporterType'}
              {...formik.getFieldProps('transporterType')}
              hideArrow
              customClass={'form-select transporter-search-overlay'}
              placeholder={intl.formatMessage({
                id: 'Carrier.transporters.create.transportType.placeHolder',
              })}
              customSearchValue={transporterCustomSearch}
              customOnSearch={(e: any) => {
                setCurrentTransporterPageIndex(0)
                setTransporterCustomSearch(e.target.value)
                getTransportTypeList(0, e.target.value)
              }}
              onScroll={(val: any) => {
                setCurrentTransporterPageIndex(val)
                getTransportTypeList(val, transporterCustomSearch)
              }}
              setScrollCalled={() => {
                setTransporterTypeScrollAPICalled(true)
              }}
              scrollAPIResponse={transporterTypeScrollAPICalled}
              currentPageIndex={currentTransporterPageIndex}
              menuList={
                transporterTypeList &&
                transporterTypeList
                  .filter(function ({transportTypeId}: any) {
                    // @ts-ignore
                    return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                  }, {})
                  ?.map((item: any) => {
                    return {id: item?.transportTypeId, title: item?.description}
                  })
              }
              defaultSelected={{id: formik.values.transportType, title: selectedTransportTypeName}}
              defaultClearItem={{
                id: '',
                title: '',
              }}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  formik.setFieldValue('transportType', item?.id)
                  setSelectedTransportType(item?.id)
                  setSelectedTransportTypeName(item?.title)
                  setLoading(true)
                  getTrailerTypesData(item?.id)
                }

                if (item?.id === '' && item?.title === '') {
                  formik.setFieldValue('transportType', '')
                  setSelectedTransportType('')
                  setSelectedTransportTypeName('')
                }
              }}
            />
            {Object.keys(formik.touched).length > 0 && formik.errors.transportType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.transportType}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={' mb-10 '}>
          <label htmlFor='title' className={'text-muted fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.description'})}
          </label>
          <input
            type='text'
            id={'title'}
            placeholder={intl.formatMessage({id: 'Freight.createAdvert.description.placeholder'})}
            value={formik.values.title}
            onChange={(e) => formik.setFieldValue('title', e.target.value)}
            className={'form-control'}
          />
          {/* {formik.touched.title && formik.errors.title && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.title}
                </span>
              </div>
            </div>
          )} */}
        </div>
        <div className={'row'}>
          <div className={'col-md-6'}>
            <label htmlFor='trailerType' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.trailerType'})}i
            </label>
            <select
              id={'trailerType'}
              disabled={!formik.values.transportType}
              className='form-select'
              /*{...formik.getFieldProps('trailerType')}*/
              onChange={(e) => {
                getTrailerSpecifications(parseInt(e.target.value))
                setLoading(true)
                formik.setFieldValue('trailerType', e.target.value)
              }}
              value={formik.values.trailerType}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectTrailerType'})}
              </option>
              {trailerTypesData &&
                trailerTypesData?.map((item: any, key: number) => (
                  <option key={key} value={item?.trailerTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {/* {formik.touched.trailerType && formik.errors.trailerType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.trailerType}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-6 mb-10'}>
            <label htmlFor='trailerSpecification' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.specificationList'})}
            </label>
            <CheckGroup
              onChange={(e: any) => {
                if (e.target.checked) {
                  setSpecSelected((item: any) => [...item, Number(e?.target?.value)])
                } else {
                  setSpecSelected(specSelected.filter((itm: any) => itm !== e.target.value))
                }
              }}
              selectedItems={specSelected}
              data={
                trailerSpecifications &&
                trailerSpecifications?.map((item: any) => {
                  return {
                    id: item?.specificationId,
                    value: item?.specificationId,
                    label: item?.description,
                  }
                })
              }
              placeholder={intl.formatMessage({
                id: 'Freight.createAdvert.specificationListWarning',
              })}
            />

            {/* {formik.touched.trailerSpecification && formik.errors.trailerSpecification && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.trailerSpecification}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-6'}>
            <label htmlFor='floorType' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.floorType'})}
            </label>
            <select id={'floorType'} className='form-select' {...formik.getFieldProps('floorType')}>
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectFloorType'})}
              </option>
              {floorTypes &&
                floorTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.floorTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {/* {formik.touched.floorType && formik.errors.floorType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.floorType}
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  )

  const consignContent = () => (
    <>
      {/* <SidebarGeneral /> */}
      <ul
        className='sidebar-nav nav nav-tabs pt-5 px-5 justify-content-left'
        id='kt_sidebar_tabs'
        role='tablist'
      >
        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setActiveClientTab(0)
            }}
            className={`nav-main-wrap ${
              activeClientTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'
            }`}
            id='kt_sidebar_tab_1'
          >
            {intl.formatMessage({id: 'Carrier.freight.tab.existing'})}
          </a>
        </li>

        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setActiveClientTab(1)
            }}
            className={`nav-main-wrap ${
              activeClientTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'
            }`}
            id='kt_sidebar_tab_2'
          >
            {intl.formatMessage({id: 'Carrier.freight.tab.new'})}
          </a>
        </li>
      </ul>

      {/* begin::Sidebar Content */}
      <div id='kt_sidebar_content' className='border-wrap py-10 px-5 px-lg-5'>
        <div
          className='me-lg-n2 pe-lg-2'
          data-kt-scroll='true'
          data-kt-scroll-offset='10px'
          data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
          data-kt-scroll-wrappers='#kt_sidebar_content'
        >
          <div className='tab-content'>
            <div
              className={clsx('tab-pane', {active: activeClientTab === 0})}
              id='kt_sidebar_tab_pane_1'
              role='tabpanel'
            >
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div className={'mb-7'}>
                    <label htmlFor='consignorReferenceClientId' className={'text-muted fs-4 mb-2'}>
                      {intl.formatMessage({id: 'Carrier.freight.create.consignor.client'})}
                    </label>
                    <Dropbox
                      hideArrow
                      id={'consignorReferenceClientId'}
                      customClass={'form-select'}
                      placeholder={intl.formatMessage({
                        id: 'Carrier.freight.create.consignor.client.select',
                      })}
                      customSearchValue={consignorClientCustomSearch}
                      customOnSearch={(e: any) => {
                        setConsignorClientPageIndex(0)
                        setConsignorClientCustomSearch(e.target.value)
                        getConsignorClientsList(0, e.target.value)
                      }}
                      onScroll={(val: any) => {
                        setConsignorClientPageIndex(val)
                        getConsignorClientsList(val, consignorClientCustomSearch)
                      }}
                      setScrollCalled={() => {
                        setConsignorClientScrollAPICalled(true)
                      }}
                      scrollAPIResponse={consignorClientScrollAPICalled}
                      currentPageIndex={consignorClientPageIndex}
                      menuList={
                        consignorCompanyList &&
                        consignorCompanyList
                          .filter(function ({clientId}: any) {
                            // @ts-ignore
                            return !this[clientId] && (this[clientId] = clientId)
                          }, {})
                          ?.map((item: any) => {
                            return {id: item?.clientId, title: item?.name}
                          })
                      }
                      defaultSelected={{
                        id: formik.values.consignorReferenceClientId,
                        title: selectedConsignorName,
                      }}
                      defaultClearItem={{
                        id: '',
                        title: '',
                      }}
                      selectedItemData={(item: any) => {
                        if (item?.id) {
                          formik.setFieldValue('consignorReferenceClientId', item?.id)
                          setSelectedConsignorName(item?.title)
                        }

                        if (item?.id === '' && item?.title === '') {
                          formik.setFieldValue('consignorReferenceClientId', '')
                          setSelectedConsignorName('')
                        }
                      }}
                    />
                    {formik.touched.consignorReferenceClientId &&
                      formik.errors.consignorReferenceClientId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.consignorReferenceClientId}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className={'col-md-6'}>
                  <div className={'mb-7'}>
                    <label htmlFor='consigneeReferenceClientId' className={'text-muted fs-4 mb-2'}>
                      {intl.formatMessage({id: 'Carrier.freight.create.consignee.client'})}
                    </label>
                    <Dropbox
                      hideArrow
                      id={'consigneeReferenceClientId'}
                      customClass={'form-select'}
                      placeholder={intl.formatMessage({
                        id: 'Carrier.freight.create.consignee.client.select',
                      })}
                      customSearchValue={consigneeClientCustomSearch}
                      customOnSearch={(e: any) => {
                        setConsigneeClientPageIndex(0)
                        setConsigneeClientCustomSearch(e.target.value)
                        getConsigneeClientsList(0, e.target.value)
                      }}
                      onScroll={(val: any) => {
                        setConsigneeClientPageIndex(val)
                        getConsigneeClientsList(val, consignorClientCustomSearch)
                      }}
                      setScrollCalled={() => {
                        setConsigneeClientScrollAPICalled(true)
                      }}
                      scrollAPIResponse={consigneeClientScrollAPICalled}
                      currentPageIndex={consigneeClientPageIndex}
                      menuList={
                        consigneeCompanyList &&
                        consigneeCompanyList
                          .filter(function ({clientId}: any) {
                            // @ts-ignore
                            return !this[clientId] && (this[clientId] = clientId)
                          }, {})
                          ?.map((item: any) => {
                            return {id: item?.clientId, title: item?.name}
                          })
                      }
                      defaultSelected={{
                        id: formik.values.consigneeReferenceClientId,
                        title: selectedConsigneeName,
                      }}
                      defaultClearItem={{
                        id: '',
                        title: '',
                      }}
                      selectedItemData={(item: any) => {
                        if (item?.id) {
                          formik.setFieldValue('consigneeReferenceClientId', item?.id)
                          setSelectedConsigneeName(item?.title)
                        }

                        if (item?.id === '' && item?.title === '') {
                          formik.setFieldValue('consigneeReferenceClientId', '')
                          setSelectedConsigneeName('')
                        }
                      }}
                    />
                    {formik.touched.consigneeReferenceClientId &&
                      formik.errors.consigneeReferenceClientId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.consigneeReferenceClientId}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={clsx('tab-pane', {active: activeClientTab === 1})}
              id='kt_sidebar_tab_pane_2'
              role='tabpanel'
            >
              <div>
                <div className='row d-flex justify-content-between'>
                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignor'})}
                  </h4>

                  <h4 className={'form-label col-md-6 fw-bolder text-dark fs-3'}>
                    {intl.formatMessage({id: 'Carrier.freight.details.consignee'})}
                  </h4>
                </div>
                <hr />
                <div className={'row'}>
                  <div className='col-md-6'>
                    <div className='row consignor-wrap'>
                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorName', event.target.value)
                          }
                          value={formik.values.consignorName}
                          type='text'
                          name='consignorName'
                          autoComplete='off'
                        />
                        {formik.touched.consignorName && formik.errors.consignorName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.surName'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorSurName', event.target.value)
                          }
                          value={formik.values.consignorSurName}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consignorSurName && formik.errors.consignorSurName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorSurName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={'col-md-4'}>
                        <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                        </label>
                        <PhoneInp
                          id={'consignorCountryCode'}
                          onSelect={(event: any) => {
                            formik.setFieldValue(
                              'consignorCountryCode',
                              event.length > 0 ? '+' + event : event
                            )
                          }}
                          className='form-control form-control-lg form-control-solid w-100 bg-white'
                        />
                        {formik.touched.consignorCountryCode &&
                          formik.errors.consignorCountryCode && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' style={{color: '#DC3444'}}>
                                  {formik.errors.consignorCountryCode}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='fv-row col-md-8 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'Carrier.drivers.create.phone.placeHolder',
                          })}
                          maxLength={13}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) => {
                            if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                              formik.setFieldValue('consignorPhone', event.target.value)
                            }
                          }}
                          value={formik.values.consignorPhone}
                          type='text'
                          name='consignorPhone'
                          autoComplete='off'
                          required
                        />
                        {formik.touched.consignorPhone && formik.errors.consignorPhone && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorPhone}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='mb-10 col-md-12 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'CreateClient.create.email'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorEmail', event.target.value)
                          }
                          value={formik.values.consignorEmail}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consignorEmail && formik.errors.consignorEmail && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorEmail}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorTaxNumber', event.target.value)
                          }
                          value={formik.values.consignorTaxNumber}
                          type='text'
                          name='consignorTaxNumber'
                          autoComplete='off'
                        />
                        {formik.touched.consignorTaxNumber && formik.errors.consignorTaxNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorTaxNumber}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consignorTaxOffice', event.target.value)
                          }
                          value={formik.values.consignorTaxOffice}
                          type='text'
                          name='consignorTaxOffice'
                          autoComplete='off'
                        />
                        {formik.touched.consignorTaxOffice && formik.errors.consignorTaxOffice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consignorTaxOffice}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='row consignee-wrap'>
                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.name.name'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.name.name'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeName', event.target.value)
                          }
                          value={formik.values.consigneeName}
                          type='text'
                          name='consigneeName'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeName && formik.errors.consigneeName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'CreateClient.create.surName'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.surName'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeSurName', event.target.value)
                          }
                          value={formik.values.consigneeSurName}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeSurName && formik.errors.consigneeSurName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeSurName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={'col-md-4'}>
                        <label htmlFor={'phoneCode'} className='text-muted fs-4 mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phoneCode'})}
                        </label>
                        <PhoneInp
                          id={'consigneeCountryCode'}
                          onSelect={(event: any) => {
                            formik.setFieldValue(
                              'consigneeCountryCode',
                              event.length > 0 ? '+' + event : event
                            )
                          }}
                          className='form-control form-control-lg form-control-solid w-100 bg-white'
                        />
                        {formik.touched.consigneeCountryCode &&
                          formik.errors.consigneeCountryCode && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' style={{color: '#DC3444'}}>
                                  {formik.errors.consigneeCountryCode}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='fv-row col-md-8 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'Carrier.drivers.create.phone'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'Carrier.drivers.create.phone.placeHolder',
                          })}
                          maxLength={13}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) => {
                            if (event.target.value.match(/^\+?(?:[\d]*)$/)) {
                              formik.setFieldValue('consigneePhone', event.target.value)
                            }
                          }}
                          value={formik.values.consigneePhone}
                          type='text'
                          name='consigneePhone'
                          autoComplete='off'
                          required
                        />
                        {formik.touched.consigneePhone && formik.errors.consigneePhone && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneePhone}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='mb-10 col-md-12 mb-10'>
                        <label className='fs-4 text-muted mb-2'>
                          {intl.formatMessage({id: 'CreateClient.create.email'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'CreateClient.create.email'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeEmail', event.target.value)
                          }
                          value={formik.values.consigneeEmail}
                          type='text'
                          name='name'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeEmail && formik.errors.consigneeEmail && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeEmail}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxNumber'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeTaxNumber', event.target.value)
                          }
                          value={formik.values.consigneeTaxNumber}
                          type='text'
                          name='consigneeTaxNumber'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeTaxNumber && formik.errors.consigneeTaxNumber && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeTaxNumber}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='col-md-6 fv-row mb-10'>
                        <label className='fs-4 text-muted'>
                          {intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'Admin.Companies.Detail.taxOffice'})}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                          )}
                          onChange={(event: any) =>
                            formik.setFieldValue('consigneeTaxOffice', event.target.value)
                          }
                          value={formik.values.consigneeTaxOffice}
                          type='text'
                          name='consigneeTaxOffice'
                          autoComplete='off'
                        />
                        {formik.touched.consigneeTaxOffice && formik.errors.consigneeTaxOffice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' style={{color: '#DC3444'}}>
                                {formik.errors.consigneeTaxOffice}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  useEffect(() => {
    formik.values.fromCountry &&
      setTimeout(
        () =>
          (!formik.values.fromCity || formik.values.fromCity.length > 0) &&
          getCityList(formik.values.fromCountry, 'fromCity'),
        200
      )
    if (requiredLocationContent) {
      setRequiredLocationContent(false)
    }
  }, [formik.values.fromCountry])

  useEffect(() => {
    formik.values.toCountry &&
      setTimeout(
        () =>
          (!formik.values.toCity || formik.values.toCity.length > 0) &&
          getCityList(formik.values.toCountry, 'toCity'),
        200
      )
    if (requiredLocationContent) {
      setRequiredLocationContent(false)
    }
  }, [formik.values.toCountry])

  const setTab = (tabId: number) => {
    setActiveTab(tabId)
  }

  const locationContent = () => (
    <>
      {/* <SidebarGeneral /> */}
      <ul
        className='sidebar-nav nav nav-tabs pt-5 px-5 justify-content-left'
        id='kt_sidebar_tabs'
        role='tablist'
      >
        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setTab(0)
            }}
            className={`nav-main-wrap ${activeTab === 0 ? 'active-tab-wrap' : 'nav-normal-wrap'}`}
            id='kt_sidebar_tab_1'
          >
            {intl.formatMessage({id: 'Carrier.freight.create.existingAddress'})}
          </a>
        </li>

        <li className='nav-item tab-wrapper'>
          <a
            onClick={() => {
              setTab(1)
            }}
            className={`nav-main-wrap ${activeTab === 1 ? 'active-tab-wrap' : 'nav-normal-wrap'}`}
            id='kt_sidebar_tab_2'
          >
            {intl.formatMessage({id: 'Carrier.freight.create.newAddress'})}
          </a>
        </li>
      </ul>

      {/* begin::Sidebar Content */}
      <div id='kt_sidebar_content' className='py-10 px-5 px-lg-5'>
        <div
          className='me-lg-n2 pe-lg-2'
          data-kt-scroll='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-offset='10px'
          data-kt-scroll-dependencies='#kt_sidebar_tabs, #kt_sidebar_footer'
          data-kt-scroll-wrappers='#kt_sidebar_content'
        >
          <div className='tab-content'>
            <div
              className={clsx('tab-pane', {active: activeTab === 0})}
              id='kt_sidebar_tab_pane_1'
              role='tabpanel'
            >
              <div
                className={
                  'mb-5 d-flex ' +
                  (distanceInfo ? 'justify-content-between' : 'justify-content-end')
                }
              >
                {distanceInfo ? (
                  <div className='distance-wrap'>
                    <DistanceCard />
                  </div>
                ) : null}
                <button
                  type='submit'
                  className='btn btn-lg btn-info btn-distance-wrap'
                  onClick={() => {
                    setShowAddressPopup(true)
                  }}
                >
                  {intl.formatMessage({id: 'Freight.createAdvert.addNewAddress'})}
                </button>
              </div>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div className='col-md-12'>
                    <div className={'mb-7'}>
                      <label htmlFor='loadAddress' className={'danger-text fs-4 mb-2'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.departureLocation'})}
                      </label>
                      <Dropbox
                        hideArrow
                        id={'loadAddress'}
                        customClass={'form-select'}
                        placeholder={intl.formatMessage({
                          id: 'Freight.createAdvert.selectDownloadLoc',
                        })}
                        customSearchValue={companyDeptCustomSearch}
                        customOnSearch={(e: any) => {
                          setCurrentDeptLocPageIndex(0)
                          setCompanyDeptCustomSearch(e.target.value)
                          getNewAddressList(0, e.target.value)
                        }}
                        onScroll={(val: any) => {
                          setCurrentDeptLocPageIndex(val)
                          getNewAddressList(val, companyDeptCustomSearch)
                        }}
                        setScrollCalled={() => {
                          setFromAddressScrollAPICalled(true)
                        }}
                        scrollAPIResponse={fromAddressScrollAPICalled}
                        currentPageIndex={currentDeptLocPageIndex}
                        menuList={
                          ownerCompanyList &&
                          ownerCompanyList
                            .filter(function ({addressId}: any) {
                              // @ts-ignore
                              return !this[addressId] && (this[addressId] = addressId)
                            }, {})
                            ?.map((item: any) => {
                              return {id: item?.addressId, title: item?.description}
                            })
                        }
                        defaultClearItem={{
                          id: '',
                          title: '',
                        }}
                        defaultSelected={{
                          id: formik.values.loadAddress,
                          title: selectedLoadAddressName,
                        }}
                        selectedItemData={(item: any) => {
                          if (item?.id) {
                            formik.setFieldValue('loadAddress', item?.id)
                            setSelectedLoadAddressName(item?.title)
                            setLoading(true)
                            setRequiredLocationContent(false)
                            getAddressesDetail('departure', parseInt(item?.id))
                          }

                          if (item?.id === '' && item?.title === '') {
                            formik.setFieldValue('loadAddress', item?.id)
                            setSelectedLoadAddressName(item?.title)
                            setAddressesDetail({...addressesDetail, departureDetail: null})
                          }
                        }}
                      />
                      {formik.touched.loadAddress && formik.errors.loadAddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.loadAddress}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    {addressesDetail?.departureDetail && (
                      <div className={'col-md-12 fromDetailsWrap'}>
                        <div className={'row border border-2 rounded py-4 px-2'}>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({
                                id: 'Freight.createAdvert.departure.explanation',
                              })}
                            </p>
                            <b>{addressesDetail?.departureDetail?.description}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.departure.country'})}
                            </p>
                            <b>{addressesDetail?.departureDetail?.country?.name}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.departure.city'})}
                            </p>
                            <b>{addressesDetail?.departureDetail?.city?.name}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.departure.town'})}
                            </p>
                            <b>{addressesDetail?.departureDetail?.town}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.departure.district'})}
                            </p>
                            <b>{addressesDetail?.departureDetail?.district}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.departure.postCode'})}
                            </p>
                            <b>{addressesDetail?.departureDetail?.postCode}</b>
                          </div>
                          <hr />
                          <div className={'mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({
                                id: 'Freight.createAdvert.departure.fullAddress',
                              })}
                            </p>
                            <b>{addressesDetail?.departureDetail?.addressText}</b>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={'col-md-6'}>
                  <div className='col-md-12'>
                    <div className={'mb-7'}>
                      <label htmlFor='trailerType' className={'danger-text fs-4 mb-2'}>
                        {intl.formatMessage({id: 'Freight.createAdvert.arrivalLocation'})}
                      </label>

                      <Dropbox
                        hideArrow
                        id={'destinationAddress'}
                        customClass={'form-select'}
                        placeholder={intl.formatMessage({
                          id: 'Freight.createAdvert.selectArrivalLoc',
                        })}
                        customSearchValue={companyArrivalSearch}
                        customOnSearch={(e: any) => {
                          setCurrentDeptLocPageIndex(0)
                          setCompanyArrivalSearch(e.target.value)
                          getNewAddressList(0, e.target.value)
                        }}
                        disabled={!formik.values.loadAddress}
                        onScroll={(val: any) => {
                          setCurrentDeptLocPageIndex(val)
                          getNewAddressList(val, companyArrivalSearch)
                        }}
                        setScrollCalled={() => {
                          setToAddressScrollAPICalled(true)
                        }}
                        scrollAPIResponse={toAddressScrollAPICalled}
                        currentPageIndex={currentDeptLocPageIndex}
                        menuList={
                          ownerCompanyList &&
                          ownerCompanyList
                            .filter(function ({addressId}: any) {
                              // @ts-ignore
                              return !this[addressId] && (this[addressId] = addressId)
                            }, {})
                            ?.map((item: any) => {
                              return {id: item?.addressId, title: item?.description}
                            })
                            .filter((item: any) => parseInt(formik.values.loadAddress) !== item.id)
                        }
                        defaultSelected={{
                          id: formik.values.destinationAddress,
                          title: selectedDestinationAddressName,
                        }}
                        defaultClearItem={{
                          id: '',
                          title: '',
                        }}
                        selectedItemData={(item: any) => {
                          if (item?.id) {
                            formik.setFieldValue('destinationAddress', item?.id)
                            setSelectedDestinationAddressName(item?.title)
                            setLoading(true)
                            getAddressesDetail('arrival', parseInt(item?.id))
                          }

                          if (item?.id === '' && item?.title === '') {
                            formik.setFieldValue('destinationAddress', '')
                            setSelectedDestinationAddressName('')
                            setAddressesDetail({...addressesDetail, arrivalDetail: null})
                          }
                        }}
                      />

                      {formik.touched.destinationAddress && formik.errors.destinationAddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' style={{color: '#DC3444'}}>
                              {formik.errors.destinationAddress}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    {addressesDetail?.arrivalDetail && (
                      <div className={'col-md-12 toDetailsWrap'}>
                        <div className={'row border border-2 rounded py-4 px-2'}>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.explanation'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.description}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.country'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.country?.name}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.city'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.city?.name}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.district'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.town}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.dis'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.district}</b>
                          </div>
                          <div className={'col-md-4 mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.postCode'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.postCode}</b>
                          </div>
                          <hr />
                          <div className={'mb-5 '}>
                            <p className={'mb-0 text-muted'}>
                              {intl.formatMessage({id: 'Freight.createAdvert.arrival.fullAddress'})}
                            </p>
                            <b>{addressesDetail?.arrivalDetail?.addressText}</b>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={clsx('tab-pane', {active: activeTab === 1})}
              id='kt_sidebar_tab_pane_2'
              role='tabpanel'
            >
              {/* {requiredLocationContent ? (
        <h2 className='main-error-wrapper'>
          <span className='danger-text'>Select from existing addresses Or Select from Map</span>
        </h2>
      ) : (
        <hr />
      )} */}

              <div className='row'>
                <div className='col-md-6'>
                  <div className='row'>
                    {/* from address */}
                    <div className='col-md-6 mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                      </label>
                      <select
                        placeholder={intl.formatMessage({
                          id: 'addNewAddressPopup.area2.country.placeholder',
                        })}
                        {...formik.getFieldProps('fromCountry')}
                        className={'form-control form-control-lg'}
                        onChange={(e: any) => {
                          formik.setFieldValue('fromCountry', e.target.value)
                          formik.setFieldValue('fromCity', '')
                        }}
                      >
                        <option value='' selected disabled>
                          {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                        </option>
                        {countries &&
                          countries?.map((item: any, key: number) => (
                            <option
                              key={key}
                              /*selected={country === item?.code}*/ value={item?.code}
                            >
                              {item?.name}
                            </option>
                          ))}
                      </select>
                      {formik.touched?.fromCountry && formik.errors?.fromCountry && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className={'text-danger'} role='alert'>
                              {formik.errors?.fromCountry}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-5 mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                      </label>
                      <select
                        disabled={!formik.values.fromCountry}
                        placeholder={intl.formatMessage({
                          id: 'addNewAddressPopup.area2.city.placeholder',
                        })}
                        {...formik.getFieldProps('fromCity')}
                        className={'form-control form-control-lg'}
                      >
                        <option value='' selected={!formik.values.fromCity} disabled>
                          {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                        </option>
                        {formik.values.fromCountry &&
                          fromCityList?.map((item: any) => (
                            <option key={item.cityId} value={item?.cityId}>
                              {item?.originalName}
                            </option>
                          ))}
                      </select>
                      {formik.touched.fromCity && formik.errors.fromCity && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className={'text-danger'} role='alert'>
                              {formik.errors.fromCity}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='d-flex col-md-1 align-items-center map-content-warp'>
                      <img
                        src='/media/google_maps.svg'
                        className='map-wrap'
                        onClick={() => setIsMapFromView(true)}
                      />
                    </div>
                    <div className={'form-check mb-4 col-md-6 show-address-wrap'}>
                      <input
                        type='checkbox'
                        id={'adr1'}
                        onChange={() => setShowFromAddress(!showFromAddress)}
                        checked={showFromAddress}
                        className={'form-check-input'}
                      />
                      <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
                        {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                      </label>
                    </div>
                  </div>

                  <div className='row'>
                    {showFromAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.district1.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('fromDistrict')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.district && formik.errors.district,
                            // },
                            // {
                            //     'is-valid': formik.touched.district && !formik.errors.district,
                            // },
                          )}
                        />
                        {/* {formik.touched.district && formik.errors.district && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>{formik.errors.district}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}

                    {showFromAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('fromNeighborhood')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.neighborhood && formik.errors.neighborhood,
                            // },
                            // {
                            //     'is-valid': formik.touched.neighborhood && !formik.errors.neighborhood,
                            // },
                          )}
                        />
                        {/* {formik.touched.neighborhood && formik.errors.neighborhood && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>{formik.errors.neighborhood}</span>
                    </div>
                </div>
          )} */}
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    {showFromAddress && (
                      <div className='col-md-12 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('fromPostCode')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.postCode && formik.errors.postCode,
                            // },
                            // {
                            //     'is-valid': formik.touched.postCode && !formik.errors.postCode,
                            // },
                          )}
                        />
                        {/* {formik.touched.postCode && formik.errors.postCode && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.postCode}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    {showFromAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.street.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('fromStreet')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.street && formik.errors.street,
                            // },
                            // {
                            //     'is-valid': formik.touched.street && !formik.errors.street,
                            // },
                          )}
                        />
                        {/* {formik.touched.street && formik.errors.street && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.street}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}

                    {showFromAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('fromStreetNumber')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber,
                            // },
                            // {
                            //     'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                            // },
                          )}
                        />
                        {/* {formik.touched.streetNumber && formik.errors.streetNumber && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.streetNumber}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row'>
                    {/* to Address */}
                    <div className='col-md-6 mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        {intl.formatMessage({id: 'addNewAddressPopup.area2.country'})}
                      </label>
                      <select
                        placeholder={intl.formatMessage({
                          id: 'addNewAddressPopup.area2.country.placeholder',
                        })}
                        {...formik.getFieldProps('toCountry')}
                        className={'form-control form-control-lg'}
                        onChange={(e: any) => {
                          formik.setFieldValue('toCountry', e.target.value)
                          formik.setFieldValue('toCity', '')
                        }}
                      >
                        <option value='' selected disabled>
                          {intl.formatMessage({id: 'Carrier.freight.create.country'})}
                        </option>
                        {countries?.map((item: any, key: number) => (
                          <option
                            key={key}
                            /*selected={country === item?.code}*/ value={item?.code}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched?.toCountry && formik.errors?.toCountry && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className={'text-danger'} role='alert'>
                              {formik.errors?.toCountry}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-5 mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        {intl.formatMessage({id: 'addNewAddressPopup.area2.city'})}
                      </label>
                      <select
                        disabled={!formik.values.toCountry}
                        placeholder={intl.formatMessage({
                          id: 'addNewAddressPopup.area2.city.placeholder',
                        })}
                        {...formik.getFieldProps('toCity')}
                        className={'form-control form-control-lg'}
                      >
                        <option value='' selected={!formik.values.toCity} disabled>
                          {intl.formatMessage({id: 'Carrier.freight.create.city'})}
                        </option>
                        {formik.values.toCountry &&
                          toCityList?.map((item: any) => (
                            <option key={item.cityId} value={item?.cityId}>
                              {item?.originalName}
                            </option>
                          ))}
                      </select>
                      {formik.touched.toCity && formik.errors.toCity && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className={'text-danger'} role='alert'>
                              {formik.errors.toCity}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='d-flex col-md-1 align-items-center map-content-warp'>
                      <img
                        src='/media/google_maps.svg'
                        className='map-wrap'
                        onClick={() => setIsMapToView(true)}
                      />
                    </div>
                    <div className={'form-check mb-4 col-md-6 show-address-wrap'}>
                      <input
                        type='checkbox'
                        id={'adr2'}
                        onChange={() => setShowToAddress(!showToAddress)}
                        checked={showToAddress}
                        className={'form-check-input '}
                      />
                      <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                        {intl.formatMessage({id: 'Carrier.freight.showAddress'})}
                      </label>
                    </div>
                  </div>

                  <div className='row'>
                    {showToAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.district1'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.district1.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('toDistrict')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.district && formik.errors.district,
                            // },
                            // {
                            //     'is-valid': formik.touched.district && !formik.errors.district,
                            // },
                          )}
                        />
                        {/* {formik.touched.district && formik.errors.district && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>{formik.errors.district}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}

                    {showToAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.neighborhood'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.neighborhood.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('toNeighborhood')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.neighborhood && formik.errors.neighborhood,
                            // },
                            // {
                            //     'is-valid': formik.touched.neighborhood && !formik.errors.neighborhood,
                            // },
                          )}
                        />
                        {/* {formik.touched.neighborhood && formik.errors.neighborhood && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span className={'text-danger'} role='alert'>{formik.errors.neighborhood}</span>
                    </div>
                </div>
          )} */}
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    {showToAddress && (
                      <div className='col-md-12 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.postCode'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'statusAddNewAddressPopup.area2.postCode.placeHolder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('toPostCode')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.postCode && formik.errors.postCode,
                            // },
                            // {
                            //     'is-valid': formik.touched.postCode && !formik.errors.postCode,
                            // },
                          )}
                        />
                        {/* {formik.touched.postCode && formik.errors.postCode && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.postCode}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    {showToAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.street'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.street.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('toStreet')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.street && formik.errors.street,
                            // },
                            // {
                            //     'is-valid': formik.touched.street && !formik.errors.street,
                            // },
                          )}
                        />
                        {/* {formik.touched.street && formik.errors.street && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.street}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}

                    {showToAddress && (
                      <div className='col-md-6 mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {intl.formatMessage({id: 'addNewAddressPopup.area2.streetNumber'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'addNewAddressPopup.area2.streetNumber.placeholder',
                          })}
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('toStreetNumber')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid bg-white'
                            // {
                            //     'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber,
                            // },
                            // {
                            //     'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                            // },
                          )}
                        />
                        {/* {formik.touched.streetNumber && formik.errors.streetNumber && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block '>
                        <span className={'text-danger'} role='alert'>{formik.errors.streetNumber}</span>
                    </div>
                </div>
            )} */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Sidebar Content */}
    </>
  )

  const timeContent = () => (
    <>
      <div className={'w-100 mb-5 d-flex justify-content-center'}>
        {distanceInfo ? <DistanceCard /> : null}
      </div>
      <div className={'row mb-10'}>
        <div className={'col-md-5'}>
          <label htmlFor='loadDate' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.departureDate'})}
          </label>
          <DatePicker
            value={formik.values.loadNonFormatDate}
            onSelect={(data: any) => {
              formik.setFieldValue('loadDate', format(data, 'yyyy-MM-dd'))
              formik.setFieldValue('loadNonFormatDate', data)
              if (distanceState?.loadCityId && distanceState?.arrivalCityId) {
                setLoading(true)
                let myDate = new Date(
                  format(data, 'yyyy-MM-dd') + ' ' + (formik.values.loadTime ?? '00:00') + ':00'
                ).getTime()
                getDistanceByCities(distanceState?.loadCityId, distanceState?.arrivalCityId, myDate)
              }
            }}
            selected={!!formik.values.loadDate}
            customDate={formik.values.loadNonFormatDate}
            disabledDays={new Date()}
          />
          {formik.touched.loadDate && formik.errors.loadDate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.loadDate}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={'col-md-2'}></div>
        <div className={'col-md-5'}>
          <label htmlFor='destDate' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.arrivalDate'})}
          </label>
          <DatePicker
            disabled={!formik.values.loadDate}
            value={formik.values.destNonFormatDate}
            selected={!!formik.values.destDate}
            onSelect={(data: any) => {
              formik.setFieldValue('destDate', format(data, 'yyyy-MM-dd'))
              formik.setFieldValue('destNonFormatDate', data)
            }}
            customDate={formik.values.destNonFormatDate}
            disabledDays={disDate.setDate(disDate.getDate())}
          />
          {formik.touched.destDate && formik.errors.destDate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.destDate}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-5'}>
          <label htmlFor='loadTime' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.departureTime'})}
          </label>
          <MyTimePicker
            onChange={(val: string) => {
              formik.setFieldValue('loadTime', val)
              if (
                ((distanceState?.loadCityId && distanceState?.arrivalCityId) ||
                  (formik.values.fromCountry &&
                    formik.values.fromCity &&
                    formik.values.toCountry &&
                    formik.values.toCity)) &&
                val &&
                formik.values.loadDate &&
                formik.values.loadNonFormatDate
              ) {
                setLoading(true)
                let myDate = new Date(formik.values.loadDate + ' ' + val + ':00').getTime()
                getDistanceByCities(
                  distanceState?.loadCityId || formik.values.fromCity,
                  distanceState?.arrivalCityId || formik.values.toCity,
                  myDate
                )
              }
            }}
            value={formik.values?.loadTime}
          />
          {formik.touched.loadTime && formik.errors.loadTime && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.loadTime}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={'col-md-2'}></div>
        <div className={'col-md-5'}>
          <label htmlFor='loadTime' className={'danger-text fs-4 mb-2'}>
            {intl.formatMessage({id: 'Freight.createAdvert.arrivalTime'})}
          </label>
          <MyTimePicker
            onChange={(val: string) => {
              controlTheTime(val) && formik.setFieldValue('destTime', val)
            }}
            value={formik.values?.destTime}
            /*min={formik.values.loadTime ?? ''}*/
          />
          {formik.touched.destTime && formik.errors.destTime && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' style={{color: '#DC3444'}}>
                  {formik.errors.destTime}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )

  const handleScrollGtip = React.useMemo(
    () =>
      _.debounce((val) => {
        setCurrentPageIndex(val)
        getGtips(gtipSearch, val)
      }, 800),
    []
  )

  const loadContent = () => (
    <>
      <div>
        <div className='row'>
          <div className={'col-md-4 mb-10'}>
            <label
              htmlFor='freightLoadType'
              className={`${
                formik.errors.targetCost || formik.errors.targetCostCurrency
                  ? 'danger-text'
                  : 'text-muted'
              } fs-4 mb-2`}
            >
              {intl.formatMessage({id: 'Freight.createAdvert.targetCurrency'})}
            </label>
            <div className={'row'}>
              <div className={'col-md-7 mb-5'}>
                <input
                  type='number'
                  id={'targetCost'}
                  step='any'
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  placeholder={intl.formatMessage({id: 'Freight.createAdvert.enterValue'})}
                  value={formik.values.targetCost}
                  onChange={(e) => formik.setFieldValue('targetCost', e.target.value)}
                  min={0}
                  className={clsx('form-control', {
                    'price-input-wrap': formik.values.targetCost,
                  })}
                />
                {formik.touched.targetCost && formik.errors.targetCost && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.targetCost}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={'col-md-5'}>
                <select
                  id={'targetCostCurrency'}
                  className='form-select'
                  {...formik.getFieldProps('targetCostCurrency')}
                >
                  <option value='' selected disabled>
                    {intl.formatMessage({id: 'Freight.createAdvert.currency'})}
                  </option>
                  {currencyList &&
                    currencyList.map((item: any, key: number) => (
                      <option key={key} value={item?.currencyId}>
                        {item?.description}
                      </option>
                    ))}
                </select>
                {formik.touched.targetCostCurrency && formik.errors.targetCostCurrency && (
                  <div className='fv-plugins-message-container' style={{position: 'absolute'}}>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.targetCostCurrency}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={'col-md-4 mb-10'} />
          <div className={'col-md-4 mb-10'}>
            <label
              htmlFor='freightLoadType'
              className={`${
                formik.errors.value || formik.errors.currency ? 'danger-text' : 'text-muted'
              } fs-4 mb-2`}
            >
              {intl.formatMessage({id: 'Freight.createAdvert.valueCurrency'})}
            </label>
            <div className={'row'}>
              <div className={'col-md-7 mb-5'}>
                <input
                  type='number'
                  id={'value'}
                  step='any'
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  placeholder={intl.formatMessage({id: 'Freight.createAdvert.enterValue'})}
                  value={formik.values.value}
                  onChange={(e) => formik.setFieldValue('value', e.target.value)}
                  min={0}
                  className={clsx('form-control', {
                    'price-input-wrap': formik.values.value,
                  })}
                />
                {formik.touched.value && formik.errors.value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.value}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={'col-md-5'}>
                <select
                  id={'currency'}
                  className='form-select'
                  {...formik.getFieldProps('currency')}
                >
                  <option value='' selected disabled>
                    {intl.formatMessage({id: 'Freight.createAdvert.currency'})}
                  </option>
                  {currencyList &&
                    currencyList.map((item: any, key: number) => (
                      <option key={key} value={item?.currencyId}>
                        {item?.description}
                      </option>
                    ))}
                </select>
                {formik.touched.currency && formik.errors.currency && (
                  <div className='fv-plugins-message-container' style={{position: 'absolute'}}>
                    <div className='fv-help-block'>
                      <span role='alert' style={{color: '#DC3444'}}>
                        {formik.errors.currency}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={'row'}>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='freightContentTypes' className={'danger-text fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
            </label>
            <select
              id={'freightContentTypes'}
              className='form-select'
              {...formik.getFieldProps('freightContentTypes')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectLoadContentType'})}
              </option>
              {freightContentTypes &&
                freightContentTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightContentTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {formik.touched.freightContentTypes && formik.errors.freightContentTypes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightContentTypes}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='freightPackageType' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.packagingType'})}
            </label>
            <select
              id={'freightPackageType'}
              className='form-select'
              {...formik.getFieldProps('freightPackageType')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectPackagingType'})}
              </option>
              {freightPackageTypes &&
                freightPackageTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightPackageTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {/* {formik.touched.freightPackageType && formik.errors.freightPackageType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightPackageType}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='freightLoadType' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.loadingType'})}
            </label>
            <select
              id={'freightLoadType'}
              className='form-select'
              {...formik.getFieldProps('freightLoadType')}
            >
              <option value='' selected disabled>
                {intl.formatMessage({id: 'Freight.createAdvert.selectUploadType'})}
              </option>
              {freightLoadTypes &&
                freightLoadTypes?.map((item: any, key: number) => (
                  <option key={key} value={item?.freightLoadingTypeId}>
                    {item?.description}
                  </option>
                ))}
            </select>
            {/* {formik.touched.freightLoadType && formik.errors.freightLoadType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightLoadType}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='harmonizedSystemCode' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.gTip'})}
            </label>
            <Dropbox
              hideArrow
              id={'harmonizedSystemCode'}
              customClass={'form-select'}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.gTip.placeHolder'})}
              customSearchValue={gtipSearch}
              customOnSearch={(e: any) => {
                setCurrentPageIndex(0)
                setGtipSearch(e.target.value)
                getGtips(e.target.value, 0)
              }}
              onScroll={(val: any) => {
                handleScrollGtip(val)
              }}
              setScrollCalled={() => {
                setHarmonizedCodeScrollAPICalled(true)
              }}
              scrollAPIResponse={harmonizedCodeScrollAPICalled}
              currentPageIndex={currentPageIndex}
              menuList={
                gtipList &&
                gtipList
                  .filter(function ({code}: any) {
                    // @ts-ignore
                    return !this[code] && (this[code] = code)
                  }, {})
                  .map((item: any) => {
                    return {id: item?.code, title: item?.code + ' - ' + item?.description}
                  })
              }
              defaultClearItem={{
                id: '',
                title: '',
              }}
              defaultSelected={{id: formik?.values?.harmonizedSystemCode}}
              selectedItemData={(item: any) => {
                if (item?.id) {
                  formik.setFieldValue('harmonizedSystemCode', item?.id)
                }

                if (item?.id === '' && item?.title === '') {
                  formik.setFieldValue('harmonizedSystemCode', '')
                }
              }}
            />
            {/* {formik.touched.harmonizedSystemCode && formik.errors.harmonizedSystemCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.harmonizedSystemCode}
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <hr />
        <div className={'row'}>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='freightLength' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.lengthValue'})}
            </label>
            <input
              type='number'
              id={'freightLength'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.lengthValue.placeHolder'})}
              value={formik.values.freightLength}
              min={1}
              onChange={(e) => formik.setFieldValue('freightLength', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.freightLength && formik.errors.freightLength && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.freightLength}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='width' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.widthValue'})}
            </label>
            <input
              type='number'
              id={'width'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.widthValue.placeHolder'})}
              value={formik.values.width}
              min={1}
              onChange={(e) => formik.setFieldValue('width', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.width && formik.errors.width && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.width}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='height' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.heightValue'})}
            </label>
            <input
              type='number'
              id={'height'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.heightValue.placeHolder'})}
              value={formik.values.height}
              min={1}
              onChange={(e) => formik.setFieldValue('height', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.height && formik.errors.height && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.height}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='weight' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.grossKg'})}
            </label>
            <input
              type='number'
              id={'weight'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.grossKg.placeHolder'})}
              value={formik.values.weight}
              min={1}
              onChange={(e) => formik.setFieldValue('weight', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.weight && formik.errors.weight && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.weight}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='desi' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.desi'})}
            </label>
            <input
              type='number'
              id={'desi'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.desi.placeHolder'})}
              value={formik.values.desi}
              min={1}
              onChange={(e) => formik.setFieldValue('desi', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.desi && formik.errors.desi && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.desi}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className={'col-md-4 mb-10'}>
            <label htmlFor='ldm' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Freight.createAdvert.ldm'})}
            </label>
            <input
              type='number'
              id={'ldm'}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.ldm.placeHolder'})}
              value={formik.values.ldm}
              min={1}
              onChange={(e) => formik.setFieldValue('ldm', e.target.value)}
              className={'form-control'}
            />
            {/* {formik.touched.ldm && formik.errors.ldm && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' style={{color: '#DC3444'}}>
                    {formik.errors.ldm}
                  </span>
                </div>
              </div>
            )} */}
          </div>

          <div className={'col-md-12 mb-10'}>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Freight.createAdvert.note'})}
            </label>
            <textarea
              rows={3}
              placeholder={intl.formatMessage({id: 'Freight.createAdvert.note.placeHolder'})}
              autoComplete='off'
              {...formik.getFieldProps('note')}
              className={clsx(
                'form-control form-control-lg form-control-solid bg-white',
                {
                  'is-invalid': formik.touched.note && formik.errors.note,
                },
                {
                  'is-valid': formik.touched.note && !formik.errors.note,
                }
              )}
            />
            {/* {formik.touched.note && formik.errors.note && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <span className={'text-danger'} role='alert'>{formik.errors.note}</span>
                </div>
            </div>
              )} */}
          </div>
        </div>
        <hr />
        <div className={'mt-10 mb-10'}>
          <div className={'form-check mb-4'}>
            <input
              type='checkbox'
              id={'adr1'}
              onChange={(e) => formik.setFieldValue('adr1', !formik.values.adr1)}
              checked={formik.values.adr1}
              className={'form-check-input '}
            />
            <label htmlFor='adr1' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Freight.createAdvert.productContaining'})}
            </label>
          </div>
          <div className={'form-check'}>
            <input
              type='checkbox'
              id={'istif'}
              onChange={(e) => formik.setFieldValue('stackable', !formik.values.stackable)}
              checked={formik.values.stackable}
              className={'form-check-input '}
            />
            <label htmlFor='istif' className={'text-muted fs-4 ml-3 form-check-label'}>
              {intl.formatMessage({id: 'Freight.createAdvert.stackable'})}
            </label>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'Freight.createAdvert.createNewFreight'})}
      </PageTitle>
      <div>
        <TabManagerComponent
          onSubmit={() => {
            formik.handleSubmit()
          }}
          checkValidation={(value: any) => {
            if (value === 0) {
              if (!formik.values.transportType) {
                formik.setErrors({
                  ...formik?.errors,
                  transportType: intl.formatMessage({id: 'global.mandatory'}),
                })
                formik.setTouched({...formik.touched, transportType: true})
              }
              if (!formik.values.freightType) {
                formik.setErrors({
                  ...formik?.errors,
                  freightType: intl.formatMessage({id: 'global.mandatory'}),
                })
                formik.setTouched({...formik.touched, freightType: true})
              }
            }

            if (value === 2) {
              let errors = {}
              let touched = {}
              if (!formik.values.destinationAddress) {
                errors = Object.assign(
                  {destinationAddress: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({destinationAddress: true}, touched)
              }

              if (!formik.values.loadAddress) {
                errors = Object.assign(
                  {loadAddress: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({loadAddress: true}, touched)
              }

              if (!formik.values.fromCountry) {
                errors = Object.assign(
                  {fromCountry: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({fromCountry: true}, touched)
              }

              if (!formik.values.fromCity) {
                errors = Object.assign(
                  {fromCity: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({fromCity: true}, touched)
              }

              if (!formik.values.toCountry) {
                errors = Object.assign(
                  {toCountry: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({toCountry: true}, touched)
              }

              if (!formik.values.toCity) {
                errors = Object.assign(
                  {toCity: intl.formatMessage({id: 'global.mandatory'})},
                  errors
                )
                touched = Object.assign({toCity: true}, touched)
              }

              if (
                !formik.values.destinationAddress &&
                !formik.values.loadAddress &&
                !formik.values.fromCountry &&
                !formik.values.toCountry &&
                !formik.values.fromCity &&
                !formik.values.toCity
              ) {
                setRequiredLocationContent(true)
              }
              formik.setErrors({...formik?.errors, ...errors})
              formik.setTouched({...formik.touched, ...touched})
            }

            if (value === 3) {
              let timeErrors = {}
              let timeTouched = {}

              if (!formik.values.loadDate) {
                timeErrors = Object.assign(
                  {loadDate: intl.formatMessage({id: 'global.mandatory'})},
                  timeErrors
                )
                timeTouched = Object.assign({loadDate: true}, timeTouched)
              }

              if (!formik.values.destDate) {
                timeErrors = Object.assign(
                  {destDate: intl.formatMessage({id: 'global.mandatory'})},
                  timeErrors
                )
                timeTouched = Object.assign({destDate: true}, timeTouched)
              }

              if (!formik.values.loadTime) {
                timeErrors = Object.assign(
                  {loadTime: intl.formatMessage({id: 'global.mandatory'})},
                  timeErrors
                )
                timeTouched = Object.assign({loadTime: true}, timeTouched)
              }

              if (!formik.values.destTime) {
                timeErrors = Object.assign(
                  {destTime: intl.formatMessage({id: 'global.mandatory'})},
                  timeErrors
                )
                timeTouched = Object.assign({destTime: true}, timeTouched)
              }
              formik.setErrors({...formik?.errors, ...timeErrors})
              formik.setTouched({...formik.touched, ...timeTouched})
            }
          }}
          data={[
            {
              title: intl.formatMessage({id: 'Freight.createAdvert.vehicleOption'}),
              disabled: vehicleDisableCheck(),
              content: vehicleContent(),
            },
            {
              title: intl.formatMessage({id: 'Carrier.freight.create.title'}),
              disabled: false,
              content: consignContent(),
            },
            {
              title: intl.formatMessage({id: 'Freight.createAdvert.fromWhereToWhere'}),
              disabled: locationDisableCheck(),
              content: locationContent(),
            },
            {
              title: intl.formatMessage({id: 'Freight.createAdvert.dateTime'}),
              disabled: timeDisableCheck(),
              content: timeContent(),
            },
            {
              title: intl.formatMessage({id: 'Freight.createAdvert.freightInformation'}),
              disabled: loadDisableCheck(),
              content: loadContent(),
            },
          ]}
        />
      </div>

      {showPreviewScreen && (
        <PreviewScreen
          formikValues={formik.values}
          showPreviewSuccess={showPreviewSuccess}
          addressList={addressList}
          trailerTypes={trailerTypes}
          floorTypes={floorTypes}
          transporterTypeList={transporterTypeList}
          selectedTransportType={selectedTransportType}
          trailerSpecifications={trailerSpecifications}
          currencyList={currencyList}
          freightTypes={freightTypes}
          transportTypes={transportTypes}
          freightPackageTypes={freightPackageTypes}
          freightLoadTypes={freightLoadTypes}
          gtipList={gtipList}
          createNewAdvert={(val: any) => val && createNewAdvert()}
          onClose={() => setShowPreviewScreen(false)}
        />
      )}

      {showAddressPopup && (
        <AddNewAddressPopup
          selectedCountry={(item: any) => setSelectedCountry(item)}
          countryList={countries && countries}
          cityList={cities && cities}
          onSubmit={(data: any) => {
            popupSubmit(data)
          }}
          onClose={() => setShowAddressPopup(false)}
        />
      )}

      {(isMapFromView || isMapToView) && (
        <CreateMapAddress
          handleClose={() => {
            if (isMapFromView) {
              setIsMapFromView(false)
            }

            if (isMapToView) {
              setIsMapToView(false)
            }
          }}
          setFillupAddress={(data: any) => {
            if (isMapFromView) {
              formik.setFieldValue('fromCountry', data.countryCode)
              formik.setFieldValue('fromCity', data.cityId)
              formik.setFieldValue('fromDistrict', data.district)
              formik.setFieldValue('fromNeighborhood', data.neighborhood)
              formik.setFieldValue('fromPostCode', data.postCode)
              formik.setFieldValue('fromStreet', data.street)
              formik.setFieldValue('fromStreetNumber', data.streetNumber)
              formik.setFieldValue('fromLatitude', data.latitude)
              formik.setFieldValue('fromLongitude', data.longitude)
            }

            if (isMapToView) {
              formik.setFieldValue('toCountry', data.countryCode)
              formik.setFieldValue('toCity', data.cityId)
              formik.setFieldValue('toDistrict', data.district)
              formik.setFieldValue('toNeighborhood', data.neighborhood)
              formik.setFieldValue('toPostCode', data.postCode)
              formik.setFieldValue('toStreet', data.street)
              formik.setFieldValue('toStreetNumber', data.streetNumber)
              formik.setFieldValue('toLatitude', data.latitude)
              formik.setFieldValue('toLongitude', data.longitude)
            }
          }}
        />
      )}
    </>
  )
}

export {CreateNewAdvert}
