import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import clsx from 'clsx'
import {toast} from 'react-toastify'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../Components/RightLayoutPopup'
import PreferredRoutes from './Popups/PreferredRoutes'
import Details from './Popups/Details'
import EditCompanies from './Popups/EditCompanies'
import {PageTitle} from '../../../../_metronic/layout/core'

import {CompanyService} from '../../../../services/AdminServices/CompanyService'

import useScrollLock from '../../../../utils/useScrollLock'
import {KTSVG} from '../../../../_metronic/helpers'

import './Companies.scss'
import ShowUserList from './Popups/ShowUserList'

const Companies = () => {
  const intl = useIntl()
  const companyServ = new CompanyService()
  const [loading, setLoading] = useState<boolean>(false)

  const [filter, setFilter] = useState<any>(null)
  const [editCompanies, setEditCompanies] = useState<any>(null)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [userPageMIndex, setUserPageMIndex] = useState<number>(0)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(-1)
  const [companies, setCompanies] = useState<any>([])
  const [companyStatuses, setCompanyStatuses] = useState<any>([])
  const [companyData, setCompanyData] = useState<any>({
    id: -1,
    companyName: '',
    type: '',
    country: '',
    status: '',
    website: '',
    taxNumber: '',
    taxOffice: '',
    billingAddress: '',
    authorizedName: '',
    authorizedEmail: '',
    authorizedPhone: '',
  })

  const [userListdata, setUserListData] = useState<any>([])
  const [transportTypeList, setTransportTypeList] = useState<any[]>([])

  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false)
  const [showUsersPopup, setShowUsersPoup] = useState<boolean>(false)

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isUserNextPage, setIsUserNextPage] = useState<boolean>(false)

  const [noPreferred, setNoPreferred] = useState<boolean>(false)
  const [noVehicle, setNoVehicle] = useState<boolean>(false)
  const [noTrailer, setNoTrailer] = useState<boolean>(false)
  const [noTransporter, setNoTransporter] = useState<boolean>(false)

  const [showPreferredRoute, setShowPreferredRoute] = useState<boolean>(false)

  const [preferredRouteList, setPreferredRouteList] = useState<any>([])
  const [preferredRoutesPageMIndex, setPreferredRoutesPageMIndex] = useState<number>(0)

  const {scrollUnlock} = useScrollLock()

  useEffect(() => {
    if (!showDetailPopup && !showEditPopup) scrollUnlock()
  }, [showDetailPopup, showEditPopup])

  const getPreferredRouteList = (companyId?: number, pageIndex?: number) => {
    companyServ
      .getPreferredRouteList(companyId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setPreferredRouteList(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getUserListData = (companyId?: number, pageIndex?: number) => {
    companyServ
      .getCompanyUserList(companyId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setIsUserNextPage(false)
        setUserListData(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsUserNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getTransportTypeList = () => {
    companyServ
      .transportTypesList()
      .then((resp: any) => {
        setTransportTypeList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getCompanies = (pageIndex?: number, searchText?: string) => {
    companyServ
      .getAllCompanies(
        pageIndex,
        searchText,
        noPreferred ? 'Y' : 'N',
        noVehicle ? 'Y' : 'N',
        noTrailer ? 'Y' : 'N',
        noTransporter ? 'Y' : 'N'
      )
      .then((resp: any) => {
        setCompanies(resp.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getCompanyStatuses = () => {
    companyServ
      .getCompanyStatutes()
      .then((resp: any) => {
        setCompanyStatuses(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }
  const updateCompanyStatus = (id: number, statusId: number) => {
    companyServ
      .updateCompanyStatus(id, statusId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.companies.status.update.success'}))
        getCompanies(pageMIndex)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getDetails = (id: number) => {
    return companyServ.getCompanyDetails(id)
  }
  const getInvoiceAddress = (id: number) => {
    return companyServ.getCompanyInvoiceAddress(id)
  }
  const getResponsibles = (id: number) => {
    return companyServ.getCompanyResponsibles(id)
  }

  const onPreferredRoutePaginationChange = (page: number) => {
    getPreferredRouteList(selectedCompanyId, page)
    setPreferredRoutesPageMIndex(page)
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getCompanies(page, filter)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const onPaginationUserListChange = (page: number) => {
    if (!isUserNextPage) {
      getUserListData(page)
      setUserPageMIndex(page)
    }
    setIsUserNextPage(true)
  }

  useEffect(() => {
    setLoading(true)
    getCompanies(pageMIndex, filter)
    getTransportTypeList()
  }, [])

  useEffect(() => {
    getCompanies(pageMIndex, filter)
  }, [noPreferred, noVehicle, noTrailer, noTransporter])

  const handleFilterSearch = React.useMemo(
    () =>
      _.debounce((_event, searchText) => {
        setLoading(true)
        getCompanies(0, searchText)
      }, 1000),
    [noPreferred, noVehicle, noTrailer, noTransporter]
  )

  return (
    <>
      <div className={'w-100'}>
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'SideMenu.systemOperator.companies'})}
        </PageTitle>
        {loading && <Loading />}

        <div className={`row ${showFilterPanel ? 'mb-0' : 'mb-5'}`}>
          <span className='menu-icon d-flex justify-content-end'>
            <div
              className={`btn btn-icon btn-active-light-primary btn-custom ${
                showFilterPanel ? 'menu-button-active' : ''
              }`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => setShowFilterPanel(!showFilterPanel)}
            >
              <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
            </div>
          </span>
        </div>

        {showFilterPanel && (
          <div className='row'>
            <div className='mb-10 col-md-3 filter-wrap-content-company'>
              <h6 className={'mb-3'}>{intl.formatMessage({id: 'Admin.Adverts.table_company'})}</h6>
              <div className={''}>
                <input
                  placeholder={intl.formatMessage({id: 'Admin.Adverts.table_company'})}
                  className={clsx('form-control form-control-lg')}
                  onChange={(event: any) => {
                    handleFilterSearch(event, event.target.value)
                    setFilter(event.target.value)
                  }}
                  value={filter}
                  type='text'
                />
              </div>
            </div>

            <div className='mb-10 row col-md-9 align-items-end'>
              <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                <input
                  type='checkbox'
                  id={'adr2'}
                  onChange={() => setNoPreferred(!noPreferred)}
                  checked={noPreferred}
                  className={'form-check-input '}
                />
                <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Carrier.freight.noPreferred'})}
                </label>
              </div>

              <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                <input
                  type='checkbox'
                  id={'adr2'}
                  onChange={() => setNoVehicle(!noVehicle)}
                  checked={noVehicle}
                  className={'form-check-input '}
                />
                <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Carrier.freight.noVehicle'})}
                </label>
              </div>

              <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                <input
                  type='checkbox'
                  id={'adr2'}
                  onChange={() => setNoTrailer(!noTrailer)}
                  checked={noTrailer}
                  className={'form-check-input '}
                />
                <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Carrier.freight.noTrailer'})}
                </label>
              </div>

              <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                <input
                  type='checkbox'
                  id={'adr2'}
                  onChange={() => setNoTransporter(!noTransporter)}
                  checked={noTransporter}
                  className={'form-check-input '}
                />
                <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                  {intl.formatMessage({id: 'Carrier.freight.noTransporter'})}
                </label>
              </div>
            </div>
          </div>
        )}

        {!companies.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-1'}),
              accessor: 'companyName',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-2'}),
              accessor: 'type',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-3'}),
              accessor: 'country',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-4'}),
              accessor: 'status',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.preferredCount'}),
              accessor: 'preferredRouteCount',
              cellStyle: {
                textAlign: 'center',
              },
              isAction: true,
              Cell: ({row}: any) => (
                <div
                  className={
                    row?.values?.preferredRouteCount !== '-' &&
                    row?.values?.preferredRouteCount !== 0
                      ? 'proposal-wrap'
                      : ''
                  }
                  onClick={() => {
                    if (
                      row?.values?.preferredRouteCount !== '-' &&
                      row?.values?.preferredRouteCount !== 0
                    ) {
                      setLoading(true)
                      setLoading(true)
                      setShowPreferredRoute(true)
                      setSelectedCompanyId(Number(row?.values?.id))
                      getPreferredRouteList(Number(row?.values?.id), preferredRoutesPageMIndex)
                    }
                  }}
                >
                  {row?.values?.preferredRouteCount}
                </div>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.vehicleCount'}),
              accessor: 'vehicleCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.trailerCount'}),
              accessor: 'trailerCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.transporterCount'}),
              accessor: 'transporterCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: 'Durum ID',
              accessor: 'statusId',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
          ]}
          data={companies?.map((item: any) => {
            return {
              id: item?.companyId,
              companyName: item?.name ?? '-',
              type: item?.companyType?.description ?? '-',
              country: item?.country?.name ?? '-',
              preferredRouteCount: item?.preferredRouteCount ?? '-',
              vehicleCount: item?.vehicleCount ?? '-',
              trailerCount: item?.trailerCount ?? '-',
              transporterCount: item?.transporterCount ?? '-',
              status: item?.companyStatus?.description ?? '-',
              statusId: item?.companyStatus?.companyStatusId,
              hiddenButtons: {
                proposalBtn: false,
              },
            }
          })}
          editableRows={['']}
          editBtn={(row: any) => {
            !companyStatuses?.length && setLoading(true)
            !companyStatuses?.length && getCompanyStatuses()
            setShowEditPopup(true)
            setSelectedCompanyId(row?.values?.id)
            setEditCompanies(row?.values?.status)
          }}
          proposalBtn={(row: any) => {
            setLoading(true)
            setShowPreferredRoute(true)
            setSelectedCompanyId(Number(row?.values?.id))
            getPreferredRouteList(Number(row?.values?.id), preferredRoutesPageMIndex)
          }}
          proposalBtnText={intl.formatMessage({id: 'Admin.companies.preferredRoutes.table.title'})}
          showDetailBtn={async (row: any) => {
            let id = row?.values?.id
            if (id) {
              setLoading(true)
              await getDetails(id)
                .then((resp: any) => {
                  const det = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    id: det?.companyId,
                    companyName: det?.name,
                    type: det?.companyType?.description,
                    country: det?.country?.name,
                    status: det?.companyStatus?.description,
                    website: det?.web,
                    taxNumber: det?.taxNumber,
                    taxOffice: det?.taxOffice,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e.response.data.message + ' | ' + e.response.data.code)
                })
              await getResponsibles(id)
                .then((resp: any) => {
                  const res = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    authorizedName: res[0]?.name + ' ' + res[0]?.surname,
                    authorizedEmail: res[0]?.email,
                    authorizedPhone: res[0]?.mobile,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e.response.data.message + ' | ' + e.response.data.code)
                })
              await getInvoiceAddress(id)
                .then((resp: any) => {
                  const inv = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    billingAddress: inv?.addressText,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e.response.data.message + ' | ' + e.response.data.code)
                })
              setLoading(false)
              setShowDetailPopup(true)
            }
          }}
          showFile={(row: any) => {
            setLoading(true)
            setShowUsersPoup(true)
            setSelectedCompanyId(row?.values?.id)
            getUserListData(Number(row?.values.id))
          }}
          showFileText={intl.formatMessage({id: 'Admin.company.user.showUser'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showPreferredRoute && (
        <PreferredRoutes
          selectedCompanyId={selectedCompanyId}
          onClose={() => setShowPreferredRoute(false)}
          preferredRouteList={preferredRouteList}
          pageMIndex={preferredRoutesPageMIndex}
          getPreferredRouteList={getPreferredRouteList}
          onPaginationChange={onPreferredRoutePaginationChange}
          setLoading={setLoading}
          getCompanies={getCompanies}
          transportTypeList={transportTypeList}
        />
      )}

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <Details allData={companyData} onClose={() => setShowDetailPopup(false)} />
      )}

      {showUsersPopup && (
        <ShowUserList
          userList={userListdata}
          selectedCompanyId={selectedCompanyId}
          getUserListData={getUserListData}
          setLoading={setLoading}
          onPaginationChange={onPaginationUserListChange}
          pageIndex={userPageMIndex}
          onClose={() => setShowUsersPoup(false)}
        />
      )}

      {/*EDIT POPUP*/}
      {showEditPopup && (
        <RightLayoutPopup
          title={intl.formatMessage({id: 'Admin.Companies.update.title'})}
          className='admin-edit-company-wrap'
          isCloseIcon
          closeOnClick={() => setShowEditPopup(false)}
        >
          <EditCompanies
            setFilter={setEditCompanies}
            filter={editCompanies}
            companyStatuses={companyStatuses}
            setLoading={setLoading}
            updateCompanyStatus={updateCompanyStatus}
            selectedCompanyId={selectedCompanyId}
            onClose={() => setShowEditPopup(false)}
          />
        </RightLayoutPopup>
      )}
    </>
  )
}

export default Companies
