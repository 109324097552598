import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import DataTableAdvanced from '../../../../Components/DataTableAdvanced'

import useScrollLock from '../../../../../utils/useScrollLock'

import {CompanyService} from '../../../../../services/AdminServices/CompanyService'
import EditUser from './EditUser'

const ShowUserList = ({
  onClose,
  userList,
  setLoading,
  selectedCompanyId,
  getUserListData,
  onPaginationChange,
  pageIndex,
}: any) => {
  const intl = useIntl()
  const companyServ = new CompanyService()

  const [selectedUserData, setSelectedUserData] = useState<any>({})
  const [selectedUserId, setSelectedUserId] = useState<any>(null)

  const [showEditUserPopup, setShowEditUserPopup] = useState<boolean>(false)

  const getSelectedUserDetail = (userId?: any) => {
    companyServ
      .getUserDetails(userId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedUserData(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const updateUserDetails = (data?: any) => {
    companyServ
      .updateUserDetails(data)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'Admin.company.user.update.success'}))
        getUserListData(selectedCompanyId)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const {scrollLock, scrollUnlock} = useScrollLock()
  useEffect(() => {
    scrollLock()
  }, [])

  return (
    <div className={''}>
      <RightLayoutPopup
        title={intl.formatMessage({id: 'SideMenu.freightOperator.user'})}
        className='admin-details-company-wrap'
        isCloseIcon
        closeOnClick={() => onClose()}
      >
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Freight.users.name'}),
              accessor: 'name',
            },

            {
              Header: intl.formatMessage({id: 'Freight.users.surName'}),
              accessor: 'surname',
            },

            {
              Header: intl.formatMessage({id: 'Freight.users.email'}),
              accessor: 'email',
            },

            {
              Header: intl.formatMessage({id: 'Freight.users.phone'}),
              accessor: 'mobile',
            },

            {
              Header: intl.formatMessage({id: 'Freight.users.countryCode'}),
              accessor: 'countryCode',
            },
            {
              Header: intl.formatMessage({id: 'Freight.users.defaultPass'}),
              accessor: 'initialPassword',
            },
          ]}
          editableRows={['name', 'surname', 'email', 'countryCode', 'mobile']}
          data={
            userList &&
            userList?.map((item: any) => {
              return {
                id: item?.userId ?? '',
                name: item?.name ?? '',
                surname: item?.surname ?? '',
                email: item?.email ?? '',
                countryCode: item?.countryCode,
                mobile: item?.mobile ?? '',
                initialPassword: item?.initialPassword ?? '',
              }
            })
          }
          editBtn={(row: any) => {
            setLoading(true)
            setSelectedUserId(row.values.id)
            setShowEditUserPopup(true)
            getSelectedUserDetail(row.values.id)
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageIndex}
        />

        {showEditUserPopup && Object.keys(selectedUserData).length > 0 && (
          <EditUser
            onClose={() => setShowEditUserPopup(false)}
            selectedUserData={selectedUserData}
            selectedUserId={selectedUserId}
            setLoading={setLoading}
            updateUserDetails={updateUserDetails}
          />
        )}
        <div className='text-center mt-5 row view-details-freight-popup freightPost-popup-container'>
          <div className={'col-md-6'}>
            <button
              onClick={() => {
                onClose()
                scrollUnlock()
              }}
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5 '
            >
              {intl.formatMessage({id: 'Carrier.freightPosting.view.close'})}
            </button>
          </div>
        </div>
      </RightLayoutPopup>
    </div>
  )
}

export default ShowUserList
