import React, {useEffect, useState} from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import {useIntl} from 'react-intl'
import {Tooltip} from 'react-tooltip'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'

import {PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'

import Loading from '../../../Components/Loading'
import Dropbox from '../../../Components/Dropbox'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import Modal from '../../../Components/Modal'
import CreateNewContainerPopup from '../../../Components/CreateNewContainerPopup'
import ShowDetails from './Popups/ShowDetails'
import CreateFreightProposalContainer from './Popups/CreateFreightProposalContainer'
import ShowFreightProposalTable from './Popups/ShowFreightProposalTable'
import FreightShowDetails from '../../Admin/Adverts/Popups/ShowDetails'
import CreateProposalContainer from './Popups/CreateProposalContainer'
import AttachmentFreightToContainer from './Popups/AttachmentFreightToContainer'
import ShowProposalTable from './Popups/ShowProposalTable'
import CreateTransport from './Popups/CreateTransport'
import PubllishContainer from './Popups/PublishContainer'

import {AdvertService} from '../../../../services/FreightOperatorServices/AdvertService'
import {ContainerService} from '../../../../services/AdminServices/ContainerService'
import {TransportService} from '../../../../services/AdminServices/TransportService'
import {TransporterGroupService} from '../../../../services/CarrierServices/TransporterGroupService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import useScrollLock from '../../../../utils/useScrollLock'
import {priceConversion, getFreightIcon} from '../../../../utils/common'
import getDateTime from '../../../../utils/common'

import './Containers.scss'

const SubTableComponent = ({
  freightContainerList,
  intl,
  setShowFreightDetailsScreen,
  getFreightDetailedList,
  setLoading,
  setSelectedFreightId,
  setShowFreightProposalPopup,
  detachFreightList,
  getCurrencyList,
  setShowFreightProposalTablePopup,
  getFreightProposalTableList,
  getFreightProposalScopeList,
  getProposalScopeList,
}: any) =>
  freightContainerList ? (
    <DataTableAdvanced
      columns={[
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_title'}),
          accessor: 'title',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_company'}),
          accessor: 'company',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_value'}),
          accessor: 'value',
          cellStyle: {
            textAlign: 'end',
          },
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
          accessor: 'currencyCode',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_from'}),
          accessor: 'fromLocation',
        },

        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_to'}),
          accessor: 'toLocation',
        },

        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_departureDate'}),
          accessor: 'departureDate',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_arriveDate'}),
          accessor: 'arriveDate',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_status'}),
          accessor: 'status',
        },
        {
          Header: intl.formatMessage({id: 'Admin.Adverts.table_proposal_count'}),
          accessor: 'proposalCount',
          isAction: true,
          Cell: ({row}: any) => (
            <div
              className={
                row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0
                  ? 'proposal-wrap'
                  : ''
              }
              onClick={() => {
                if (row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0) {
                  setLoading(true)
                  getFreightProposalTableList(row?.values?.id)
                  setSelectedFreightId(row?.values?.id)
                  setShowFreightProposalTablePopup(true)
                  getCurrencyList()
                }
              }}
            >
              {row?.values?.proposalCount}
            </div>
          ),
        },
        {
          Header: 'statusCode',
          accessor: 'statusCode',
          hidden: true,
        },
      ]}
      data={
        freightContainerList &&
        freightContainerList.map((item: any) => {
          return {
            id: item?.freightId,
            title: item?.description ?? '-',
            company: item?.company?.name ?? '-',
            value: item?.value && priceConversion(item?.value, item?.valueCurrency?.currencyCode),
            currencyCode: item?.valueCurrency?.currencyCode,
            fromLocation: item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
            toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
            departureDate:
              item?.plannedDepartureDate !== null &&
              getDateTime(item?.plannedDepartureDate, intl.locale),
            arriveDate:
              item?.plannedArrivalDate !== null &&
              getDateTime(item?.plannedArrivalDate, intl.locale),
            status: item?.freightStatus?.description ?? '-',
            statusCode: item?.freightStatus?.code ?? '-',
            proposalCount: item?.proposalCount ?? '-',
          }
        })
      }
      proposalBtn={(row: any) => {
        setSelectedFreightId(row.values.id)
        setShowFreightProposalPopup(true)
        getCurrencyList()
        getProposalScopeList()
        // getFreightProposalScopeList(parseInt(row.values.id))
      }}
      showDetailBtn={(row: any) => {
        setLoading(true)
        getFreightDetailedList(row.values.id)
        setSelectedFreightId(row.values.id)
        setShowFreightDetailsScreen(true)
      }}
      detachmentBtn={(row: any) => {
        setLoading(true)
        detachFreightList(row.values.id)
      }}
      editableRows={['']}
      showPaginaton={false}
      subHeaderStyles={{className: 'subHeader-wrap'}}
      rowProps={() => {
        return {
          style: {
            background: '#f1f1f1f1',
          },
        }
      }}
    />
  ) : null

const CarrierContainers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [subTableLoading, setSubTableLoading] = useState<boolean>(false)
  const [showAttachmentFreight, setShowAttachmentFreight] = useState<boolean>(false)
  const [showFreightDetailsScreen, setShowFreightDetailsScreen] = useState<boolean>(false)
  const [showFreightProposalTablePopup, setShowFreightProposalTablePopup] = useState<boolean>(false)
  const [transportTooltipShow, setTransportTooltipShow] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>('1')
  const [freightDetails, setFreightDetails] = useState<any>({})
  const [statusFilter, setStatusFilter] = useState<string>('all')
  const [filterCounter, setFilterCounter] = useState<number>(0)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [pageTransportMIndex, setPageTransportMIndex] = useState<number>(0)

  const [numberSliderValue, setNumberSliderValue] = useState<number>(100)
  const [transportChecks, setTransportChecks] = useState<any>({
    preferredRoute: false,
    conformingToSpecification: false,
    appliedToFreight: false,
  })
  const [searchByDistance, setSearchByDistance] = useState<boolean>(false)

  /*Service States*/
  const advertServ = new AdvertService()
  const containerServ = new ContainerService()
  const transportService = new TransportService()
  const transporterGroupServ = new TransporterGroupService()
  const commonServ = new CommonService()

  /*List States*/
  const [containerList, setContainerList] = useState<any>([])
  const [containerDetailedList, setContainerDetailedList] = useState<any>({})
  const [transporterList, setTransporterList] = useState<any>([])
  const [approvedAdverts, setApprovedAdverts] = useState<any>([])
  const [statusList, setStatusList] = useState<any>([])
  const [currencyList, setCurrencyList] = useState<any>([])
  const [freightContainerList, setFreightContainerList] = useState<any>([])
  const [selectedProposalTableList, setSelectedProposalTableList] = useState<any[]>([])
  const [selectedEditProposal, setSelectedEditProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
    proposalScope: '',
    companyName: '',
  })
  const [proposalScopeList, setProposalScopeList] = useState<any[]>([])
  const [containerProposalScopeList, setContainerProposalScopeList] = useState<any[]>([])
  const [freightProposalScopeList, setFreightProposalScopeList] = useState<any[]>([])

  const [selectedFreightProposalTableList, setSelectedFreightProposalTableList] = useState<any[]>(
    []
  )
  const [selectedEditFreightProposal, setSelectedEditFreightProposal] = useState<any>({
    price: '',
    currency: '',
    explanation: '',
    proposalScope: '',
    companyName: '',
  })

  const [filterDetails, setFilterDetails] = useState<any>({
    name: '',
    surName: '',
    vehiclePlate: '',
    trailerPlate: '',
  })

  const [selectedContainer, setSelectedContainer] = useState<any>(null)
  const [selectedContainerId, setSelectedContainerId] = useState<number>(-1)
  const [selectedFreightId, setSelectedFreightId] = useState<number>(-1)
  const [selectedTransporter, setSelectedTransporter] = useState<any>(null)
  const [selectedMatchContianer, setSelectedMatchContianer] = useState<any>(null)
  const [selectedSubTableId, setSelectedSubTableId] = useState<any>(null)

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])
  const [freightContentTypeList, setFreightContentTypeList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('all')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('all')

  const [freightContentTypeSearch, setFreightContentTypeSearch] = useState<string>('')
  const [freightContentTypePageIndex, setFreightContentTypePageIndex] = useState<number>(0)
  const [freightContentTypeId, setFreightContentTypeId] = useState<string>('all')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)
  const [freightContentTypeScrollAPICalled, setFreightContentTypeScrollAPICalled] =
    useState<boolean>(false)

  /*Screens and modals*/
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState<boolean>(false)
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  const [showCreateContainerPopup, setShowCreateContainerPopup] = useState<boolean>(false)
  const [showPublishContainerPopup, setShowPublishContainerPopup] = useState<boolean>(false)
  const [showCreateTransportPopup, setShowCreateTransportPopup] = useState<boolean>(false)
  const [showProposalPopup, setShowProposalPopup] = useState<boolean>(false)
  const [showFreightProposalPopup, setShowFreightProposalPopup] = useState<boolean>(false)
  const [showProposalTablePopup, setShowProposalTablePopup] = useState<boolean>(false)

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isFreightProposalNextPage, setIsFreightProposalNextPage] = useState<boolean>(false)
  const [isContainerProposalNextPage, setIsContainerProposalNextPage] = useState<boolean>(false)

  const [transporterGroupUserList, setTransporterGroupUserList] = useState<any>([])
  const [selectedTransporterGroup, setSelectedTransporterGroup] = useState<any>([])

  const [proposalPageMIndex, setProposalPageMIndex] = useState<number>(0)
  const [freightProposalPageMIndex, setFreightProposalPageMIndex] = useState<number>(0)

  const [publishDate, setPublishDate] = useState<any>(null)
  const [publishNonFormatDate, setPublishNonFormatDate] = useState<any>('')
  const [publicFreightExchange, setPublicFreightExchange] = useState<boolean>(false)
  const [privateFreightExchange, setPrivateFreightExchange] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()
  useEffect(() => {
    if (
      !showDetailsScreen &&
      !showCreateContainerPopup &&
      !showUpdateScreen &&
      !showDeletePopup &&
      !showPublishContainerPopup &&
      !showCreateTransportPopup &&
      !showProposalPopup
    )
      scrollUnlock()
  }, [
    showCreateContainerPopup,
    showDetailsScreen,
    showUpdateScreen,
    showDeletePopup,
    showPublishContainerPopup,
    showCreateTransportPopup,
    showProposalPopup,
  ])

  const fillUpdateScreenInputs = (dat: any) => {
    formik.setFieldValue('title', dat?.description)
    formik.setFieldValue('value', dat?.price)
    formik.setFieldValue('appliedForTransport', dat?.appliedForTransport === 'Y')
    formik.setFieldValue('currency', dat?.priceCurrency?.currencyId)
    formik.setFieldValue('plannedDepartureDate', dat?.plannedDepartureDate)
    formik.setFieldValue('plannedArrivalDate', dat?.plannedArrivalDate)
    formik.setFieldValue('loadAddress', dat?.fromLocation?.locationId)
    formik.setFieldValue('destinationAddress', dat?.toLocation?.locationId)
    formik.setFieldValue('trailerType', dat?.trailerType?.trailerTypeId)
    formik.setFieldValue('transportType', dat?.floorType?.floorTypeId)
    setTimeout(() => formik.setFieldValue('freightSpecificationList', dat?.specificationList), 150)
    formik.setFieldValue('transportType', dat?.freightType?.transportTypeId)
  }

  /*----------------------------*/
  /** Formik Preferences Start**/
  const initialValues = {
    title: '',
    trailerType: '',
    trailerSpecification: [],
    floorType: '',
    transportType: '',
    loadAddress: '',
    destinationAddress: '',
    value: '',
    currency: '',
    appliedForTransport: false,

    publishDate: '',
    publishNonFormatDate: new Date().getTime(),
    publishTime: '',
  }
  const loginSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    trailerType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    floorType: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    trailerSpecification: Yup.array().required(intl.formatMessage({id: 'global.mandatory'})),

    loadAddress: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    destinationAdress: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    value: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
    currency: Yup.string().required(intl.formatMessage({id: 'global.mandatory'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setShowUpdateScreen(false)
    },
  })
  /** Formik Preferences End**/

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      setPageMIndex(page)
      getContainerList({
        isPublished: getVal(filter),
        pageMIndex: page,
        fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
        fromCityId: fromCityId === 'all' ? '' : fromCityId,
        toCountryCode: toCountryId === 'all' ? '' : toCountryId,
        toCityId: toCityId === 'all' ? '' : toCityId,
        freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
      })
    }

    setIsNextPage(true)
  }

  const onProposalPaginationChange = (page: number) => {
    if (!isContainerProposalNextPage) {
      setProposalPageMIndex(page)
      getContainerProposalList(selectedContainerId, page)
    }
    setIsContainerProposalNextPage(true)
  }

  const onFreightProposalPaginationChange = (page: number) => {
    if (!isFreightProposalNextPage) {
      setFreightProposalPageMIndex(page)
      getFreightProposalTableList(selectedFreightId, page)
    }

    setIsFreightProposalNextPage(true)
  }

  /** Container Api call Start **/
  const getContainerList = (data?: any) => {
    containerServ
      .getCarrierContainers({
        ...(data?.containerStatusId && {containerStatusId: data?.containerStatusId}),
        ...(data?.isPublished && {isPublished: data?.isPublished}),
        ...{pageIndex: data?.pageMIndex ?? pageMIndex},
        ...data,
      })
      .then((resp: any) => {
        setContainerList(resp?.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
        setIsNextPage(false)
      })
  }

  const attachFreightContainer = (containerId?: number, freightId?: number) => {
    containerServ
      .attachFreightToCarrierContainer(containerId, freightId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.attachFreight.success'}))
        setShowAttachmentFreight(false)
        getFreightInContainerList(selectedContainerId)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const detachFreightList = (freightId?: number) => {
    containerServ
      .detachFreightToCarrierContainer(selectedContainerId, freightId)
      .then(() => {
        getFreightInContainerList(selectedContainerId)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.detachFreight.success'}))
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleGetSelectedFreightProposal = (freightProposalId: number) => {
    advertServ
      .getCarrierSelectedFreightProposal(freightProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedEditFreightProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          explanation: resp?.data?.explanation,
          proposalScope: resp?.data?.proposalScope?.proposalScopeId,
          companyName: resp?.data?.companyName,
        }))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleDeleteFreightProposalData = (freightProposalId: number) => {
    advertServ
      .deleteCarrierFreigthProposal(freightProposalId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.delete.success'}))
        setLoading(false)
        getFreightProposalTableList(selectedFreightId, freightProposalPageMIndex)
        getFreightInContainerList(selectedContainerId)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getContainerStatusListData = () => {
    containerServ
      .getCarrierContainerStatusList()
      .then((resp: any) => {
        setLoading(false)
        setStatusList(
          [
            {
              containerStatusId: 'all',
              description: intl.formatMessage({id: 'Admin.Adverts.all'}),
            },
          ].concat(resp?.data)
        )
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const setUnsuccessContainer = (containerId: number) => {
    containerServ
      .setUnsuccessCarrierContainer(containerId)
      .then((resp: any) => {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'global.success'}))
        getContainerList({
          isPublished: getVal(filter),
          containerStatusId: statusFilter,
          pageMIndex: pageMIndex,
          fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
          fromCityId: fromCityId === 'all' ? '' : fromCityId,
          toCountryCode: toCountryId === 'all' ? '' : toCountryId,
          toCityId: toCityId === 'all' ? '' : toCityId,
          freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
        })
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (statusFilter && filterCounter > 0) {
      setLoading(true)
      getContainerList({
        containerStatusId: statusFilter,
        ...(filter && {isPublished: getVal(filter)}),
        fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
        fromCityId: fromCityId === 'all' ? '' : fromCityId,
        toCountryCode: toCountryId === 'all' ? '' : toCountryId,
        toCityId: toCityId === 'all' ? '' : toCityId,
        freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
      })
    }
  }, [statusFilter])

  useEffect(() => {
    if (filter && filterCounter > 0) {
      setLoading(true)
      getContainerList({
        isPublished: getVal(filter),
        ...(statusFilter && {containerStatusId: statusFilter}),
        fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
        fromCityId: fromCityId === 'all' ? '' : fromCityId,
        toCountryCode: toCountryId === 'all' ? '' : toCountryId,
        toCityId: toCityId === 'all' ? '' : toCityId,
        freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
      })
    }
  }, [filter])

  useEffect(() => {
    getContainerList({
      isPublished: getVal(filter),
      containerStatusId: statusFilter,
      pageMIndex: pageMIndex,
      fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
      fromCityId: fromCityId === 'all' ? '' : fromCityId,
      toCountryCode: toCountryId === 'all' ? '' : toCountryId,
      toCityId: toCityId === 'all' ? '' : toCityId,
      freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
    })
  }, [fromCountryId, fromCityId, toCountryId, toCityId, freightContentTypeId])

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0)
          setFromCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setFromCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0)
          setToCountryList(
            [
              {
                code: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCountryList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0)
          setToCityList(
            [
              {
                cityId: 'all',
                name: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.name))
          )
        else setToCityList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getFreightContentTypeList = (pageIndex: number = 0, searchText?: any) => {
    commonServ
      .freightContentTypeList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFreightContentTypeScrollAPICalled(false)
        if (pageIndex === 0)
          setFreightContentTypeList(
            [
              {
                freightContentTypeId: 'all',
                description: intl.formatMessage({id: 'Admin.Adverts.all'}),
              },
            ].concat(resp?.data?.filter((item: any) => item?.description))
          )
        else setFreightContentTypeList((item: any) => [...item, ...resp?.data])
        setLoading(false)
      })
      .catch((e: any) => {
        setFreightContentTypeScrollAPICalled(false)
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const getContainerProposalList = (containerId: number, pageIndex?: any) => {
    containerServ
      .getCarrierContainerProposals(containerId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setIsContainerProposalNextPage(false)
        setSelectedProposalTableList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setIsContainerProposalNextPage(false)
      })
  }

  const createProposal = (
    price: number,
    currency: number,
    explanation: string,
    proposalScopeId: number,
    companyName: string
  ) => {
    containerServ
      .createCarrierProposal(
        price,
        currency,
        explanation,
        selectedContainerId,
        proposalScopeId,
        companyName
      )
      .then(() => {
        setShowProposalPopup(false)
        getContainerList()
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.create.success'}))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getFreightProposalTableList = (freightId: number, pageIndex?: any) => {
    advertServ
      .getCarrierFreightProposalList(freightId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setIsFreightProposalNextPage(false)
        setSelectedFreightProposalTableList(resp.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setIsFreightProposalNextPage(false)
      })
  }

  const getFreightDetailedList = (freightId: number) => {
    advertServ
      .getCarrierAdvertWithId(freightId)
      .then((resp: any) => {
        setFreightDetails(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const createFreightProposal = (
    price: number,
    currency: number,
    explanation: string,
    companyName: string,
    proposalScopeId: number
  ) => {
    containerServ
      .createCarrierFreightProposals(
        price,
        currency,
        explanation,
        selectedFreightId,
        companyName,
        proposalScopeId
      )
      .then(() => {
        setShowFreightProposalPopup(false)
        getFreightInContainerList(selectedContainerId)
        setLoading(false)
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.create.success'}))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleFreightUpdateProposal = (data: any) => {
    advertServ
      .updateCarrierFreightProposal(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
        getFreightProposalTableList(selectedFreightId, freightProposalPageMIndex)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getFreightInContainerList = (containerId: any) => {
    containerServ
      .getCarrierFreightsInContainer(containerId)
      .then((resp: any) => {
        setFreightContainerList(resp.data)
        setLoading(false)
        setSubTableLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  const handleDeleteFreightProposal = (containerProposalId: number) => {
    containerServ
      .deleteCarrierContainerProposal(containerProposalId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.createProposal.delete.success'}))
        setLoading(false)
        getContainerProposalList(selectedContainerId, proposalPageMIndex)
        getContainerList()
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleGetSelectedContainerProposal = (containerProposalId: number) => {
    containerServ
      .getSelectedCarrierContainerProposal(containerProposalId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedEditProposal((item: any) => ({
          ...item,
          price: resp?.data?.price,
          currency: resp?.data?.currency?.currencyId,
          explanation: resp?.data?.explanation,
          proposalScope: resp?.data?.proposalScope?.proposalScopeId,
          companyName: resp?.data?.companyName,
        }))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const handleUpdateProposal = (data: any) => {
    containerServ
      .updateCarrierContainerProposal(data)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.Adverts.updateProposal.success'}))
        setLoading(false)
        getContainerProposalList(selectedContainerId, proposalPageMIndex)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const createContainer = (data: any) => {
    containerServ
      .createCarrierContainer(
        data.desc,
        parseInt(data.freightId),
        parseInt(data.price),
        parseInt(data.currency)
      )
      .then((resp: any) => {
        getContainerList()
        toast.success(intl.formatMessage({id: 'global.createSuccess'}))
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getApprovedFreights = () => {
    advertServ
      .getCarrierAdvertsWithStatus(5)
      .then((resp: any) => {
        setApprovedAdverts(resp.data)
        setLoading(false)
        /* resp?.data?.length ? setShowCreateContainerPopup(true) : toast.info('Onaylanmış yük bulunamadı.');*/
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getContainerDetailedList = (contId: number) => {
    containerServ
      .getCarrierContainerWithId(contId)
      .then((resp: any) => {
        setContainerDetailedList(resp?.data)
        fillUpdateScreenInputs(resp?.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getCurrencyList = () => {
    advertServ
      .carrierCurrencyList()
      .then((resp: any) => {
        setCurrencyList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getTransporterList = (containerId: number) => {
    containerServ
      .getCarrierTransporterFilterList(
        containerId,
        !searchByDistance ? '' : numberSliderValue,
        transportChecks?.conformingToSpecification,
        transportChecks?.appliedToFreight,
        transportChecks?.preferredRoute,
        filterDetails
      )
      .then(async (resp: any) => {
        setShowCreateTransportPopup(true)
        setTransporterList(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const senNotificationTotransporterAsCarrier = (containerId: number) => {
    containerServ
      .sendNotificationTransporterAsCarrier(
        containerId,
        !searchByDistance ? '' : numberSliderValue,
        transportChecks?.conformingToSpecification,
        transportChecks?.appliedToFreight,
        transportChecks?.preferredRoute
      )
      .then((resp: any) => {
        setShowCreateTransportPopup(true)
        toast.success(intl.formatMessage({id: 'Admin.Containers.sendNotification.success'}))
        setLoading(false)
      })
      .catch((e: any) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const matchContainer = (transporterId: number, containerId: number) => {
    transportService
      .createCarrierTransport(transporterId, containerId)
      .then(async (resp: any) => {
        await setTimeout(() => getContainerList(), 150)
        setLoading(false)
        setTimeout(() => {
          setSelectedTransporter(null)
          setSelectedMatchContianer(null)
        }, 150)
        toast.success(intl.formatMessage({id: 'global.success'}))
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setTimeout(() => {
          setSelectedTransporter(null)
          setSelectedMatchContianer(null)
        }, 150)
      })
  }

  const publishContainer = (data: any) => {
    containerServ
      .carrierContainerPublish(data)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'global.success'}))
        setLoading(false)
        getContainerList({
          isPublished: getVal(filter),
          pageMIndex: pageMIndex,
          fromCountryCode: fromCountryId === 'all' ? '' : fromCountryId,
          fromCityId: fromCityId === 'all' ? '' : fromCityId,
          toCountryCode: toCountryId === 'all' ? '' : toCountryId,
          toCityId: toCityId === 'all' ? '' : toCityId,
          freightContentTypeId: freightContentTypeId === 'all' ? '' : freightContentTypeId,
        })
        setShowPublishContainerPopup(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
        setShowPublishContainerPopup(false)
      })
  }

  const getProposalScopeList = () => {
    containerServ
      .getCarrierProposalScope()
      .then((resp: any) => {
        setProposalScopeList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getFreightProposalScopeList = (freightId?: any) => {
    containerServ
      .getFreightProposalScope(freightId)
      .then((resp: any) => {
        setFreightProposalScopeList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const getProposalScopeListData = (containerId: number) => {
    containerServ
      .getCarrierProposalScopeList(containerId)
      .then((resp: any) => {
        setContainerProposalScopeList(resp?.data)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const unPublishContainer = (containerId: number) => {
    containerServ
      .carrierContainerUnPublish(containerId)
      .then((resp: any) => {
        toast.success(intl.formatMessage({id: 'global.success'}))
        setLoading(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }

  const hadnleGetTransporterGroupList = () => {
    transporterGroupServ
      .getTransporterGroupListCurrentUser()
      .then((res: any) => {
        setTransporterGroupUserList(
          res?.data.map((item: any) => ({id: item.transporterGroupId, name: item.description}))
        )
      })
      .catch((e) => {
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
        setLoading(false)
      })
  }
  /** Container Api call End **/

  const getApiCalls = () => {
    setLoading(true)
    getContainerList()
    setLoading(true)
    getContainerStatusListData()
  }

  useEffect(() => {
    getApiCalls()
    getFromCountryList()
    getToCountryList()
    getFreightContentTypeList()
    setFilterCounter(1)
  }, [])

  const SubTableComp =
    freightContainerList.length > 0
      ? SubTableComponent({
          freightContainerList,
          intl,
          setShowFreightDetailsScreen,
          getFreightDetailedList,
          setLoading,
          getCurrencyList,
          setShowFreightProposalPopup,
          detachFreightList,
          setShowFreightProposalTablePopup,
          getFreightProposalTableList,
          setSelectedFreightId,
          getFreightProposalScopeList,
          getProposalScopeList,
        })
      : null
  const checkPublishStat = (val: any) => !val || val === '-'
  const getVal = (val: any) => (val === '1' ? null : val === '2' ? 'Y' : 'N')

  return (
    <>
      {loading && <Loading />}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Admin.Containers.Title'})}</PageTitle>

      <div className={'d-flex justify-content-end'}>
        <button
          onClick={() => {
            setLoading(true)
            getCurrencyList()
            setLoading(true)
            getApprovedFreights()
            setShowCreateContainerPopup(true)
          }}
          className='btn btn-sm btn-light-primary mb-5 border border-2 '
        >
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          {intl.formatMessage({id: 'Admin.Containers.newContainerCreation'})}
        </button>
      </div>

      {/* <div className='row mb-5'> */}
      {/* <span className='menu-icon d-flex justify-content-end'>
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span> */}
      {/* </div> */}

      <div
        className={`d-flex align-items-center ${
          showFilterPanel ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        {showFilterPanel && (
          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Admin.Adverts.filterByStatus'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Containers.statusFilterPlaceholder'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={
                  statusList &&
                  statusList.map((item: any) => {
                    return {
                      id: item?.containerStatusId,
                      title: item?.description,
                    }
                  })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setStatusFilter(item?.id)
                  }
                }}
              />
            </div>
          </div>
        )}

        {showFilterPanel && (
          <div className='mb-10 col-md-2'>
            <h6 className={'mb-3'}>{intl.formatMessage({id: 'Admin.Containers.statusFilter'})}</h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Admin.Containers.statusFilterPlaceholder'})}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={() => {}}
                setScrollCalled={() => {}}
                currentPageIndex={0}
                menuList={[
                  {id: '1', title: intl.formatMessage({id: 'global.all'})},
                  {id: '2', title: intl.formatMessage({id: 'Admin.Containers.published'})},
                  {
                    id: '3',
                    title: intl.formatMessage({id: 'Admin.Containers.notPublished'}),
                  },
                ]}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFilter(item?.id)
                    setLoading(true)
                  }
                }}
              />
            </div>
          </div>
        )}

        {showFilterPanel && (
          <div className={'d-flex align-items-center justify-content-center flex-wrap'}>
            <div className={'d-flex align-items-center '} style={{marginRight: '15px'}}>
              <span
                className={'card p-3'}
                style={{backgroundColor: '#FF8787', marginRight: '5px'}}
              ></span>
              {intl.formatMessage({id: 'Admin.Containers.datePass'})}
            </div>
            <div className={' d-flex align-items-center'} style={{marginRight: '15px'}}>
              <span
                className={'card p-3'}
                style={{backgroundColor: '#F8C4B4', marginRight: '5px'}}
              ></span>
              {intl.formatMessage({id: 'Admin.Containers.notPublished'})}
            </div>
            <div className={' d-flex align-items-center'}>
              <span
                className={'card p-3'}
                style={{backgroundColor: '#BCE29E', marginRight: '5px'}}
              ></span>
              {intl.formatMessage({id: 'Admin.Containers.published'})}
            </div>
          </div>
        )}

        <span className='mb-5 menu-icon d-flex justify-content-end'>
          <div
            className={`btn btn-icon btn-active-light-primary btn-custom ${
              showFilterPanel ? 'menu-button-active' : ''
            }`}
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => setShowFilterPanel(!showFilterPanel)}
          >
            <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
          </div>
        </span>
      </div>
      {showFilterPanel && (
        <div className='row justify-content-between'>
          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedFromFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={fromCountrySearch}
                customOnSearch={(e: any) => {
                  setFromCountryPageIndex(0)
                  setFromCountrySearch(e.target.value)
                  getFromCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setFromCountryPageIndex(val)
                  getFromCountryList(val, fromCountrySearch)
                }}
                setScrollCalled={() => {
                  setFromCountryScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCountryScrollAPICalled}
                currentPageIndex={fromCountryPageIndex}
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                menuList={
                  fromCountryList &&
                  fromCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.code, title: item?.name}
                    })
                }
                defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCountryId(item?.id)
                    setSelectedFromFilterCountry(item?.title)
                    setDependentFromCity(true)
                    setFromCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!fromCountryId || fromCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={fromCitySearch}
                customOnSearch={(e: any) => {
                  setFromCityPageIndex(0)
                  setFromCitySearch(e.target.value)
                  getFromSearchCityList(fromCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (fromCountryId) {
                    setFromCityPageIndex(val)
                    getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setFromCityScrollAPICalled(true)
                }}
                scrollAPIResponse={fromCityScrollAPICalled}
                currentPageIndex={fromCityPageIndex}
                menuList={
                  fromCityList &&
                  fromCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: fromCityId}}
                dependentValue={dependentFromCity}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFromCityId(item?.id)
                    setDependentFromCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
            </h6>
            <div className={''}>
              <Dropbox
                key={selectedToFilterCountry}
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({
                  id: 'addNewAddressPopup.area2.country.placeholder',
                })}
                customSearchValue={toCountrySearch}
                customOnSearch={(e: any) => {
                  setToCountryPageIndex(0)
                  setToCountrySearch(e.target.value)
                  getToCountryList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setToCountryPageIndex(val)
                  getToCountryList(val, toCountrySearch)
                }}
                setScrollCalled={() => {
                  setToCountryScrollAPICalled(true)
                }}
                scrollAPIResponse={toCountryScrollAPICalled}
                defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                currentPageIndex={toCountryPageIndex}
                menuList={
                  toCountryList &&
                  toCountryList
                    ?.filter(function ({code}: any) {
                      // @ts-ignore
                      return !this[code] && (this[code] = code)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.code, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCountryId(item?.id)
                    setSelectedToFilterCountry(item?.title)
                    setDependentToCity(true)
                    setToCityId('')
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                disabled={!toCountryId || toCountryId === 'all'}
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'addNewAddressPopup.area2.city.placeholder'})}
                customSearchValue={toCitySearch}
                customOnSearch={(e: any) => {
                  setToCityPageIndex(0)
                  setToCitySearch(e.target.value)
                  getToSearchCityList(toCountryId, 0, e.target.value)
                }}
                onScroll={(val: any) => {
                  if (toCountryId) {
                    setToCityPageIndex(val)
                    getToSearchCityList(toCountryId, val, fromCountrySearch)
                  }
                }}
                setScrollCalled={() => {
                  setToCityScrollAPICalled(true)
                }}
                scrollAPIResponse={toCityScrollAPICalled}
                currentPageIndex={toCityPageIndex}
                dependentValue={dependentToCity}
                menuList={
                  toCityList &&
                  toCityList
                    ?.filter(function ({cityId}: any) {
                      // @ts-ignore
                      return !this[cityId] && (this[cityId] = cityId)
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.cityId, title: item?.name}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: toCityId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setToCityId(item?.id)
                    setDependentToCity(false)
                  }
                }}
              />
            </div>
          </div>

          <div className='mb-10 col-md-2 filter-wrap-content'>
            <h6 className={'mb-3'}>
              {intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
            </h6>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select'}
                placeholder={intl.formatMessage({id: 'Freight.createAdvert.freightContentType'})}
                customSearchValue={freightContentTypeSearch}
                customOnSearch={(e: any) => {
                  setFreightContentTypePageIndex(0)
                  setFreightContentTypeSearch(e.target.value)
                  getFreightContentTypeList(0, e.target.value)
                }}
                onScroll={(val: any) => {
                  setFreightContentTypePageIndex(val)
                  getFreightContentTypeList(val, freightContentTypeSearch)
                }}
                setScrollCalled={() => {
                  setFreightContentTypeScrollAPICalled(true)
                }}
                scrollAPIResponse={freightContentTypeScrollAPICalled}
                currentPageIndex={freightContentTypePageIndex}
                menuList={
                  freightContentTypeList &&
                  freightContentTypeList
                    ?.filter(function ({freightContentTypeId}: any) {
                      return (
                        // @ts-ignore
                        !this[freightContentTypeId] &&
                        // @ts-ignore
                        (this[freightContentTypeId] = freightContentTypeId)
                      )
                    }, {})
                    ?.map((item: any) => {
                      return {id: item?.freightContentTypeId, title: item?.description}
                    })
                }
                defaultClearItem={{
                  id: 'all',
                  title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                }}
                defaultSelected={{id: freightContentTypeId}}
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setFreightContentTypeId(item?.id)
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        {!containerList.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: '',
              accessor: 'freightIcon',
              isAction: true,
              Cell: ({row}: any) => (
                <>
                  {getFreightIcon[row?.values?.freightIcon] && (
                    <img
                      src={getFreightIcon[row?.values?.freightIcon]}
                      className='containerIconWrap'
                      alt='frieghtIcon'
                    />
                  )}
                </>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.description'}),
              accessor: 'title',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.value'}),
              accessor: 'price',
              cellStyle: {
                textAlign: 'end',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_currency'}),
              accessor: 'currencyCode',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.from'}),
              accessor: 'fromLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Containers.to'}),
              accessor: 'toLocation',
            },

            {
              Header: intl.formatMessage({id: 'Admin.Containers.departureDate'}),
              accessor: 'departureDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.arrivalDate'}),
              accessor: 'arriveDate',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_status'}),
              accessor: 'status',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Adverts.table_proposal_count'}),
              accessor: 'proposalCount',
              isAction: true,
              Cell: ({row}: any) => (
                <div
                  className={
                    row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0
                      ? 'proposal-wrap'
                      : ''
                  }
                  onClick={() => {
                    if (row?.values?.proposalCount !== '-' && row?.values?.proposalCount !== 0) {
                      setLoading(true)
                      getContainerProposalList(parseInt(row?.values?.id))
                      setSelectedContainerId(row?.values?.id)
                      setShowProposalTablePopup(true)
                      getCurrencyList()
                      // getProposalScopeList()
                    }
                  }}
                >
                  {row?.values?.proposalCount}
                </div>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Admin.Containers.publicationStartDate'}),
              accessor: 'issueStartDate',
            },
            {
              id: 'expander', // Make sure it has an ID
              Header: '',
              isAction: true,
              Cell: ({row, rows, toggleRowExpanded}: any) =>
                !row.canExpand ? (
                  <div className='sub-table-column-wrap'>
                    <span
                      className='sub-table-icon'
                      {...row.getToggleRowExpandedProps({
                        style: {
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                      onClick={async () => {
                        setSelectedContainerId(row?.values?.id)
                        setFreightContainerList([])
                        if (row.id === selectedSubTableId) {
                          setSelectedSubTableId(null)
                        } else {
                          setSubTableLoading(true)
                          getFreightInContainerList(row.values.id)
                          setSelectedSubTableId(row.id)
                        }
                      }}
                    >
                      {row.id === selectedSubTableId ? (
                        <KTSVG path='/media/icons/duotune/art/art016.svg' />
                      ) : (
                        <KTSVG path='/media/icons/duotune/art/art015.svg' />
                      )}
                    </span>
                  </div>
                ) : null,
            },
            {
              Header: 'isContainer',
              accessor: 'isContainer',
              hidden: true,
            },
          ]}
          editableRows={['title']}
          data={
            containerList &&
            containerList.map((item: any) => {
              return {
                id: item?.containerId,
                title: item?.description,
                freightIcon: item?.freightType?.freightTypeId,
                price:
                  item?.price && priceConversion(item?.price, item?.priceCurrency?.currencyCode),
                currencyCode: item?.priceCurrency?.currencyCode,
                fromLocation:
                  item?.fromAddress?.city?.name + ' / ' + item?.fromAddress?.country?.name,
                toLocation: item?.toAddress?.city?.name + ' / ' + item?.toAddress?.country?.name,
                departureDate:
                  item?.plannedDepartureDate !== null &&
                  getDateTime(item?.plannedDepartureDate, intl.locale),
                arriveDate:
                  item?.plannedArrivalDate !== null &&
                  getDateTime(item?.plannedArrivalDate, intl.locale),
                issueStartDate: item?.issueStartDate
                  ? getDateTime(item?.issueStartDate, intl.locale)
                  : '-',
                proposalCount: item?.proposalCount ?? '-',
                status: item?.containerStatus?.description,
                isContainer: true,
              }
            })
          }
          proposalBtn={(row: any) => {
            setSelectedContainerId(row.values.id)
            setShowProposalPopup(true)
            getCurrencyList()
            // getProposalScopeListData(parseInt(row.values.id))
            getProposalScopeList()
          }}
          showDetailBtn={(row: any) => {
            setLoading(true)
            getContainerDetailedList(row.values.id)
            setSelectedContainerId(row.values.id)
            setShowDetailsScreen(true)
          }}
          containerMatch={(row: any) => {
            setSelectedContainerId(row.values.id)
            setLoading(true)
            getTransporterList(row.values.id)
          }}
          containerPublish={(row: any) => {
            setSelectedContainer(row)
            if (checkPublishStat(row.values.issueStartDate)) {
              setShowPublishContainerPopup(true)
              hadnleGetTransporterGroupList()
              setSelectedContainerId(row.values.id)
            } else
              toast.warning(intl.formatMessage({id: 'Admin.Containers.containerAlreadyPublished'}))
          }}
          containerUnPublish={(row: any) => {
            if (!checkPublishStat(row.values.issueStartDate)) {
              setLoading(true)
              unPublishContainer(row.values.id)
            } else toast.warning(intl.formatMessage({id: 'Admin.Containers.containerNotPublished'}))
          }}
          unsuccessBtn={(row: any) => {
            setUnsuccessContainer(row.values.id)
          }}
          attachmentFrieghtBtn={(row: any) => {
            setSelectedContainerId(row.values.id)
            setShowAttachmentFreight(true)
            setLoading(true)
            getApprovedFreights()
          }}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
          subTableComponentData={freightContainerList}
          selectedSubTableId={selectedSubTableId}
          ChildSubTableComponent={SubTableComp}
          subTableLoading={subTableLoading}
          isSubTable
          subTablecolumnStyle={{
            width: '79px',
          }}
          /* showFile={(row: any) => {
                     }}*/
        />
      </div>

      {showDeletePopup && (
        <Modal
          closeBtnSettings={{
            show: true,
            onClick: () => setShowDeletePopup(false),
          }}
          customAll={
            <div className={''}>
              <h3 className={'mt-5 mb-15'}>
                {intl.formatMessage({id: 'Admin.Containers.confirmDeletion'})}
              </h3>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <button
                    className={'btn btn-danger w-100'}
                    onClick={() => {
                      setShowDeletePopup(false)
                    }}
                  >
                    {intl.formatMessage({id: 'global.cancel'})}
                  </button>
                </div>
                <div
                  className={'col-md-6'}
                  onClick={() => {
                    setLoading(true)
                    setShowDeletePopup(false)
                    getContainerList()
                  }}
                >
                  <button className={'btn btn-success w-100'}>
                    {intl.formatMessage({id: 'global.approve'})}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDetailsScreen && (
        <ShowDetails
          containerDetailedList={containerDetailedList}
          onClose={() => setShowDetailsScreen(false)}
        />
      )}

      {showProposalPopup && (
        <CreateProposalContainer
          currencyList={currencyList}
          proposalScopeList={proposalScopeList}
          onClose={() => setShowProposalPopup(false)}
          setLoading={setLoading}
          handleCreateProposal={(data: any) => {
            createProposal(
              data.price,
              data.currency,
              data.explanation,
              data.proposalScopeId,
              data.companyName
            )
          }}
        />
      )}

      {showFreightProposalPopup && (
        <CreateFreightProposalContainer
          currencyList={currencyList}
          onClose={() => setShowFreightProposalPopup(false)}
          setLoading={setLoading}
          handleCreateProposal={(data: any) => {
            createFreightProposal(
              data.price,
              data.currency,
              data.explanation,
              data.companyName,
              data.proposalScopeId
            )
          }}
          proposalScopeList={proposalScopeList}
        />
      )}

      {showFreightDetailsScreen && (
        <FreightShowDetails
          currentData={freightDetails}
          onClose={() => setShowFreightDetailsScreen(false)}
        />
      )}

      {showFreightProposalTablePopup && (
        <ShowFreightProposalTable
          currencyList={currencyList}
          getSelectedFreightProposal={(freightProposalId: any) =>
            handleGetSelectedFreightProposal(freightProposalId)
          }
          selectedProposalTableList={selectedFreightProposalTableList}
          setLoading={setLoading}
          onClose={() => setShowFreightProposalTablePopup(false)}
          deleteFreigthProposal={(freightProposalId: any) =>
            handleDeleteFreightProposalData(freightProposalId)
          }
          selectedEditProposal={selectedEditFreightProposal}
          setSelectedEditProposal={setSelectedEditFreightProposal}
          handleUpdateProposal={handleFreightUpdateProposal}
          freightProposalPageMIndex={freightProposalPageMIndex}
          setFreightProposalPageMIndex={onFreightProposalPaginationChange}
        />
      )}

      {showProposalTablePopup && (
        <ShowProposalTable
          currencyList={currencyList}
          proposalScopeList={proposalScopeList}
          getSelectedFreightProposal={(containerProposalId: any) =>
            handleGetSelectedContainerProposal(containerProposalId)
          }
          selectedProposalTableList={selectedProposalTableList}
          setLoading={setLoading}
          onClose={() => setShowProposalTablePopup(false)}
          deleteFreigthProposal={(containerProposalId: any) =>
            handleDeleteFreightProposal(containerProposalId)
          }
          selectedEditProposal={selectedEditProposal}
          setSelectedEditProposal={setSelectedEditProposal}
          handleUpdateProposal={handleUpdateProposal}
          proposalPageMIndex={proposalPageMIndex}
          setProposalPageMIndex={onProposalPaginationChange}
        />
      )}
      {showCreateContainerPopup && (
        <CreateNewContainerPopup
          freightList={approvedAdverts}
          currencyList={currencyList}
          onSubmit={async (data: any) => {
            setLoading(true)
            await createContainer(data)
            setShowCreateContainerPopup(false)
          }}
          onClose={() => setShowCreateContainerPopup(false)}
        />
      )}

      {showAttachmentFreight && (
        <AttachmentFreightToContainer
          freightList={approvedAdverts}
          onClose={() => setShowAttachmentFreight(false)}
          onSubmit={async (data: any) => {
            setLoading(true)
            attachFreightContainer(selectedContainerId, data?.freightId)
          }}
        />
      )}

      {/* {showPublishContainerPopup && (
        <Modal
          width={'450px'}
          height={'550px'}
          closeBtnSettings={{
            show: true,
            onClick: () => setShowPublishContainerPopup(false),
          }}
          customAll={
            <div className={''}>
              <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Admin.Containers.publishingDate'})}
              </label>
              <DatePicker
                value={formik.values.publishNonFormatDate}
                onSelect={(data: any) => {
                  formik.setFieldValue('publishDate', format(data, 'yyyy-MM-dd'))
                  formik.setFieldValue('publishNonFormatDate', data)
                }}
                selected={!!formik.values.publishDate}
                customDate={formik.values.publishNonFormatDate}
                disabledDays={new Date()}
                captionLayout={'buttons'}
                menuDefaultOpen
              />
              {formik.touched.publishDate && formik.errors.publishDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.publishDate}</span>
                  </div>
                </div>
              )}
              <label htmlFor='publishDate' className={'text-muted fs-4 mb-2'}>
                {intl.formatMessage({id: 'Carrier.transporterGroup.publish.group'})}
              </label>
              <Multiselect
                options={transporterGroupUserList}
                selectedValues={selectedTransporterGroup}
                onSelect={(value: any) => setSelectedTransporterGroup(value)}
                onRemove={(value: any) => setSelectedTransporterGroup(value)}
                displayValue='name'
                style={{
                  inputField: {
                    minHeight: '30px',
                    height: 'auto',
                  },
                }}
              />

              <div className={'w-100 mt-5 mb-5'}>
                <button
                  onClick={() =>
                    publishContainer(
                      selectedContainer?.values?.id,
                      new Date(formik.values.publishDate + ' 00:00:00').getTime().toString()
                    )
                  }
                  disabled={!formik.values.publishDate}
                  className={'w-100 btn btn-success'}
                >
                  {intl.formatMessage({id: 'Admin.Containers.publish'})}
                </button>
              </div>
            </div>
          }
        />
      )} */}

      {showPublishContainerPopup && (
        <PubllishContainer
          setPublishNonFormatDate={setPublishNonFormatDate}
          publishNonFormatDate={publishNonFormatDate}
          publishDate={publishDate}
          setPublishDate={setPublishDate}
          setSelectedTransporterGroup={setSelectedTransporterGroup}
          selectedTransporterGroup={selectedTransporterGroup}
          transporterGroupUserList={transporterGroupUserList}
          onClose={() => setShowPublishContainerPopup(false)}
          selectedContainerId={selectedContainerId}
          publishContainer={(data: any) => publishContainer(data)}
          publicFreightExchange={publicFreightExchange}
          setPublicFreightExchange={setPublicFreightExchange}
          setPrivateFreightExchange={setPrivateFreightExchange}
          privateFreightExchange={privateFreightExchange}
        />
      )}

      {showCreateTransportPopup && (
        <CreateTransport
          setTransportChecks={setTransportChecks}
          setSearchByDistance={setSearchByDistance}
          searchByDistance={searchByDistance}
          transportChecks={transportChecks}
          numberSliderValue={numberSliderValue}
          setNumberSliderValue={setNumberSliderValue}
          setLoading={setLoading}
          getTransporterList={getTransporterList}
          senNotificationTotransporterAsCarrier={senNotificationTotransporterAsCarrier}
          selectedContainer={selectedContainer}
          transporterList={transporterList}
          setSelectedTransporter={setSelectedTransporter}
          setTransportTooltipShow={setTransportTooltipShow}
          transportTooltipShow={transportTooltipShow}
          setShowCreateTransportPopup={setShowCreateTransportPopup}
          matchContainer={matchContainer}
          selectedTransporter={selectedTransporter}
          selectedContainerId={selectedContainerId}
          pageTransportMIndex={pageTransportMIndex}
          setSelectedMatchContianer={setSelectedMatchContianer}
          selectedMatchContianer={selectedMatchContianer}
          setFilterDetails={setFilterDetails}
          filterDetails={filterDetails}
        />
      )}
      <>
        <Tooltip
          anchorId={'transport-anchor-element-' + selectedTransporter?.id}
          place='top'
          style={{zIndex: 1000}}
          isOpen={transportTooltipShow}
        >
          <div style={{color: '#ffffff', fontFamily: 'Poppins, sans-serif'}}>
            <h5 style={{color: '#fff'}}>
              {intl.formatMessage({id: 'Admin.Containers.trailerDetails'})}
            </h5>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <small className={'text-muted'}>
                  {intl.formatMessage({id: 'Admin.Containers.plate'})}
                </small>
                <p>{selectedTransporter?.plate}</p>
              </div>
              <div className={'col-md-6'}>
                <small className={'text-muted'}>
                  {intl.formatMessage({id: 'Admin.Containers.trailerType'})}
                </small>
                <p>{selectedTransporter?.trailerType}</p>
              </div>
              <div className={'col-md-6'}>
                <small className={'text-muted'}>
                  {intl.formatMessage({id: 'Admin.Containers.floorType'})}
                </small>
                <p>{selectedTransporter?.floorType}</p>
              </div>
              <div className={'col-md-6'}>
                <small className={'text-muted'}>
                  {intl.formatMessage({id: 'Admin.Containers.specificationList'})}
                </small>
                <p>{selectedTransporter?.specificationList}</p>
              </div>
            </div>
          </div>
        </Tooltip>
      </>
    </>
  )
}
export {CarrierContainers}
