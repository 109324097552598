import react, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'

import {TrailerDetails} from './Components/TrailerDetails'
import {TruckDetails} from './Components/TruckDetails'
import Loading from '../../../Components/Loading'

import {ListingService} from '../../../../services/FreightOperatorServices/ListingService'

const PublicVehicleBuySellDetails = () => {
  const {id}: any = useParams()
  const intl = useIntl()

  const [loading, setLoading] = useState<boolean>(true)
  const [noDataFound, setNoDataFound] = useState<boolean>(false)
  const [trailerDetails, setTrailerDetails] = useState<any>({})
  const [truckDetails, setTruckDetails] = useState<any>({})

  const [selectedListImageList, setSelectedListImageList] = useState<any>([])

  const [selectedContactList, setSelectedContactList] = useState<any>([])

  const listingServ = new ListingService()

  const getListingContactDetails = (itemId: any) => {
    listingServ
      .getPublicListingContact(itemId)
      .then((resp: any) => {
        setSelectedContactList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getListingFile = (itemId: any) => {
    listingServ
      .getPublicListingFileList(itemId)
      .then((resp: any) => {
        setLoading(false)
        setSelectedListImageList(resp?.data)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  const getPublicDetails = (id: any) => {
    listingServ
      .getPublicDetails(id)
      .then((resp: any) => {
        setLoading(false)
        if (resp?.data?.listing?.listingId) {
          getListingFile(resp?.data?.listing?.listingId)
          if (resp?.data?.listing?.listingType?.code === 'TRAILER') {
            setTrailerDetails(resp?.data)
            getListingContactDetails(resp?.data?.listing?.listingId)
          } else {
            setTruckDetails(resp?.data)
            getListingContactDetails(resp?.data?.listing?.listingId)
          }
        } else {
          setNoDataFound(true)
        }
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message + ' | ' + e.response.data.code)
      })
  }

  useEffect(() => {
    if (id) {
      getPublicDetails(id)
    }
  }, [])

  return (
    <>
      {noDataFound && (
        <div className='no-data-wrap'>
          <img style={{width: '25%'}} src='/media/track.png' alt='truck' />
          <h1>{intl.formatMessage({id: 'global.noDataFound'})}</h1>
        </div>
      )}
      <div className='public-vehicle-main-wrap d-flex justify-content-center'>
        <div>
          {!loading && !noDataFound && (
            <div style={{display: 'flex', justifyContent: 'center'}} className='mb-5'>
              <h1>
                {Object.keys(trailerDetails)?.length > 0
                  ? intl.formatMessage({id: 'freight.trailer.title'})
                  : intl.formatMessage({id: 'freight.truck.title'})}
              </h1>
            </div>
          )}
          {loading && <Loading />}
          {trailerDetails && Object.keys(trailerDetails).length > 0 && (
            <TrailerDetails
              trailerDetails={trailerDetails}
              selectedListImageList={selectedListImageList}
              contactList={selectedContactList}
            />
          )}
          {truckDetails && Object.keys(truckDetails).length > 0 && (
            <TruckDetails
              truckDetails={truckDetails}
              selectedListImageList={selectedListImageList}
              contactList={selectedContactList}
            />
          )}
        </div>
      </div>
    </>
  )
}

export {PublicVehicleBuySellDetails}
